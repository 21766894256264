export const GET_ACTIVE_FOUNDERS_LIST = {
  REQUEST: 'GET_ACTIVE_FOUNDERS_LIST_REQUEST',
  SUCCESS: 'GET_ACTIVE_FOUNDERS_LIST_SUCCESS',
  FAILURE: 'GET_ACTIVE_FOUNDERS_LIST_FAILURE',
};

export const GET_ALL_FOUNDERS_LIST = {
  REQUEST: 'GET_ALL_FOUNDERS_LIST_REQUEST',
  SUCCESS: 'GET_ALL_FOUNDERS_LIST_SUCCESS',
  FAILURE: 'GET_ALL_FOUNDERS_LIST_FAILURE',
};

export const GET_FOUNDER_DETAIL = {
  REQUEST: 'GET_FOUNDER_DETAIL_REQUEST',
  SUCCESS: 'GET_FOUNDER_DETAIL_SUCCESS',
  FAILURE: 'GET_FOUNDER_DETAIL_FAILURE',
};

export const GET_FOUNDER_FUNDRAISING_STATUS = {
  REQUEST: 'GET_FOUNDER_FUNDRAISING_STATUS_REQUEST',
  SUCCESS: 'GET_FOUNDER_FUNDRAISING_STATUS_SUCCESS',
  FAILURE: 'GET_FOUNDER_FUNDRAISING_STATUS_FAILURE',
};

export const GET_FOUNDER_SWAP_PARTNERS = {
  REQUEST: 'GET_FOUNDER_SWAP_PARTNERS_REQUEST',
  SUCCESS: 'GET_FOUNDER_SWAP_PARTNERS_SUCCESS',
  FAILURE: 'GET_FOUNDER_SWAP_PARTNERS_FAILURE',
};
