import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import Decimal from 'decimal.js-light';
import RadioButton from '../../../UI/RadioButton';
import { prettyFloatMoney } from '../../../../helpers/utils';
import IconCreditCard from '../../../../assets/icons/icon_credit_card.svg';
import IconGiroPay from '../../../../assets/icons/icon_giropay.svg';
import LogoSepa from '../../../../assets/icons/icon_sepa_logo.png';
import { getStripeCalculatedAmount } from '../../../../store/services/stripeServices';
import s from '../index.module.scss';

export const STRIPE_PAYMENT_TYPES = {
  bank: 'bank',
  card: 'card',
  giropay: 'giropay',
  sepa_debit: 'sepa_debit',
  bancontact: 'bancontact',
  blik: 'blik',
  eps: 'eps',
  fpx: 'fpx',
  ideal: 'ideal',
  p24: 'p24',
  sofort: 'sofort',
};

export const PROCESSING_TIME = {
  sepa_debit: 5,
  sofort: 2,
};

const DISCOUNT = 0.7; // 30% discount

export const PaymentMethod = ({ item, amount, disabled, formikBag }) => {
  const [commission, setCommission] = useState(0);

  const { values, setValues } = formikBag;

  const calculateComission = (amount, fixedCommission, commission) => {
    const am1 = new Decimal(amount).add(fixedCommission);
    const am2 = new Decimal(commission).div(100);
    const am3 = new Decimal(1).sub(am2);
    const res = am1.div(am3).sub(amount).toFixed(2, 8);
    return new Decimal(res).mul(DISCOUNT).toFixed(2, 8);
  };

  const comissions = {
    eu: calculateComission(amount, 0.25, 1.5),
    uk: calculateComission(amount, 0.25, 2.5),
    international: calculateComission(amount, 0.25, 3.25),
  };

  useEffect(() => {
    calculate(amount);
  }, [amount]);

  const calculate = () => {
    getStripeCalculatedAmount({ amount, payment_method: item.type }).then(res => {
      if (res && res.success) {
        setCommission(new Decimal(res.data.amount).sub(amount).toNumber());
      }
    });
  };

  const getIcon = item => {
    if (item.icon) {
      return <img src={item.icon} alt={item.title} height={23} />;
    }
    switch (item.type) {
      case STRIPE_PAYMENT_TYPES.card:
        return <img src={IconCreditCard} alt={item.title} width={31} />;
      case STRIPE_PAYMENT_TYPES.giropay:
        return <img src={IconGiroPay} alt={item.title} width={31} />;
      case STRIPE_PAYMENT_TYPES.sepa_debit:
        return <img src={LogoSepa} alt={item.title} width={36} />;
      default:
        return null;
    }
  };

  return (
    <RadioButton
      key={item.type}
      className={classnames(
        s.method_item,
        disabled && 'disabled',
        values.method === item.type && 'selected'
      )}
      value={item.type}
      name={item.type}
      checked={values.method === item.type}
      disabled={disabled}
      onChange={() =>
        setValues({
          ...values,
          method: item.type,
          commission: 0,
          hasConfirmed: false,
        })
      }
    >
      <>
        <h5 className={classnames(s.method_title, 'f-20 f-500')}>
          {getIcon(item)}
          <span>{item.title}</span>
        </h5>
        <div className={classnames(s.method_item_commission, 'f-16 f-500')}>
          {commission ? `Transfer fee is ${prettyFloatMoney(commission)}` : 'No transfer fees'}
        </div>
        {item.type === STRIPE_PAYMENT_TYPES.card && (
          <div className={classnames(s.method_commission_card, 'f-14 f-400')}>
            The amount of transfer fee depends on your card issuer:
            <ul className="scr-circle-list">
              <li>{prettyFloatMoney(comissions.eu)} for European Economic Area cards </li>
              <li>{prettyFloatMoney(comissions.uk)} for UK cards</li>
              <li>{prettyFloatMoney(comissions.international)} for international cards </li>
            </ul>
            Please note that in some cases your bank may charge additional transfer fees, so the
            amount that will be transferred to your Scramble account may be less.
          </div>
        )}
      </>
    </RadioButton>
  );
};
