import * as React from 'react';
import ForgotPassword from '../../containers/ForgotPassword';
import BasicLayout from '../../layouts/BasicLayout';

const ForgotPasswordPage = ({ history }) => (
  <BasicLayout>
    <div className="container">
      <ForgotPassword history={history} />
    </div>
  </BasicLayout>
);

export default ForgotPasswordPage;
