import * as React from 'react';
import BasicLayout from '../../layouts/BasicLayout';
import SignUp from '../../containers/SignUp';
import SignUpCoFounder from '../../containers/SignUpCoFounder';

const SignUpPage = props => (
  <BasicLayout>
    <div className="container">
      {props.match.path.includes('co-founder') ? (
        <SignUpCoFounder {...props} />
      ) : (
        <SignUp {...props} />
      )}
    </div>
  </BasicLayout>
);

export default SignUpPage;
