import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import qs from 'qs';
import PaymentForm from '../../components/Forms/PaymentForm';
import { createLHVPaymentSession, getPaymentMethods } from '../../store/services/lhvServices';

const DEFAULT_AMOUNT = 100;

const PaymentModule = ({ children, disabled, className, onBack }) => {
  const location = useLocation();
  const { amount, method } = qs.parse(location.search.replace('?', ''));
  const [loading, setLoading] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState([]);

  useEffect(() => {
    getPaymentMethodsList();
  }, []);

  const getPaymentMethodsList = async () => {
    const res = await getPaymentMethods();
    if (res && res.success) {
      setPaymentMethods(res.data);
    }
  };

  const onSubmit = async values => {
    if (values.method !== 'bank') {
      setLoading(true);
      const res = await createLHVPaymentSession({
        payment_method: values.method,
        amount: values.amount,
      });

      if (res && res.success) {
        return window.location.replace(res.data.payment_link);
      }
      setLoading(false);
    }
  };

  return (
    <PaymentForm
      onSubmit={onSubmit}
      loading={loading}
      paymentMethods={paymentMethods}
      className={className}
      onBack={onBack}
      disabled={disabled}
      initialValues={{
        amount: `${Number(amount) || DEFAULT_AMOUNT}`,
        method: method || 'bank',
        commission: 0,
        hasConfirmed: false,
      }}
    >
      {children}
    </PaymentForm>
  );
};

export default PaymentModule;
