import { APP_LINKS } from './links';
import { TARIFF_PLANS } from './constants';

export const getTariffPlanTermsLink = plan => {
  switch (plan) {
    case TARIFF_PLANS.start:
      return APP_LINKS.helpLoanTerms('v2');
    case TARIFF_PLANS.standard:
      return APP_LINKS.helpLoanTerms('v4-standard');
    case TARIFF_PLANS.advanced:
      return APP_LINKS.helpLoanTerms('v4-advanced');
    default:
      return APP_LINKS.helpLoanTerms();
  }
};
