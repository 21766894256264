import axios from '../../axios-api';
import Notify from '../../components/Notification';
import Pathes from '../../helpers/pathes';
import { getMessage, getQuery } from '../../helpers/utils';
import {
  GET_FR_STATUS,
  GET_FR_LOAN_GROUPS,
  GET_FR_SWAP_LIST,
  SET_PARTNER_COUNTS,
  SET_FUNDRAISING_STATUS,
  GET_CUSTOM_STRATEGY_FOUNDERS,
  GET_CUSTOM_STRATEGY_PARTNERS,
  GET_RESULT_CUSTOM_PARTNERS,
  GET_HOLD_CUSTOM_PARTNERS,
  GET_CO_FOUNDERS_LIST,
} from '../types/fundraisingTypes';

export const getFundraisingStatusRequest = () => ({
  type: GET_FR_STATUS.REQUEST,
});
export const getFundraisingStatusSuccess = payload => ({
  type: GET_FR_STATUS.SUCCESS,
  payload,
});
export const getFundraisingStatusFailure = error => ({
  type: GET_FR_STATUS.FAILURE,
  error,
});

export const getLoanGroupsRequest = () => ({
  type: GET_FR_LOAN_GROUPS.REQUEST,
});
export const getLoanGroupsSuccess = payload => ({
  type: GET_FR_LOAN_GROUPS.SUCCESS,
  payload,
});
export const getLoanGroupsFailure = error => ({
  type: GET_FR_LOAN_GROUPS.FAILURE,
  error,
});

export const getSwapListRequest = () => ({ type: GET_FR_SWAP_LIST.REQUEST });
export const getSwapListSuccess = payload => ({
  type: GET_FR_SWAP_LIST.SUCCESS,
  payload,
});
export const getSwapListFailure = error => ({
  type: GET_FR_SWAP_LIST.FAILURE,
  error,
});

export const setPartnerCountsSuccess = counts => ({
  type: SET_PARTNER_COUNTS,
  counts,
});

export const setFundraisingStatus = status => ({
  type: SET_FUNDRAISING_STATUS,
  status,
});

const getCustomStrategyFoundersRequest = () => ({
  type: GET_CUSTOM_STRATEGY_FOUNDERS.REQUEST,
});
const getCustomStrategyFoundersSuccess = payload => ({
  type: GET_CUSTOM_STRATEGY_FOUNDERS.SUCCESS,
  payload,
});
const getCustomStrategyFoundersFailure = error => ({
  type: GET_CUSTOM_STRATEGY_FOUNDERS.FAILURE,
  error,
});

const getCustomStrategyPartnersRequest = () => ({
  type: GET_CUSTOM_STRATEGY_PARTNERS.REQUEST,
});
const getCustomStrategyPartnersSuccess = payload => ({
  type: GET_CUSTOM_STRATEGY_PARTNERS.SUCCESS,
  payload,
});
const getCustomStrategyPartnersFailure = error => ({
  type: GET_CUSTOM_STRATEGY_PARTNERS.FAILURE,
  error,
});

const getHoldCustomPartnersRequest = () => ({
  type: GET_HOLD_CUSTOM_PARTNERS.REQUEST,
});
const getHoldCustomPartnersSuccess = payload => ({
  type: GET_HOLD_CUSTOM_PARTNERS.SUCCESS,
  payload,
});
const getHoldCustomPartnersFailure = error => ({
  type: GET_HOLD_CUSTOM_PARTNERS.FAILURE,
  error,
});

const getResultCustomPartnersRequest = () => ({
  type: GET_RESULT_CUSTOM_PARTNERS.REQUEST,
});
const getResultCustomPartnersSuccess = payload => ({
  type: GET_RESULT_CUSTOM_PARTNERS.SUCCESS,
  payload,
});
const getResultCustomPartnersFailure = error => ({
  type: GET_RESULT_CUSTOM_PARTNERS.FAILURE,
  error,
});

const getCoFoundersListRequest = () => ({ type: GET_CO_FOUNDERS_LIST.REQUEST });
const getCoFoundersListSuccess = payload => ({
  type: GET_CO_FOUNDERS_LIST.SUCCESS,
  payload,
});
const getCoFoundersListFailure = error => ({
  type: GET_CO_FOUNDERS_LIST.FAILURE,
  error,
});

export const getFundraisingStatus = () => {
  return dispatch => {
    dispatch(getFundraisingStatusRequest());
    return axios
      .get(Pathes.Fundraising.status)
      .then(response => {
        const { status, data } = response;
        const message = getMessage(data);
        if (status === 200) {
          dispatch(getFundraisingStatusSuccess(data));
          return { data, success: true };
        }
        throw new Error(message);
      })
      .catch(e => dispatch(getFundraisingStatusFailure(e.message)));
  };
};

export const setFundraisingGoal = payload => {
  return dispatch => {
    return axios
      .post(Pathes.Fundraising.setGoal, payload)
      .then(response => {
        const { status, data } = response;
        const message = getMessage(data);
        if (status === 200) {
          Notify.success({ text: 'Goal set successfully' });
          dispatch(getFundraisingStatusSuccess(data));
          return { data, success: true };
        }
        if (status === 400) {
          Notify.error({ text: message });
        }
        throw new Error(message);
      })
      .catch(e => ({ success: false, error: e.message }));
  };
};

export const resetFundraisingGoal = () => {
  return dispatch => {
    dispatch(getFundraisingStatusRequest());
    return axios
      .post(Pathes.Fundraising.resetGoal)
      .then(response => {
        const { status, data } = response;
        const message = getMessage(data);
        if (status === 200) {
          Notify.success({ text: 'Fundraising settings reset success' });
          dispatch(getFundraisingStatusSuccess(data));
          return { data, success: true };
        }
        throw new Error(message);
      })
      .catch(e => dispatch(getFundraisingStatusFailure(e.message)));
  };
};

export const getSwapList = params => {
  return dispatch => {
    dispatch(getSwapListRequest());
    return axios
      .get(Pathes.Fundraising.customPartners + getQuery(params, ['selected']))
      .then(response => {
        const { status, data } = response;
        const message = getMessage(data);
        if (status === 200) {
          dispatch(getSwapListSuccess(data));
          return { data, success: true };
        }

        throw new Error(message);
      })
      .catch(e => dispatch(getSwapListFailure(e.message)));
  };
};

export const getPartnerCounts = () => {
  return dispatch => {
    return axios
      .get(Pathes.Fundraising.partnerCounts)
      .then(response => {
        const { status, data } = response;
        const message = getMessage(data);
        if (status === 200) {
          dispatch(setPartnerCountsSuccess(data));
          return { data, success: true };
        }
        throw new Error(message);
      })
      .catch(e => ({ success: false, error: e.message }));
  };
};

export const setPartnerCounts = counts => dispatch => dispatch(setPartnerCountsSuccess(counts));

export const getLoanGroups = () => {
  return dispatch => {
    dispatch(getLoanGroupsRequest());
    return axios
      .get(Pathes.Fundraising.loanGroups)
      .then(response => {
        const { status, data } = response;
        const message = getMessage(data);
        if (status === 200) {
          dispatch(getLoanGroupsSuccess(data));
          return { data, success: true };
        }

        throw new Error(message);
      })
      .catch(e => dispatch(getLoanGroupsFailure(e.message)));
  };
};

export const getNextFundraisingStatus = () => {
  return dispatch => {
    return axios
      .get(Pathes.Fundraising.nextStatus)
      .then(response => {
        const { status, data } = response;
        const message = getMessage(data);
        if (status === 200) {
          dispatch(getFundraisingStatusSuccess(data));
          return { data, success: true };
        }
        throw new Error(message);
      })
      .catch(e => ({ error: e.message, success: false }));
  };
};

export const getCustomStrategyFounders = (params, isNext) => {
  return (dispatch, getState) => {
    dispatch(getCustomStrategyFoundersRequest());
    return axios
      .get(Pathes.Fundraising.strategyCustomFounders + getQuery(params))
      .then(response => {
        const { status, data } = response;
        const message = getMessage(data);
        if (status === 200) {
          const prevData = getState().fundraisingStore.customStrategyFounders.data;
          if (!isNext || !prevData) {
            dispatch(getCustomStrategyFoundersSuccess(data));
            return { data, success: true, message };
          }

          const updatedData = {
            total_count: data.total_count,
            total_pages: data.total_pages,
            list: [...prevData.list, ...data.list],
          };

          dispatch(getCustomStrategyFoundersSuccess(updatedData));
          return { data: updatedData, success: true, message };
        }
        throw new Error(message);
      })
      .catch(e => dispatch(getCustomStrategyFoundersFailure(e.message)));
  };
};

export const getCustomStrategyPartners = (params, isNext) => {
  return (dispatch, getState) => {
    dispatch(getCustomStrategyPartnersRequest());
    return axios
      .get(Pathes.Fundraising.customPartners + getQuery(params))
      .then(response => {
        const { status, data } = response;
        const message = getMessage(data);
        if (status === 200) {
          const prevData = getState().fundraisingStore.customStrategyPartners.data;
          if (!isNext || !prevData) {
            dispatch(getCustomStrategyPartnersSuccess(data));
            return { data, success: true, message };
          }

          const updatedData = {
            total_count: data.total_count,
            total_pages: data.total_pages,
            list: [...prevData.list, ...data.list],
          };

          dispatch(getCustomStrategyPartnersSuccess(updatedData));
          return { data: updatedData, success: true, message };
        }
        throw new Error(message);
      })
      .catch(e => dispatch(getCustomStrategyPartnersFailure(e.message)));
  };
};

export const getHoldCustomPartners = (params, isNext) => {
  return (dispatch, getState) => {
    dispatch(getHoldCustomPartnersRequest());
    return axios
      .get(Pathes.Fundraising.holdCustomPartners + getQuery(params))
      .then(response => {
        const { status, data } = response;
        const message = getMessage(data);
        if (status === 200) {
          const prevData = getState().fundraisingStore.holdCustomPartners.data;
          if (!isNext || !prevData) {
            dispatch(getHoldCustomPartnersSuccess(data));
            return { data, success: true, message };
          }

          const updatedData = {
            total_count: data.total_count,
            total_pages: data.total_pages,
            list: [...prevData.list, ...data.list],
          };

          dispatch(getHoldCustomPartnersSuccess(updatedData));
          return { data: updatedData, success: true, message };
        }
        throw new Error(message);
      })
      .catch(e => dispatch(getHoldCustomPartnersFailure(e.message)));
  };
};

export const getResultCustomPartners = (params, isNext) => {
  return (dispatch, getState) => {
    dispatch(getResultCustomPartnersRequest());
    return axios
      .get(Pathes.Fundraising.latestFundraisingPartners + getQuery(params))
      .then(response => {
        const { status, data } = response;
        const message = getMessage(data);
        if (status === 200) {
          const prevData = getState().fundraisingStore.resultCustomPartners.data;
          if (!isNext || !prevData) {
            dispatch(getResultCustomPartnersSuccess(data));
            return { data, success: true, message };
          }

          const updatedData = {
            total_count: data.total_count,
            total_pages: data.total_pages,
            list: [...prevData.list, ...data.list],
          };

          dispatch(getResultCustomPartnersSuccess(updatedData));
          return { data: updatedData, success: true, message };
        }
        throw new Error(message);
      })
      .catch(e => dispatch(getResultCustomPartnersFailure(e.message)));
  };
};

// Co-founders list
export const getCoFoundersList = () => {
  return dispatch => {
    dispatch(getCoFoundersListRequest());
    return axios
      .get(Pathes.Founders.coFounders)
      .then(response => {
        const { status, data } = response;
        const message = getMessage(data);
        if (status === 200) {
          dispatch(getCoFoundersListSuccess(data));
          return { data, success: true };
        }
        throw new Error(message);
      })
      .catch(e => {
        dispatch(getCoFoundersListFailure(e.message));
        return { success: false };
      });
  };
};

// Accept or Decline participating status in Fundraising
export const participateFundraising = payload => {
  return (dispatch, getState) => {
    return axios
      .post(Pathes.Fundraising.acceptOrDeclineFundraising, payload)
      .then(response => {
        const { status, data } = response;
        const message = getMessage(data);
        if (status === 200) {
          const { status } = getState().fundraisingStore;
          Notify.success({ text: message });
          status.data &&
            dispatch(
              setFundraisingStatus({
                ...status.data,
                is_participating: data.is_participating,
                is_confirmed: data.has_accepted,
                has_accepted_group_a_terms: data.has_accepted,
                has_accepted_group_b_terms: data.has_accepted,
              })
            );
          return { data, success: true };
        }
        throw new Error(message);
      })
      .catch(e => {
        Notify.error({ text: e.message });
        return { success: false };
      });
  };
};
