import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ProfileContactsForm from '../../components/Forms/ProfileContactsForm';
import { updateProfileContact } from '../../store/services/userServices';
import { getUserProfile } from '../../store/actions/profileActions';
import { uploadFile } from '../../store/actions/commonActions';

const ProfileContactsModal = ({ onClose, onSuccess }) => {
  const { user } = useSelector(state => state.profileStore.profile.data);
  const { userLocation } = useSelector(state => state.userStore);
  const dispatch = useDispatch();

  const onSubmit = async values => {
    const payload = {
      phone: values.phone,
      passport_id: values.passport_id,
      country: values.country,
      city: values.city,
      country_code: values.country_code,
      address: values.address,
      postal_code: values.postal_code,
      proof_of_residential_address: values.proof_of_residential_address[0]?.id || null,
    };

    if (values.proof_of_residential_address[0] && !values.proof_of_residential_address[0].id) {
      const result = await dispatch(uploadFile(values.proof_of_residential_address[0]));
      if (result && result.success) {
        payload.proof_of_residential_address = result.id;
      }
    }

    updateProfileContact(payload).then(res => {
      if (res && res.success) {
        dispatch(getUserProfile());
        onSuccess && onSuccess(res.data);
        onClose();
      }
    });
  };

  return (
    <ProfileContactsForm
      user={user}
      userLocation={userLocation}
      onSubmit={onSubmit}
      onCancel={onClose}
    />
  );
};

export default ProfileContactsModal;
