import { METADATA } from '../../helpers/metadata';
import { LOGOUT_USER } from '../actions/actionTypes';
import {
  CREATE_WITHDRAW_REQUEST,
  GET_NEXT_PAYMENT,
  GET_PAYMENT_SCHEDULE,
  GET_PREPAY_INFO,
  GET_WITHDRAW_HISTORY,
  GET_WITHDRAW_INFO,
  SET_BANK_DETAILS_LIST,
  SET_PENDING_TRANSACTIONS,
} from '../types/paymentTypes';

const initialState = {
  withdrawInfo: { ...METADATA.default, data: null },
  withdrawHistory: { ...METADATA.default, data: null },
  nextPayment: { ...METADATA.default, data: null },
  paymentSchedule: { ...METADATA.default, data: null },
  prepayInfo: { ...METADATA.default, data: null },
  pendingTransactions: null,
  bankDetailsList: [],
};

const paymentReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_WITHDRAW_INFO.REQUEST:
      return {
        ...state,
        withdrawInfo: { ...state.withdrawInfo, ...METADATA.request },
      };
    case GET_WITHDRAW_INFO.SUCCESS:
      return {
        ...state,
        withdrawInfo: { ...METADATA.success, data: action.payload },
      };
    case GET_WITHDRAW_INFO.FAILURE:
      return {
        ...state,
        withdrawInfo: {
          ...state.withdrawInfo,
          ...METADATA.error,
          error: action.error,
        },
      };
    case GET_WITHDRAW_HISTORY.REQUEST:
      return {
        ...state,
        withdrawHistory: { ...state.withdrawHistory, ...METADATA.request },
      };
    case GET_WITHDRAW_HISTORY.SUCCESS:
      return {
        ...state,
        withdrawHistory: { ...METADATA.success, data: action.payload },
      };
    case GET_WITHDRAW_HISTORY.FAILURE:
      return {
        ...state,
        withdrawHistory: {
          ...state.withdrawHistory,
          ...METADATA.error,
          error: action.error,
        },
      };
    case CREATE_WITHDRAW_REQUEST:
      return {
        ...state,
        withdrawInfo: { ...state.withdrawInfo, data: action.data },
      };
    case GET_NEXT_PAYMENT.REQUEST:
      return {
        ...state,
        nextPayment: { ...state.nextPayment, ...METADATA.request, data: null },
      };
    case GET_NEXT_PAYMENT.SUCCESS:
      return {
        ...state,
        nextPayment: { ...METADATA.success, data: action.payload },
      };
    case GET_NEXT_PAYMENT.FAILURE:
      return {
        ...state,
        nextPayment: {
          ...state.nextPayment,
          ...METADATA.error,
          error: action.error,
        },
      };
    case GET_PAYMENT_SCHEDULE.REQUEST:
      return {
        ...state,
        paymentSchedule: { ...state.paymentSchedule, ...METADATA.request },
      };
    case GET_PAYMENT_SCHEDULE.SUCCESS:
      return {
        ...state,
        paymentSchedule: { ...METADATA.success, data: action.payload },
      };
    case GET_PAYMENT_SCHEDULE.FAILURE:
      return {
        ...state,
        paymentSchedule: {
          ...state.paymentSchedule,
          ...METADATA.error,
          data: null,
          error: action.error,
        },
      };
    case GET_PREPAY_INFO.REQUEST:
      return {
        ...state,
        prepayInfo: { ...state.prepayInfo, ...METADATA.request, data: null },
      };
    case GET_PREPAY_INFO.SUCCESS:
      return {
        ...state,
        prepayInfo: { ...METADATA.success, data: action.payload },
      };
    case GET_PREPAY_INFO.FAILURE:
      return {
        ...state,
        prepayInfo: {
          ...state.prepayInfo,
          ...METADATA.error,
          error: action.error,
        },
      };
    case SET_PENDING_TRANSACTIONS:
      return { ...state, pendingTransactions: action.payload };

    case SET_BANK_DETAILS_LIST:
      return {
        ...state,
        bankDetailsList: action.payload,
      };

    case LOGOUT_USER:
      return initialState;
    default:
      return state;
  }
};

export default paymentReducer;
