import { SET_INNER_WIDTH, SET_MODALS } from './actionTypes';
import axios from '../../axios-api';
import Notify from '../../components/Notification';
import Pathes from '../../helpers/pathes';

export const setInnerWidth = width => {
  return (dispatch, getState) => {
    const currentWidth = getState().commonStore.innerWidth;
    currentWidth !== width && dispatch({ type: SET_INNER_WIDTH, width });
  };
};

export const uploadFile = fileObj => {
  return () => {
    let file;
    if (fileObj instanceof FormData) {
      file = fileObj;
    } else {
      file = new FormData();
      file.append('file', fileObj);
    }

    return axios
      .post(Pathes.File.file, file)
      .then(response => response && response.status === 201 && { ...response.data, success: true })
      .catch(() => Notify.info({ text: 'Could not upload file' }));
  };
};

export const uploadPhoto = fileObj => {
  return () => {
    let file;
    if (fileObj instanceof FormData) {
      file = fileObj;
    } else {
      file = new FormData();
      file.append('file', fileObj);
    }

    return axios
      .post(Pathes.File.photo, file)
      .then(response => response && response.status === 201 && { ...response.data, success: true })
      .catch(() => Notify.info({ text: 'Could not upload file' }));
  };
};

export const setModals = value => {
  return (dispatch, getState) => {
    if (Array.isArray(value)) {
      return dispatch({ type: SET_MODALS, modals: value });
    }
    const { modals } = getState().commonStore;
    return dispatch({ type: SET_MODALS, modals: [...modals, value] });
  };
};
