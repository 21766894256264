import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RoundLink } from '../../components/UI/RoundLink';
import { PaymentBlock } from '../../components/PaymentBlock';
import { getNextPayment } from '../../store/actions/paymentActions';
import { getMissingProfileDetails } from '../../store/actions/profileActions';
import { RepaymentSchedule } from './RepaymentSchedule';
import { APP_LINKS } from '../../helpers/links';
import './index.scss';

const PaymentsNextPage = () => {
  const dispatch = useDispatch();

  const { nextPayment } = useSelector(state => state.paymentStore);
  const { balance } = useSelector(state => state.dashboardStore);

  useEffect(() => {
    dispatch(getNextPayment());
    dispatch(getMissingProfileDetails());
  }, []);

  return (
    <div className="payments-next-page">
      <div className="row">
        <div>
          <h2 className="f-26 f-500">Your next repayment</h2>
          {nextPayment.data && (
            <p className="payments-next-page__desc f-16 f-400">
              Each month you must make at least monthly repayment towards each loan. All repayments
              are debited automatically. Make sure you have sufficient funds in your account for the
              next repayment.
            </p>
          )}
        </div>
        <RoundLink
          label="Add funds"
          path={APP_LINKS.addFunds}
          className="payments-next-page__link"
        />
      </div>
      {nextPayment.data ? (
        <PaymentBlock
          isMain
          payment={nextPayment.data}
          cash={balance && balance.cash}
          className="payments-next-page__main"
        />
      ) : (
        <div className="payments-next-page__no-payment">No repayments.</div>
      )}

      <RepaymentSchedule />
    </div>
  );
};

export default PaymentsNextPage;
