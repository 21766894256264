import React from 'react';
import { Link } from 'react-router-dom';
import { CONTACT_EMAIL } from '../../helpers/constants';

const empty = '[*]';

export const FAQ = {
  how_it_works: {
    q: 'How does it work?',
    a: () => (
      <div>
        <p>
          A startup can raise up to €1M in funding that is entirely or partially guaranteed by the
          lifetime income of the founders.
        </p>
        <p>
          The founder’s personal credit limit is based on his/her personal earning potential if the
          current startup fails. Maximum personal credit limit per co-founder – €100’000.
        </p>
        <p>
          Fundraising rounds happen every month, so if needed, you can get additional funding each
          month. However, to get the money, you will need to take a few simple steps:
        </p>
        <ul className="faq-list-decimal">
          <li>
            <b>Raise funds.</b>
            <ul className="faq-list-dot">
              <li>
                Set your fundraising goal: how much money you want to raise and which loan types
                (from the cheapest to the most expensive ones) are acceptable for you;
              </li>
              <li>
                Select a mutual insurance strategy:
                <ul className="faq-list-decimal" style={{ marginLeft: '30px' }}>
                  <li>
                    General safety deposit - 5%.
                    <div style={{ marginTop: '10px' }}>
                      Founder selects all founders (“swap partner”) as indemnified partners for
                      mutual insurance for in the calendar year.
                    </div>
                    <ul className="faq-list-dash" style={{ marginLeft: '30px' }}>
                      <li>
                        Founders agree to contribute up to 5% of the principal they borrow to a
                        mutual insurance pool. If a some founders fail to repay the loan from their
                        personal income, mutual insurance funds are used to cover;
                      </li>
                      <li>
                        When the strategy is selected, you can relax and wait for the end of the
                        fundraising round;
                      </li>
                    </ul>
                  </li>
                  <li>
                    Custom insurance - 20%.
                    <div style={{ marginTop: '10px' }}>
                      A founder selects partner founders (“swap partner”) for mutual insurance in
                      the fundraising round.
                    </div>
                    <ul className="faq-list-dash" style={{ marginLeft: '30px' }}>
                      <li>
                        Swap partners agree to contribute up to 20% of the principal they borrow to
                        a mutual insurance pool. If an insured founder fails to repay their personal
                        debt, his/her investors obtain compensation from the insurance pool.
                      </li>
                      <li>
                        When the round starts, you need to find mutual insurance partners among
                        other founders who participate in the fundraising round and select the same
                        strategy. So you choose which founders you trust and if they trust you back
                        or they trust someone who trusts you – you’ve got a mutual insurance
                        partner.
                      </li>
                      <li>
                        For every dollar you get, you must have at least 4 swap partners borrowing
                        at least 25 cents each. This is our way of ensuring that enough founders
                        trust you on the platform and that our investors are safe!
                      </li>
                      <li>When the round ends, all matches are calculated automatically.</li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li>
                Once the fundraising round is completed, investor money is allocated among founders
                who have successfully found swap partners;
              </li>
            </ul>
          </li>
          <li>
            <b>Withdraw funds.</b>
            <ul className="faq-list-dot">
              <li>
                Once the money is allocated to your Scramble account, you can{' '}
                <span className="underline">withdraw</span> it to your company’s bank account;
              </li>
            </ul>
          </li>
          <li>
            <b>Repay loans.</b>
            <ul className="faq-list-dot">
              <li>
                You <span className="underline">repay loans</span> with at least a monthly repayment
                amount.
              </li>
              <li>
                Annual interest is repaid monthly, starting from the 1st month. The loan principal
                and success fee are repaid in the last 6 months of the loan term.
              </li>
              <li>
                In case of successful repayment of loans by other founders, funds from the insurance
                pool are returned to the founders.
              </li>
            </ul>
          </li>
        </ul>
      </div>
    ),
  },
  how_much_does_it_cost: {
    q: 'How much does it cost?',
    a: (
      <div>
        <p>The loan cost consists of several parts:</p>
        <ul className="faq-list-decimal">
          <li>
            <b>100% Loan principal</b>
            <br />
            <div style={{ marginLeft: 20 + 'px' }}>The total amount that you borrow</div>
          </li>
          <li>
            <b>6% Interest rate</b>
            <br />
            <div style={{ marginLeft: 20 + 'px' }}>
              The annual interest rate paid monthly on outstanding loan principal
            </div>
          </li>
          <li>
            <b>12-35% One-time success fee</b>
            <br />
            <div style={{ marginLeft: 20 + 'px' }}>
              Paid only in case a startup survives and can repay the loan principal. A success fee
              is charged upon loan principal repayment. The success fee amount depends on the loan
              group.
            </div>
          </li>
          <li>
            <b>5% Security deposit</b>
            <br />
            <div style={{ marginLeft: 20 + 'px' }}>
              All startups funded during a calendar year contribute security deposits for investor
              protection. This deposit is held by Scramble.
              <br />
              Once loans are repaid, unused security deposits are refunded to startups.
            </div>
          </li>
        </ul>
        <p>
          Three loan groups available to a startup.
          <br />
          Founders pick A, B, or C loans depending on their particular business case. Loan groups
          have a different timeline, seniority of repayment, and success fees.{' '}
          <a href="/help/loan-terms">Learn more about loan groups</a>
        </p>
      </div>
    ),
  },
  when_do_fundraising_rounds_usually_start: {
    q: 'When do fundraising rounds usually start?',
    a: (
      <p>
        Fundraising rounds happen once a month and last for several days. You can find the actual
        dates of the following fundraising period in the app.
      </p>
    ),
  },
  how_can_i_withdraw_funds: {
    q: 'How can I withdraw funds?',
    a: (
      <div>
        <p>
          You can withdraw funds from your Scramble cash account to your bank at any time. Just fill
          in the form on the{' '}
          <Link to="/payments/withdraw" className="underline">
            Withdraw page
          </Link>
          .
        </p>
        <p>
          It usually takes 1-3 business days to withdraw money to a bank account within the
          Eurozone. Withdrawals outside the Eurozone may take a bit longer, depending on your bank.
        </p>
        <p>Scramble does not charge any withdrawal fees.</p>
        <p style={{ marginTop: 'unset' }}>
          However, your bank or money transfer service provider may charge additional fees for
          transfers. Please check with your bank or money transfer service provider for these fees.
        </p>
        <p>
          <b>Please keep in mind:</b> withdrawals are only available to companies with valid bank
          accounts (IBAN for EU-based companies). To activate your Scramble cash account, please
          transfer at least €1 from your valid bank account to your Scramble cash account.{' '}
          <Link to="/payments/add-funds" className="underline">
            Add funds →
          </Link>
        </p>
      </div>
    ),
  },
  how_can_i_add_funds_to_my_cash_account: {
    q: 'How can I add funds to my cash account?',
    a: (
      <div>
        <p>
          You can transfer funds from your bank account to your Scramble cash account.{' '}
          <Link to="/payments/add-funds" className="underline">
            See all methods to add funds →
          </Link>
        </p>
        <p>
          Deposits are accepted only from accounts that are holding the same name as the Scramble
          account. To transfer your funds, you need to indicate your Scramble account’s ID number.
        </p>
        <p>
          The processing time of the fund transfer will depend on the transfer method you choose.
          Usually, it takes 1-3 working days. If your transfer is not processed in 3 working days,
          please send a payment confirmation to{' '}
          <a href={`mailto:${CONTACT_EMAIL}`} className="underline">
            {CONTACT_EMAIL}
          </a>
          .
        </p>
      </div>
    ),
  },
  how_do_i_repay_loans: {
    q: 'How do I repay loans?',
    a: () => (
      <div>
        <p>
          Monthly repayments are due on the 5th day of the month following the month for which the
          repayment is due. On this day, the accrued amount is{' '}
          <b>automatically deducted from your Scramble cash account</b>.
        </p>
        <p>
          Your monthly repayment is calculated automatically and depends on your fundraising
          strategy and loan terms.
        </p>
        <p>
          At any point in time, you can prepay any of your loans in full without fees or penalties.
        </p>
      </div>
    ),
  },
  how_to_participate_in_a_fundraising_round: {
    q: 'How to participate in the fundraising round?',
    a: (
      <div>
        <p>
          It’s straightforward: just set your fundraising goal, select acceptable loan types, and an
          insurance strategy.
        </p>
        <p>
          You can edit or cancel your settings until the start of a fundraising round. Once the
          round starts, you can’t change your fundraising goal, loan offerings and your insurance
          strategy.
        </p>
        <p>When the fundraising round is completed, investor money is allocated among founders.</p>
      </div>
    ),
  },
  how_much_money_can_i_get: {
    q: 'How much money can I get?',
    a: ({ limitAmount, raiseAmount }) => (
      <div>
        <p>
          In total, you can get up to €250k loan per co-founder (depending on your personal credit
          limit). However, as a new customer, your fundraising is limited at {limitAmount || empty}{' '}
          per month. This is done to create a safer environment both for the investors and for
          potential swap partners.
        </p>
        <p>
          Once you’ve borrowed and then repaid financing for a certain amount, then you can raise up
          to that amount and
          {limitAmount || empty} more (as long as your total debt remains below your credit limit).
          For example, in September you’ve borrowed
          {limitAmount || empty}, and repaid it in January. Then in March you can raise up to
          {raiseAmount || empty}.
        </p>
      </div>
    ),
  },
  which_loan_groups_should_i_choose: {
    q: 'Which loan groups should I choose?',
    a: () => (
      <div>
        <p>
          Investors offer loans of three different groups - A, B, and C. Each group has its interest
          rate, success fee, term, and sequence of repayment.
        </p>
        <ul className="faq-list-dash" style={{ marginLeft: '20px' }}>
          <li>Group A loans are the cheapest and the first ones to be repaid from 13m to 18m;</li>
          <li>
            Group B loans have a medium price and can be repaid from 19m to 24m only after all Group
            A loans are repaid;
          </li>
          <li>
            Group C loans have the highest price and can be repaid from 25m to 30m only after all
            Group A and Group B loans are repaid;
          </li>
        </ul>
        <p>
          Obviously, less expensive loans are more attractive to founders. But there may not be
          enough supply of the cheapest loans. In that case, you may consider raising more expensive
          funds too.
        </p>
        <p>
          To maximize the chances of successfully achieving the fundraising goal, it’s better to
          select all loan groups: A, B, and C.
        </p>
        <p>Depending on your choice, here’s what happens:</p>
        <ul className="faq-list-dash" style={{ marginLeft: '20px' }}>
          <li>
            <b>Group A only</b>: we first try to achieve your fundraising goal with Group A funds.
            If there&apos;s not enough supply of Group A loans to reach your fundraising goal, you
            will not be able to raise the remainder with Group B and C loans;
          </li>
          <li>
            <b>Groups A and B</b>: we first try to achieve your fundraising goal with Group A funds,
            then – Group B funds. If there&apos;s not enough supply of Group A and B loans to reach
            your fundraising goal, you will not be able to raise the remainder with Group C loans;
          </li>
          <li>
            <b>Groups A, B and C</b>: we first try to achieve your fundraising goal with Group A
            funds, then – Group B funds, and Group C funds after.
          </li>
        </ul>
        <p>
          <a href="/help/loan-terms">Learn more about loan groups</a>
        </p>
      </div>
    ),
  },
  what_is_the_loan_group: {
    q: 'What is the loan group?',
    a: () => (
      <div>
        <p>
          Investors offer loans of three different groups - A, B, and C. Each group has its loan
          terms, success fee, and sequence of repayment.
        </p>
        <ul className="faq-list-dash">
          <li>Group A loans are the cheapest and the first ones to be repaid;</li>
          <li>
            Group B loans have a medium price and can be repaid only after all Group A loans are
            repaid;
          </li>
          <li>
            Group C loans have the highest price and can be repaid only after all Group A and Group
            B loans are repaid;
          </li>
        </ul>
        <p>
          To maximize the chances of successfully achieving the fundraising goal, it’s better to
          select all loan loan groups: A, B, and C.
        </p>
        <p>
          <Link to="/help/loan-terms" className="underline" style={{ marginTop: '20px' }}>
            Learn more about loan groups
          </Link>
        </p>
      </div>
    ),
  },
  what_is_the_annual_interest_rate: {
    q: 'What is the annual interest rate?',
    a: (
      <p>
        Annual interest is paid monthly on the outstanding loan principal.
        <br />
        The same annual interest rate applies if a startup is successful or a startup fails, and the
        loan is repaid by the founder personally.
      </p>
    ),
  },
  what_is_the_success_fee: {
    q: 'What is the Success fee?',
    a: (
      <p>
        The success fee is paid only in case a startup survives and can repay the loan principal.
        The amount of the success fee 12-35% depends on the loan group. We’ll charge it upon loan
        principal repayment.
      </p>
    ),
  },
  what_is_the_grace_period: {
    q: 'What is the grace period?',
    a: (
      <p>
        The grace period is the number of late payment months before the loan is marked as late.
        During the grace period, no interest is charged. If a startup can’t repay the loan fully
        within the grace period, the interest starts to accrue. If there&apos;s sufficient demand
        from investors, a loan may be refinanced at the end of the grace period.
      </p>
    ),
  },
  what_is_the_disbursement_fee: {
    q: 'What is the Disbursement Fee?',
    a: (
      <p>
        Loan disbursement fee is a one-time fee automatically charged at loan origination. It
        includes payments to investors and to the platform.
        <br /> The amount of the disbursement fee depends on the loan group.
      </p>
    ),
  },
  what_is_the_refinancing_fee: {
    q: 'What is the Refinancing Fee?',
    a: (
      <p>
        The loan refinancing fee is a one-time fee charged when refinancing a loan. It includes
        payments to investors and the platform.
        <br /> The amount of the refinancing fee depends on the loan group.
      </p>
    ),
  },
  what_is_the_interest_beyond_grace: {
    q: 'What is the Interest beyond grace?',
    a: () => (
      <p>
        Interest beyond grace - is an annual interest rate of
        {empty} that is applied daily on outstanding principal starting from the end of the grace
        period.
        <br />
        It consists of regular and delayed interest. Regular interest included into monthly
        payments, delayed interest is accumulated to your total debt until the loan is repaid in
        full. Interest rate is different for each loan group (A, B, and C).{' '}
        <Link to="#" className="underline">
          Learn more about loan terms →
        </Link>
      </p>
    ),
  },
  what_do_the_senior_subordinated_and_junior_loans_mean: {
    q: 'What does the Senior, Subordinated and Junior loans mean?',
    a: (
      <div>
        <p>Senior loans are the least risky because founders always repay Senior loans first.</p>
        <p>
          Subordinated loans are the medium risk because founders always repay Senior loans first,
          followed by Subordinated loans.
        </p>
        <p>
          Junior loans are the highest risk because founders always repay Senior loans first,
          followed by Subordinated loans and Junior loans.
        </p>
      </div>
    ),
  },
  what_about_the_financing_agreements: {
    q: 'What about the Financing Agreements?',
    a: () => (
      <div>
        <p>
          A founder shall approve the standard terms and conditions of the Financing agreement that
          will be concluded with the Investors due to the Fundraising round.
        </p>
        <p>
          You conclude the{' '}
          <Link to="/docs/Financing_agreement.pdf" className="underline" target="_blank">
            Financing agreement
          </Link>{' '}
          and the{' '}
          <Link to="/docs/Surety_agreement.pdf" className="underline" target="_blank">
            Surety agreement
          </Link>
          .
        </p>
      </div>
    ),
  },
  what_about_the_loan_terms: {
    q: 'What about the Loan terms?',
    a: (
      <p>
        Investors offer loans of three different groups - A, B, and C. Each group has its loan
        terms, success fee, and sequence of repayment.
        <br />
        <a href="/loant-terms/">Learn more about loan groups</a>
      </p>
    ),
  },
  which_strategy_should_i_choose: {
    q: 'Which strategy should I choose?',
    a: () => (
      <div>
        <p>We offer two insurance strategies:</p>
        <ul className="faq-list-decimal" style={{ marginLeft: '20px' }}>
          <li>
            General safety deposit &mdash; 5% (recommended)
            <ul className="faq-list-dash" style={{ marginLeft: '20px' }}>
              <li>Founder selects all founders for mutual insurance in the calendar year;</li>
              <li>
                Founders agree to contribute up to 5% of the principal they borrow to an insurance
                pool. If some founders fail to repay the loan from their personal income, mutual
                insurance funds are used to cover it;
              </li>
              <li>
                Once your swap partner repay their loans, the insurance part will be paid back to
                you;
              </li>
            </ul>
            <p>We recommend this strategy to most founders.</p>
          </li>
          <li>
            Custom insurance &mdash; 20%
            <ul className="faq-list-dash" style={{ marginLeft: '20px' }}>
              <li>
                A founder selects partner founders for mutual insurance in the fundraising round;
              </li>
              <li>
                Swap partners agree to contribute up to 20% of the principal they borrow to a mutual
                insurance pool. If an insured founder fails to repay their personal debt, his
                investors obtain compensation from the insurance pool;
              </li>
              <li>
                When the round starts, you need to find mutual insurance partners among other
                founders who participate in the fundraising round and select the same strategy. So
                you choose which founders you trust and if they trust you back or they trust someone
                who trusts you &mdash; you’ve got a mutual insurance partner;
              </li>
              <li>
                For every dollar you get, you must have at least 4 swap partners borrowing at least
                25 cents each. This is our way of ensuring that enough founders trust you on the
                platform and that our investors are safe!
              </li>
              <li>
                Once your swap partners repay their loans, the insurance part will be paid back to
                you.
              </li>
            </ul>
            <p>We recommend this strategy to founders who trust their swap partners well.</p>
            <ul className="faq-list-dash" style={{ marginLeft: '20px' }}>
              <li>I select founders myself</li>
              <li>Repayment is fast if everyone repays fast</li>
            </ul>
          </li>
        </ul>
      </div>
    ),
  },
  who_are_the_mutual_insurance_partners: {
    q: 'Who are the mutual insurance partners (“swap partners”)?',
    a: () => (
      <div>
        <p>
          The mutual insurance partners or swap partners are startups who are willing to jointly
          insure your investors against your personal default as a founder, and whose investors your
          startup is agreeing to insure against such default of their own founders.{' '}
        </p>
        <p>
          Imagine that your startup fails but you personally repay the debt as required. That is
          normally the case for all founders. However, there’s a chance that some founders will
          refuse to repay their personal obligations - either due to natural reasons such as death
          or because they are committing fraud. Mutual insurance is our way of protecting investors
          from such rare cases, ensuring high investor confidence, and ultimately creating ample
          supply of capital for startups.{' '}
        </p>
        <p>
          A startup can become your swap partner either directly (Startup A insures Startup B, and
          Startup B insures Startup A) or indirectly (Startup A insures Startup B, Startup B insures
          Startup C, and Startup C insures Startup A). In any case, each time you borrow $100 on the
          platform, you insure some other startups borrowing $100 between them, and they jointly
          insure you in return.
        </p>
        <p>
          To be funded, you will need to find at least
          {empty} swap partners among active founders who are also participating in the current
          fundraising round. This means that for every $1 you want to get for your startup, you will
          need at least
          {empty} other founders borrowing no more than
          {empty} cents each. The number of swap partners that you can get in the fundraising round
          is not limited.
        </p>
        <p>
          Importantly, swap partners are not releasing you from your personal debt. As a founder, it
          is you who is ultimately responsible for the borrowed amount of your startup. So if your
          startup fails, and then swap partners paid back all or some of the remaining debt to the
          investors, you will gradually repay them from your own personal income.
        </p>
      </div>
    ),
  },
  how_to_get_a_loan: {
    q: 'How to get a loan?',
    a: (
      <p>
        When the fundraising round is completed, investor`&apos;s money is allocated among founders,
        and loans are created.
        <br />
        The loan insurance amount is automatically charged. The remaining amount is automatically
        transferred to your cash account so that you can{' '}
        <a href="/payments/withdraw" className="underline">
          withdraw funds
        </a>{' '}
        to the company`&apos;s external bank account.
        <br />
        The Financing agreement for each loan is always available on the{' '}
        <a href="/loans" className="underline">
          Loans page
        </a>
        .
      </p>
    ),
  },
  where_can_i_find_a_list_of_loans: {
    q: 'Where can I find a list of loans?',
    a: (
      <p>
        You can find a list of loans on the{' '}
        <Link to="/loans" className="underline">
          All loans page
        </Link>
        .
      </p>
    ),
  },
  what_if_i_late_on_my_loan_repayment: {
    q: "What if I'm late on my loan repayment?",
    a: () => (
      <p>
        Each month you must make at least monthly repayment towards each loan. Scramble loans
        quickly become expensive if you do not maintain the repayment schedule. We charge a 3% of
        the loan principal penalty for each missed monthly repayment. Late fees are only applied to
        the startup and waived in case of a startup failure. So you don’t need to pay late fees if
        you’re repaying the loan as a person. If necessary, overdue loans can be refinanced on
        Scramble.
      </p>
    ),
  },
  where_can_i_see_the_repayment_schedule: {
    q: 'Where can I see the repayment schedule?',
    a: () => (
      <p>
        You can find the actual repayment schedule on the{' '}
        <a href="/payments/next-payments" className="underline">
          Next repayments page
        </a>
        .
      </p>
    ),
  },
  what_is_the_regular_loan_repayment_schedule: {
    q: 'What is the regular loan repayment schedule?',
    a: (
      <p>
        In general, the annual interest is repaid monthly, starting from the 1st month, then the
        loan principal and success fee are repaid in the last 6 months of the loan term.
        <br />
        The repayment schedule depends on the loan term.{' '}
        <a href="/help/loan-terms" className="underline">
          Learn more about loan groups
        </a>
        .
        <br />
        You can find the actual repayment schedule for your loans on the{' '}
        <a href="/payments/next-payments" className="underline">
          Next repayments page
        </a>
        .
      </p>
    ),
  },
  can_i_refuse_the_loans_i_received: {
    q: 'The round is over, but I haven’t reached my goal. Can I refuse the loans I received?',
    a: (
      <p>
        No, you cannot refuse the received loans.
        <br />
        To get more funds, try to take part in the next fundraising round. If you don&apos;t want
        to, you can{' '}
        <Link to="/payments/prepay-loans" className="underline">
          prepay
        </Link>{' '}
        the loan immediately, excluding the disbursement fee.
      </p>
    ),
  },
  what_if_i_want_to_repay_the_loan_early: {
    q: 'What if I want to repay the loan early?',
    a: (
      <div>
        <p>
          You can prepay up to 100% of your outstanding loans without any extra fees or penalties.
          To make an early repayment:
        </p>
        <ul className="faq-list-decimal" style={{ marginLeft: '20px' }}>
          <li>
            Add sufficient funds to your account. Go to
            <Link to="payments/add-funds" className="underline">
              Add funds
            </Link>{' '}
            page.
          </li>
          <li>
            Fill in the form on the
            <Link to="/payments/prepay-loans" className="underline">
              Prepay loans
            </Link>{' '}
            page.
          </li>
        </ul>
        <p>
          The amount you are prepaying will be automatically allocated among your loans following
          the{' '}
          <Link to="#" className="underline">
            platform`&apos;s repayment strategy
          </Link>
          .
        </p>
      </div>
    ),
  },
  what_about_the_overdue_loans: {
    q: 'What about the overdue loans?',
    a: () => (
      <div>
        <p>
          If you can’t repay the loan in full after the end of the loan term, an extra charge of 3%
          of the loan principal will be added to your debt monthly. You can refinance your overdue
          loans by getting a new loan. It is similar to how you received your original loan:
          participate in a fundraising round and get new funds. After the end of a fundraising
          round, all of your overdue loans will be automatically repaid using whatever new funds.
        </p>
      </div>
    ),
  },
  how_can_i_refinance_my_loans: {
    q: 'How can I refinance my loans?',
    a: (
      <div>
        <p>
          You can refinance your overdue loans by getting a new loan. This is similar to how you
          received your original loan: take part in a fundraising round and get new funds. After the
          end of a fundraising round all of your overdue loans will be automatically repaid using
          whatever new funds you’ve secured.
        </p>
        <p>
          Refinanced loans are also issued with a three-month grace period. Disbursement fee for
          refinanced loans is lower.
          <Link to="#" className="underline">
            Learn more about loan terms →
          </Link>
        </p>
      </div>
    ),
  },
  my_insurance_was_triggered_how_and_when_will_i_be_repaid: {
    q: 'My insurance was triggered and I’ve paid for my swap partner. How and when will I be repaid?',
    a: () => (
      <div>
        <p>
          In short, you will be repaid from the swap partner startup’s founder personal income over
          a period of time of up to
          {empty} months.
        </p>
        <p>
          More detail:
          <br />
          Any founder borrowing money on Scramble platform for their startup issues a personal
          guarantee for that debt. This means each founder is ultimately personally responsible for
          the repayment of loans taken by their startup.
        </p>
        <p>
          When a startup fails, the outstanding loan principal (excluding various charges and late
          fees, i.e. only originally borrowed amount not yet repaid) is assigned to the founder for
          repayment over as long as
          {empty} months from the date of loan disbursement. In the meantime, investors of the
          failed startup will be partially repaid using a mutual insurance pool from swap partners.{' '}
        </p>
        <p>
          As the failed startup’s founder finds a new job or starts a new project, she or he
          gradually repays their personal debt. Those repayments first go to repay the original
          investors, and then the remainder is used to repay the swap partners.
        </p>
      </div>
    ),
  },
  what_is_the_order_in_which_my_debts_are_paid_off: {
    q: 'What is the order in which my debts are paid off?',
    a: () => (
      <div>
        <p>Priority of loan repayments is defined by the Scramble loan repayment strategy.</p>
        <p>Group A loans are always repaid first, then Group B loans, and lastly, Group C loans.</p>
        <p>Loans are repaid in the following order:</p>
        <ul className="faq-list-decimal" style={{ marginLeft: '30px' }}>
          <li>
            Group A loans
            <ul className="faq-list-dash" style={{ marginLeft: '20px' }}>
              <li>Interest rate for group A active loans;</li>
              <li>Interest rate, Principal and Success fee repayment for group A overdue loans;</li>
              <li>Principal and Success fee repayment for group A active loans;</li>
              <li>Charge for late payments for group A loans.</li>
            </ul>
          </li>
          <li>
            Group B loans
            <ul className="faq-list-dash" style={{ marginLeft: '20px' }}>
              <li>Interest rate for group B active loans;</li>
              <li>Interest rate, Principal and Success fee repayment for group B overdue loans;</li>
              <li>Principal and Success fee repayment for group B active loans;</li>
              <li>Charge for late payments for group B loans.</li>
            </ul>
          </li>
          <li>
            Group C loans
            <ul className="faq-list-dash" style={{ marginLeft: '20px' }}>
              <li>Interest rate for group C active loans;</li>
              <li>Interest rate, Principal and Success fee repayment for group C overdue loans;</li>
              <li>Principal and Success fee repayment for group C active loans;</li>
              <li>Charge for late payments for group C loans.</li>
            </ul>
          </li>
        </ul>
        <p>Within each type of debt, money is distributed in proportion to the loan amount.</p>
      </div>
    ),
  },
  what_if_my_startup_fails: {
    q: 'What if my startup fails?',
    a: () => (
      <div>
        <p>
          If your startup fails, you must repay outstanding loans (excluding any late fees, charges,
          etc., accumulated before the startup failed) from your future personal income over up to 5
          years from the initial loan disbursement. You will be charged a 6% annual interest rate on
          this personal debt.
        </p>
        <p>
          Also, you have 3-month holidays right after startup fails. You can repay the debt
          partially or in full at any point in time without any early repayment charges or
          penalties.
        </p>
      </div>
    ),
  },
  where_can_i_find_the_account_statement: {
    q: 'Where can I find the account statement?',
    a: (
      <p>
        You can find the account statement on the{' '}
        <Link to="/activity" className="underline">
          Activity page
        </Link>
        .
      </p>
    ),
  },
  can_i_change_the_bank_account_i_withdraw_funds_to: {
    q: 'Can I change the bank account I withdraw funds to?',
    a: (
      <div>
        <p>
          We can only transfer funds to a bank account that belongs to you. This is a necessary
          precaution to protect our users and comply with anti-money laundering (AML) requirements.
        </p>
        <p>
          To change the bank account you withdraw funds to, you need to transfer at least €1 from
          your new bank account to your Scramble cash account.{' '}
          <Link to="/payments/add-funds" className="underline">
            Add funds →
          </Link>
        </p>
        <p>
          All bank accounts used to transfer funds to Scramble will be saved under your profile, and
          you will be able to choose from any of these accounts when requesting the withdrawal.
        </p>
      </div>
    ),
  },
  why_do_i_need_to_verify: {
    q: 'Why do I need to verify?',
    a: (
      <p>
        We’re committed to providing a safe marketplace and preventing financial crime. That
        includes verifying the identity of our founders and investors to make sure it’s precisely
        you creating an account. So, we follow all applicable laws for the payment industry.
      </p>
    ),
  },
  which_data_will_be_stored_during_verification_with_veriff: {
    q: 'Which data will be stored during verification with Veriff?',
    a: (
      <div>
        <p>
          We’ll store the following data: a photo of your face and a photo of your identity document
          (front and back).
        </p>
        <p>
          We`&apos;ll handle your data following the General Data Protection Regulation (GDPR) and
          other national binding laws on personal data protection and will only use it to comply
          with our legal obligations.
        </p>
      </div>
    ),
  },
  how_can_i_start_the_verification_process: {
    q: 'How can I start the verification process?',
    a: (
      <div>
        <p>To start the verification process:</p>
        <ul className="faq-list-decimal" style={{ marginLeft: '20px' }}>
          <li>Log in to your account</li>
          <li>
            Go to{' '}
            <Link to="/profile" className="underline">
              Profile
            </Link>{' '}
            page
          </li>
          <li>Provide all necessary details about you and your company</li>
          <li>Verify your identity with Veriff service</li>
        </ul>
        <p>
          Identity verification is provided by Veriff’s (
          <a
            href="https://www.veriff.com/"
            className="underline"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.veriff.com/
          </a>
          ) highly trusted and secure solution.
          <br />
          If you have technical issues, please contact our support at{' '}
          <a href={`mailto:${CONTACT_EMAIL}`} className="underline">
            {CONTACT_EMAIL}
          </a>
        </p>
        <p>
          When you successfully verify your identity, provide your details and details about your
          company. We will check your profile information; it may take up to 1 business day.
        </p>
        <p>
          When the verification process is complete, you will receive an email with the results.
        </p>
      </div>
    ),
  },
};
