import * as React from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  FACEBOOK_LINK_REGEX,
  LINKEDIN_LINK_REGEX,
  TWITTER_LINK_REGEX,
} from '../../../helpers/common';
import TextareaField from '../../UI/TextareaField';
import { InputTextField } from '../../UI/InputTextField';
import CountrySelectField from '../../UI/CountrySelectField';
import { RoundButton } from '../../UI/RoundButton';
import './index.scss';

const VALIDATION_SCHEMA = Yup.object({
  first_name: Yup.string()
    .max(40, 'First name exceeds limit of 40')
    .required('First name is required'),
  last_name: Yup.string()
    .max(40, 'Last name exceeds limit of 40')
    .required('Last name is required'),
  citizenship: Yup.string().required('Citizenship is required'),
  description: Yup.string().max(1000, 'About me exceeds limit of 1000'),
  facebook: Yup.string().matches(FACEBOOK_LINK_REGEX, 'Incorrect link'),
  twitter: Yup.string().matches(TWITTER_LINK_REGEX, 'Incorrect link'),
  linkedin: Yup.string().matches(LINKEDIN_LINK_REGEX, 'Incorrect link'),
});

class ProfileAboutForm extends React.Component {
  getInitialValues = (user, about) => ({
    first_name: (user && user.first_name) || '',
    last_name: (user && user.last_name) || '',
    citizenship: (user && user.citizenship) || '',
    citizenship_code: (user && user.citizenship_code) || '',
    facebook: (user && user.facebook) || '',
    twitter: (user && user.twitter) || '',
    linkedin: (user && user.linkedin) || '',
    description: about || '',
  });

  render() {
    const { userLocation, user, description, onSubmit, onCancel } = this.props;
    return (
      <Formik
        enableReinitialize
        initialValues={this.getInitialValues(user, description, userLocation)}
        validationSchema={VALIDATION_SCHEMA}
        onSubmit={(values, formikBag) => onSubmit(values, formikBag)}
      >
        {({ values, setFieldValue, errors, touched, handleChange, handleSubmit }) => (
          <form className="profile-personal-form" onSubmit={handleSubmit}>
            <h1 className="profile-personal-form__title f-32 f-500">Edit personal information</h1>
            <div className="profile-personal-form__grid">
              <div className="profile-personal-form__grid-left">
                <InputTextField
                  label="First name *"
                  name="first_name"
                  value={values.first_name}
                  onChange={handleChange}
                  onClear={() => setFieldValue('first_name', '')}
                  error={errors.first_name && touched.first_name && errors.first_name}
                  className="profile-personal-form__field"
                />
                <InputTextField
                  label="Last name *"
                  name="last_name"
                  value={values.last_name}
                  onChange={handleChange}
                  onClear={() => setFieldValue('last_name', '')}
                  error={errors.last_name && touched.last_name && errors.last_name}
                  className="profile-personal-form__field"
                />
              </div>
              <div className="profile-personal-form__grid-right">
                <CountrySelectField
                  label="Citizenship *"
                  name="citizenship"
                  value={values.citizenship_code}
                  onChange={(country, code) => {
                    setFieldValue('citizenship', country);
                    setFieldValue('citizenship_code', code);
                  }}
                  error={errors.citizenship && touched.citizenship && errors.citizenship}
                  className="profile-personal-form__field"
                />
              </div>
            </div>

            <h2 className="profile-personal-form__subtitle f-20 f-500">About me</h2>
            <TextareaField
              label=""
              name="description"
              minRows={4}
              value={values.description}
              placeholder="Introduce yourself to the investors (describe your outstanding achievements, education, work experience, company, hobbies)"
              onChange={handleChange}
              limit={1000}
              error={errors.description && touched.description && errors.description}
            />

            <h2 className="profile-personal-form__subtitle f-20 f-500">Social networks</h2>
            <div className="profile-personal-form__grid">
              <InputTextField
                label="Facebook Link"
                name="facebook"
                value={values.facebook}
                onChange={handleChange}
                onClear={() => setFieldValue('facebook', '')}
                error={errors.facebook && touched.facebook && errors.facebook}
                className="profile-personal-form__field"
              />
              <InputTextField
                label="LinkedIn Link"
                name="linkedin"
                value={values.linkedin}
                onChange={handleChange}
                onClear={() => setFieldValue('linkedin', '')}
                error={errors.linkedin && touched.linkedin && errors.linkedin}
                className="profile-personal-form__field"
              />
              <InputTextField
                label="Twitter Link"
                name="twitter"
                value={values.twitter}
                onChange={handleChange}
                onClear={() => setFieldValue('twitter', '')}
                error={errors.twitter && touched.twitter && errors.twitter}
                className="profile-personal-form__field"
              />
            </div>

            <div className="profile-personal-form__buttons">
              {onCancel && (
                <RoundButton
                  label="Cancel"
                  type="button"
                  fullWidth
                  onClick={onCancel}
                  className="profile-personal-form__button"
                />
              )}
              <RoundButton
                label="Save"
                type="submit"
                onSubmit={handleSubmit}
                fillBackground
                fullWidth
                className="profile-personal-form__button"
              />
            </div>
          </form>
        )}
      </Formik>
    );
  }
}

export default ProfileAboutForm;
