import { LOGOUT_USER } from '../actions/actionTypes';
import {
  GET_BALANCE,
  GET_DASH_BALANCE_INFO,
  GET_DASH_DEPOSIT_INFO,
  GET_DASH_PAYMENT_INFO,
  GET_FR_DATES,
} from '../types/dashboardTypes';

const initialState = {
  balance: null,
  fundraisingDates: null,
  dashBalanceInfo: null,
  dashPaymentInfo: null,
  dashDepositInfo: null,
};

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_BALANCE:
      return { ...state, balance: action.balance };
    case GET_FR_DATES:
      return { ...state, fundraisingDates: action.payload };
    case GET_DASH_BALANCE_INFO:
      return { ...state, dashBalanceInfo: action.payload };
    case GET_DASH_PAYMENT_INFO:
      return { ...state, dashPaymentInfo: action.payload };
    case GET_DASH_DEPOSIT_INFO:
      return { ...state, dashDepositInfo: action.payload };

    case LOGOUT_USER:
      return initialState;
    default:
      return state;
  }
};

export default dashboardReducer;
