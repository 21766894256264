import React from 'react';
import { useDispatch } from 'react-redux';
import { convertDateToString } from '../../helpers/utils';
import { createCareer, deleteCareers, updateCareer } from '../../store/services/userServices';
import ProfileCareerForm from '../../components/Forms/ProfileCareerForm';
import { getUserProfile } from '../../store/actions/profileActions';

const ProfileCareerModal = ({ onClose, career }) => {
  const dispatch = useDispatch();

  const onSubmit = async values => {
    const payload = {
      company: values.company,
      position: values.position,
      start_date: convertDateToString(values.start_date),
      end_date: convertDateToString(values.end_date),
      is_working: !values.end_date,
    };

    const res = career ? await updateCareer(career.id, payload) : await createCareer(payload);

    if (res && res.success) {
      dispatch(getUserProfile());
      onClose();
    }
  };

  const onRemove = async () => {
    try {
      if (career) {
        const res = await deleteCareers({ careers: [career.id] });
        if (res && res.success) {
          dispatch(getUserProfile());
          onClose();
        }
      }
    } catch (e) {}
  };

  return (
    <ProfileCareerForm career={career} onSubmit={onSubmit} onRemove={onRemove} onCancel={onClose} />
  );
};

export default ProfileCareerModal;
