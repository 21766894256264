import React from 'react';
import classnames from 'classnames';
import { APP_LINKS } from '../../../helpers/links';
import { prettyFloatMoney } from '../../../helpers/utils';
import WarningBlock from '../../../components/UI/WarningBlock';
import { RoundLink } from '../../../components/UI/RoundLink';
import s from './index.module.scss';

export const PaymentSuccess = ({ amount }) => {
  return (
    <div className={s.wrapper}>
      <h1 className={classnames(s.title, 'f-26 f-500')}>Payment successful!</h1>

      <WarningBlock withBorder className={s.message} isSuccess>
        <div className="f-16 f-400">
          Your payment of <span className="f-500">{prettyFloatMoney(amount)}</span> was successfully
          transferred to your Scramble account.
        </div>
      </WarningBlock>

      <div className={s.buttons}>
        <RoundLink
          path={APP_LINKS.dashboard}
          label="Go to dashboard"
          fillBackground
          className={s.button}
        />
        <RoundLink path={APP_LINKS.addFunds} label="Add more cash" className={s.button} />
      </div>
    </div>
  );
};
