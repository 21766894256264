export const GET_USER_PROFILE = {
  REQUEST: 'GET_USER_PROFILE_REQUEST',
  SUCCESS: 'GET_USER_PROFILE_SUCCESS',
  FAILURE: 'GET_USER_PROFILE_FAILURE',
};
export const GET_BANK_STATEMENTS = {
  REQUEST: 'GET_BANK_STATEMENTS_REQUEST',
  SUCCESS: 'GET_BANK_STATEMENTS_SUCCESS',
  FAILURE: 'GET_BANK_STATEMENTS_FAILURE',
};
export const SET_USER_PROFILE = 'SET_USER_PROFILE';
export const UPDATE_CONTACT_PROFILE = 'UPDATE_CONTACT_PROFILE';
export const UPDATE_COMPANY_PROFILE = 'UPDATE_COMPANY_PROFILE';
export const UPDATE_PHOTO_PROFILE = 'UPDATE_PHOTO_PROFILE';
export const SET_PROFILE_MISSING_DETAILS = 'SET_PROFILE_MISSING_DETAILS';
