import React, { useState } from 'react';
import classnames from 'classnames';
import { ClipboardIcon } from '../Icons/ClipboardIcon';
import './index.scss';

const CopyButton = ({ text, onSuccess, onFailure }) => {
  const [isCopied, setIsCopied] = useState(false);

  const onClick = () => {
    setIsCopied(true);
    navigator.clipboard.writeText(text).then(onSuccess, onFailure);
  };

  return (
    <button
      type="button"
      onClick={onClick}
      className={classnames('copy_button', isCopied ? 'copied' : 'copy')}
      onMouseLeave={() => setIsCopied(false)}
    >
      <ClipboardIcon text={text} />
      <span className="f-14 f-400">{isCopied ? 'copied' : 'copy'}</span>
    </button>
  );
};

export default CopyButton;
