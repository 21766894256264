import { METADATA } from '../../helpers/metadata';
import { LOGOUT_USER } from '../actions/actionTypes';
import {
  GET_BANK_STATEMENTS,
  GET_USER_PROFILE,
  SET_PROFILE_MISSING_DETAILS,
  SET_USER_PROFILE,
  UPDATE_COMPANY_PROFILE,
  UPDATE_CONTACT_PROFILE,
  UPDATE_PHOTO_PROFILE,
} from '../types/profileTypes';

const initialState = {
  profile: { ...METADATA.default, data: null },
  bankStatementList: { ...METADATA.default, data: null },
  profileMissingDetails: {},
};

const profileReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_PROFILE.REQUEST:
      return { ...state, profile: { ...state.profile, ...METADATA.request } };
    case GET_USER_PROFILE.SUCCESS:
      return {
        ...state,
        profile: {
          ...state.profile,
          ...METADATA.success,
          data: action.payload,
        },
      };
    case GET_USER_PROFILE.FAILURE:
      return {
        ...state,
        profile: { ...state.profile, ...METADATA.error, error: action.error },
      };
    case SET_USER_PROFILE:
      return { ...state, profile: { ...state.profile, data: action.profile } };
    case UPDATE_CONTACT_PROFILE:
      return {
        ...state,
        profile: { ...state.profile, ...METADATA.success, data: action.data },
      };
    case UPDATE_PHOTO_PROFILE:
    case UPDATE_COMPANY_PROFILE:
      return {
        ...state,
        profile: {
          ...state.profile,
          ...METADATA.success,
          data: action.payload,
        },
      };
    case SET_PROFILE_MISSING_DETAILS:
      return { ...state, profileMissingDetails: action.payload };
    case GET_BANK_STATEMENTS.REQUEST:
      return {
        ...state,
        bankStatementList: { ...state.bankStatementList, ...METADATA.request },
      };
    case GET_BANK_STATEMENTS.SUCCESS:
      return {
        ...state,
        bankStatementList: {
          ...state.bankStatementList,
          ...METADATA.success,
          data: action.payload,
        },
      };
    case GET_BANK_STATEMENTS.FAILURE:
      return {
        ...state,
        bankStatementList: {
          ...state.bankStatementList,
          ...METADATA.error,
          error: action.error,
        },
      };

    case LOGOUT_USER:
      return initialState;
    default:
      return state;
  }
};

export default profileReducer;
