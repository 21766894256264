import React from 'react';
import classnames from 'classnames';
import { FileName } from '../../FileName';
import { FileUploadField } from '../../../components/UI/FileUploadField';
import { ALLOWED_FILE_SIZES, DOCUMENT_FORMATS } from '../../../helpers/common';
import { checkForValidFile } from '../../../helpers/utils';
import { Tooltip } from '../../Tooltip';
import s from './index.module.scss';

export const BasicUploadField = ({
  name,
  value = [],
  error,
  onBlur,
  onChange,
  onError,
  onRemove,
  label,
  className,
  uploadLabel = 'Upload the PDF',
  uploadDescription = 'up to 1Mb',
  acceptFormat = DOCUMENT_FORMATS,
  acceptSize = ALLOWED_FILE_SIZES['1mb'],
  isRequired,
  multiple,
  tooltipContent,
}) => {
  return (
    <div className={classnames(s.wrapper, className)}>
      <div className={classnames(s.label, 'f-16')}>
        {label} {isRequired && '*'}
        {tooltipContent && <Tooltip renderContent={tooltipContent} />}
      </div>
      {value.length ? (
        <div className={s.files}>
          {value.map((file, index) => (
            <FileName key={index} file={file} onRemove={onRemove} />
          ))}
        </div>
      ) : (
        <FileUploadField
          name={name}
          multiple={multiple}
          label={uploadLabel}
          description={uploadDescription}
          accept={acceptFormat}
          error={!!error}
          className={s.upload}
          onBlur={onBlur}
          onChange={e => {
            const files = Object.keys(e.target.files).map(idx => e.target.files[idx]);
            const validFiles = files.filter(file => {
              const { isValid, type, size } = checkForValidFile(file, acceptFormat, acceptSize);
              !isValid && size && onError('File size is too big');
              !isValid && type && onError('Incorrect file type. Upload file in PDF format.');
              return isValid;
            });
            onChange(validFiles);
          }}
        />
      )}
    </div>
  );
};
