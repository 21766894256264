import React, { useEffect, useState } from 'react';
import { generateCoFounderInviteCode } from '../../store/services/userServices';
import Preloader from '../../components/UI/Preloader';
import config from '../../config';
import { RoundButton } from '../../components/UI/RoundButton';
import Notify from '../../components/Notification';
import './index.scss';

const CoFounderInvitation = () => {
  const [loading, setLoading] = useState(true);
  const [code, setCode] = useState('');

  useEffect(() => {
    generateCode();
  }, []);

  const generateCode = async () => {
    try {
      const res = await generateCoFounderInviteCode();
      res && res.success && setCode(res.data.code);
    } finally {
      setLoading(false);
    }
  };

  const link = code && `${config.baseURL}/sign-up/co-founder/${code}`;

  return (
    <div className="co-founder-invitation">
      {loading ? (
        <Preloader />
      ) : (
        <>
          <div className="f-32 f-500">
            {link
              ? 'Invite co-founders to your company account'
              : "Couldn't generate link, please try again"}
          </div>
          {link && (
            <div className="co-founder-invitation__desc f-18 f-400">
              Invite co-founders through the link below. By following the link, co-founders can
              register on the platform.
            </div>
          )}
          {link && <input type="text" value={link} readOnly className="f-16 f-400" />}
          <div className="co-founder-invitation__buttons">
            {code ? (
              <RoundButton
                label="Copy link"
                fullWidth
                fillBackground
                className="co-founder-invitation__copy-btn"
                onClick={() =>
                  navigator &&
                  navigator.clipboard &&
                  navigator.clipboard.writeText(link).then(() => {
                    Notify.success({ text: 'Link copied' });
                  })
                }
              />
            ) : (
              <RoundButton
                label="Generate link"
                onClick={() => {
                  setLoading(true);
                  generateCode();
                }}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default CoFounderInvitation;
