import React from 'react';
import withScroll from '../../hoc/withScroll';
import './index.scss';

const SwapGuaranteePage = () => (
  <div className="swap-guarantee-page">
    <div className="container-inner">
      <h1 className="f-42 f-500">What is the mutual swap guarantee?</h1>
      <div className="swap-guarantee-page__list">
        <p className="f-16">
          Mutual repayment swap guarantee is your calculated bet on the founders you personally
          trust.
        </p>
        <div className="swap-guarantee-page__list-item">
          <p className="f-20 f-500">
            Startups agree to repay for each other if eihter company fails and the other one
            succeeds
          </p>
        </div>
        <div className="swap-guarantee-page__list-item">
          <p className="f-20 f-500">
            Swaps can be done monthly across all founders on the platform
          </p>
        </div>
        <div className="swap-guarantee-page__list-item">
          <p className="f-20 f-500">
            If both companies fail, unpaid amount is picked by founders as individuals
          </p>
        </div>
        <div className="swap-guarantee-page__list-item">
          <p className="f-20 f-500">No more than 25% of the loan amount per one swap partner</p>
        </div>

        <div className="swap-guarantee-page__financing">
          <p className="f-16">
            Financing remains founder-friendly regardless if your company succeeds or fails:
          </p>
          <h3 className="f-16 f-500">If my company succeeds</h3>
          <p className="f-16">
            Worst case, I repay 2x the original loan. That would happen if all of my swap pair
            startups fail as businesses, AND after that their founders refuse to repay loans from
            their personal income AND the platform is unable to locate and collect debt from those
            founders. In such worst case, my own cost of money is still 10x lower than for an equity
            investment.
          </p>
          <h3 className="f-16 f-500">If my company fails</h3>
          <p className="f-16">
            My swap pairs will keep repaying my debt to the investors on the original schedule. In
            the meantime, I have 60 months to gradually repay the unpaid original borrowed amount at
            a low interest rate (5%) back to my swap pairs. I’m not personally liable for the unpaid
            accumulated interest, late payment charges, or other fees of my failed startup.
          </p>
        </div>
      </div>
    </div>
  </div>
);

export default withScroll(SwapGuaranteePage);
