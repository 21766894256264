import { createBrowserHistory } from 'history';
import originalAxios from 'axios';
import moment from 'moment';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import thunkMiddleware from 'redux-thunk';
import axios from '../axios-api';
import config from '../config';
import { SET_TOKEN } from './actions/actionTypes';
import { loadFromLocalStorage, saveToLocalStorage } from './localStorage';
import { logoutUser } from './actions/userActions';
import userReducer from './reducers/userReducer';
import commonReducer from './reducers/commonReducer';
import fundraisingReducer from './reducers/fundraisingReducer';
import paymentReducer from './reducers/paymentReducer';
import dashboardReducer from './reducers/dashboardReducer';
import founderReducer from './reducers/founderReducer';
import activityReducer from './reducers/activityReducer';
import loanReducer from './reducers/loanReducer';
import profileReducer from './reducers/profileReducer';
import startupReducer from './reducers/startupReducer';
import helpReducer from './reducers/helpReducer';

export const history = createBrowserHistory();

const rootReducer = combineReducers({
  router: connectRouter(history),
  userStore: userReducer,
  commonStore: commonReducer,
  fundraisingStore: fundraisingReducer,
  paymentStore: paymentReducer,
  dashboardStore: dashboardReducer,
  founderStore: founderReducer,
  activityStore: activityReducer,
  loanStore: loanReducer,
  profileStore: profileReducer,
  startupStore: startupReducer,
  helpStore: helpReducer,
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const middleware = [thunkMiddleware, routerMiddleware(history)];

if (process.env.NODE_ENV === `development`) {
  // eslint-disable-next-line global-require
  const { createLogger } = require(`redux-logger`);
  const logger = createLogger({
    diff: true,
    collapsed: true,
    titleFormatter: action => `[action] ${action.type}`,
    level: { prevState: false, nextState: false, error: false },
  });
  middleware.push(logger);
}

const enhancers = composeEnhancers(applyMiddleware(...middleware));

const persistedState = loadFromLocalStorage();

const store = createStore(rootReducer, persistedState, enhancers);

store.subscribe(() => {
  saveToLocalStorage({
    userStore: {
      user: store.getState().userStore.user,
      token: store.getState().userStore.token,
      expireDate: store.getState().userStore.expireDate,
    },
  });
});

axios.interceptors.request.use(config => {
  try {
    const { expireDate, token } = store.getState().userStore;

    if (expireDate && moment(expireDate).isBefore()) {
      return store.dispatch(logoutUser());
    }

    if (token?.access_token) {
      config.headers.Authorization = `Token ${token.access_token}`;
    }
  } catch (e) {
    // do nothing, user is not logged in
  }

  return config;
});

let isRefreshingToken = false;
axios.interceptors.response.use(
  response => response,
  error => {
    if (error.response?.status === 401) {
      const tokenExpireMessage = 'access_token expired';

      if (error.response?.data?.message === tokenExpireMessage) {
        const originalConfig = error.config;
        const { token } = store.getState().userStore;

        // checks the store state, if there isn't any refresh token proccessing attempts to get new token and retry the failed request
        if (!isRefreshingToken) {
          return new Promise(resolve => {
            // updates the state in store so other failed API with 401 error doesnt get to call the refresh token request
            isRefreshingToken = true;

            originalAxios
              .post(config.apiURL + 'token/refresh/', {
                refresh_token: token.refresh_token,
              })
              .then(
                res => {
                  if (res.status === 200) {
                    // We get new access token, trying to re-fetch with new token
                    store.dispatch({
                      type: SET_TOKEN,
                      token: {
                        access_token: res.data.access_token,
                        refresh_token: res.data.refresh_token,
                      },
                    });
                    originalConfig.headers.Authorization = `Bearer ${res.data.access_token}`;
                    return resolve(axios(originalConfig));
                  }
                },
                () => {
                  // Refresh token expired, force logout
                  store.dispatch(logoutUser());
                  window.location.reload();
                }
              )
              .catch(() => {
                store.dispatch(logoutUser());
                window.location.reload();
              })
              .finally(() => {
                isRefreshingToken = false;
              });
          });
        }

        return new Promise(resolve => {
          // in a 100ms time interval checks the store state
          const intervalId = setInterval(() => {
            // if the state indicates that there is no refresh token request anymore, it clears the time interval and retries the failed API call with updated token data
            if (!isRefreshingToken) {
              clearInterval(intervalId);
              resolve(axios(originalConfig));
            }
          }, 100);
        });
      }
      store.dispatch(logoutUser());
      window.location.reload();
    }

    return error.response ? error.response : { data: { message: error.message } };
  }
);

export default store;
