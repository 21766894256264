import React from 'react';
import classnames from 'classnames';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { InputTextField } from '../../UI/InputTextField';
import { BankAccountNumberField } from '../../../kit/form/input/BankAccountNumberField';
import { BankStatementUploadField } from '../../../kit/form/BankStatementUploadField';
import { SepaZoneCountrySelect } from './SepaZoneCountrySelect';
import { RoundButton } from '../../UI/RoundButton';
import { getFlagURL } from '../../../helpers/utils';
import { RoundLink } from '../../UI/RoundLink';
import { APP_LINKS } from '../../../helpers/links';
import s from './index.module.scss';

const VALIDATION_SCHEMA = Yup.object({
  country: Yup.mixed().required('Country is required'),
  beneficiary_name: Yup.string().required('Beneficiary name is required'),
  in_sepa_zone: Yup.boolean(),
  is_valid_sepa: Yup.bool().oneOf([true], 'Valid IBAN is required'),
  account_number: Yup.string().required('IBAN is required'),
  files: Yup.array()
    .min(1, 'Bank statement required field')
    .required('Bank statement required field'),
  swift: Yup.string().when('in_sepa_zone', {
    is: isSepa => isSepa !== true,
    then: Yup.string().required('SWIFT/BIC code is required'),
  }),
  beneficiary_address: Yup.string().when('in_sepa_zone', {
    is: isSepa => isSepa !== true,
    then: Yup.string().required('Address is required'),
  }),
  beneficiary_bank: Yup.string().when('in_sepa_zone', {
    is: isSepa => isSepa !== true,
    then: Yup.string().required('Bank name is required'),
  }),
  beneficiary_bank_address: Yup.string().when('in_sepa_zone', {
    is: isSepa => isSepa !== true,
    then: Yup.string().required('Bank address is required'),
  }),
});

export const BankDetailForm = ({ data, onSubmit }) => (
  <Formik
    onSubmit={onSubmit}
    validationSchema={VALIDATION_SCHEMA}
    initialValues={{
      id: data?.id,
      country: data
        ? {
            value: data.country_code,
            code: data.country_code,
            label: data.country,
            flag: getFlagURL(data.country_code),
          }
        : null,
      in_sepa_zone: !!data?.is_sepa_zone,
      is_valid_sepa: false,
      files: data?.bank_statement ? [data.bank_statement] : [],
      beneficiary_name: data?.beneficiary_name || '',
      account_number: data?.account_number || '',
      bank_statement: data?.bank_statement?.id,
      swift: data?.swift || '',
      beneficiary_address: data?.beneficiary_address || '',
      beneficiary_bank: data?.beneficiary_bank || '',
      beneficiary_bank_address: data?.beneficiary_bank_address || '',
    }}
  >
    {({
      values,
      errors,
      touched,
      dirty,
      setValues,
      handleBlur,
      handleChange,
      setFieldError,
      handleSubmit,
      setFieldValue,
    }) => (
      <form onSubmit={handleSubmit} className={s.form}>
        <h1 className="f-26 f-500">Bank account info</h1>
        {data?.review_status === 'rejected' && (
          <div className={classnames(s.reason, 'f-14')}>{data.rejected_reason}</div>
        )}

        <div className={s.inner}>
          <SepaZoneCountrySelect
            value={values.country}
            onChange={data => setValues({ ...values, ...data })}
            label="Select your bank country *"
            error={errors.country && dirty && errors.country}
          />

          <InputTextField
            onChange={handleChange}
            name="beneficiary_name"
            label="Beneficiary name *"
            value={values.beneficiary_name}
            onBlur={handleBlur}
            error={errors.beneficiary_name && touched.beneficiary_name && errors.beneficiary_name}
          />

          {!values.in_sepa_zone && (
            <>
              <InputTextField
                onChange={handleChange}
                name="swift"
                label="SWIFT/BIC code *"
                value={values.swift}
                onBlur={handleBlur}
                error={errors.swift && touched.swift && errors.swift}
              />

              <InputTextField
                onChange={handleChange}
                name="beneficiary_address"
                label="Address *"
                value={values.beneficiary_address}
                onBlur={handleBlur}
                error={
                  errors.beneficiary_address &&
                  touched.beneficiary_address &&
                  errors.beneficiary_address
                }
              />

              <InputTextField
                onChange={handleChange}
                name="beneficiary_bank"
                label="Bank name *"
                value={values.beneficiary_bank}
                onBlur={handleBlur}
                error={
                  errors.beneficiary_bank && touched.beneficiary_bank && errors.beneficiary_bank
                }
              />

              <InputTextField
                onChange={handleChange}
                name="beneficiary_bank_address"
                label="Bank address *"
                value={values.beneficiary_bank_address}
                onBlur={handleBlur}
                error={
                  errors.beneficiary_bank_address &&
                  touched.beneficiary_bank_address &&
                  errors.beneficiary_bank_address
                }
              />
            </>
          )}

          <BankAccountNumberField
            onChange={handleChange}
            name="account_number"
            value={values.account_number}
            showSepa={values.in_sepa_zone}
            label="Bank account number (IBAN) *"
            countryCode={values.country?.code}
            error={errors.account_number || values.is_valid_sepa}
            onValidate={({ is_valid }) =>
              setFieldValue(
                'is_valid_sepa',
                is_valid ? '' : 'Incorrect IBAN format for the selected country'
              )
            }
          />
          <BankStatementUploadField
            name="files"
            value={values.files}
            error={errors.files && touched.files && errors.files}
            onBlur={handleBlur}
            onChange={files => setFieldValue('files', files)}
            onError={message => setFieldError('files', message)}
            onRemove={() => setFieldValue('files', [])}
          />
        </div>
        <div className={s.controls}>
          <RoundLink label="Back" className="hov" path={APP_LINKS.bankAccounts} />
          <RoundButton
            type="submit"
            label={data ? 'Save' : 'Submit'}
            className="hov"
            disabled={!dirty || Object.keys(errors).length}
            fillBackground
          />
        </div>
      </form>
    )}
  </Formik>
);
