import React from 'react';
import * as classnames from 'classnames';
import OutsideClickHandler from 'react-outside-click-handler';
import { SpecTriangleIcon, WarningIcon } from '../Icons';
import './index.scss';

const InfoToolboxRed = ({ className, children, place, ...other }) => {
  const [show, toggle] = React.useState(false);

  return (
    <div className={classnames('info-toolbox-red', className)} {...other}>
      <WarningIcon className="info-toolbox-red__icon" onClick={() => toggle(!show)} />
      <div className={classnames('info-toolbox-red__content-wrap', show && 'visible', place)}>
        <OutsideClickHandler
          onOutsideClick={e => e.target.tagName !== 'rect' && toggle(false)}
          disabled={!show}
        >
          <div className="info-toolbox-red__content">{children}</div>
        </OutsideClickHandler>
        <SpecTriangleIcon className="info-toolbox-red__angle" />
      </div>
    </div>
  );
};

export default InfoToolboxRed;
