import * as React from 'react';
import classnames from 'classnames';
import ReactCodeInput from 'react-verification-code-input';
import s from './index.module.scss';

export const VerificationCodeInput = ({ onChange, onComplete, values, error, className }) => (
  <div className={s.wrapper}>
    <ReactCodeInput
      type="number"
      fields={6}
      onChange={onChange}
      onComplete={onComplete}
      loading={false}
      values={values}
      fieldWidth={44}
      autoFocus
      className={classnames(s.vcode, 'verification_code', error ? 'error' : 'success', className)}
    />
  </div>
);
