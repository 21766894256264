import axios from '../../axios-api';
import Pathes from '../../helpers/pathes';
import { getMessage } from '../../helpers/utils';
import Notify from '../../components/Notification';

export const uploadImage = fileData => {
  let file;
  if (fileData instanceof FormData) {
    file = fileData;
  } else {
    file = new FormData();
    file.append('file', fileData);
  }

  return axios
    .post(Pathes.File.photo, file)
    .then(response => {
      const { status, data } = response;
      const message = getMessage(data);
      if (status === 201) {
        return { data: response.data, success: true };
      }

      throw new Error(message);
    })
    .catch(() => ({ success: false }));
};

export const uploadFile = fileObj => {
  let file;
  if (fileObj instanceof FormData) {
    file = fileObj;
  } else {
    file = new FormData();
    file.append('file', fileObj);
  }

  return axios
    .post(Pathes.Common.file, file)
    .then(response => response && response.status === 201 && { data: response.data, success: true })
    .catch(() => Notify.error({ text: 'Could not upload file' }));
};
