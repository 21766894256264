import React from 'react';
import moment from 'moment';
import * as classnames from 'classnames';
import { WarningIcon } from '../UI/Icons';
import { DATE_FORMAT_DD_MMMM, DATE_FORMAT_DD_MMMM_YYYY } from '../../helpers/common';
import { prettyFloatMoney } from '../../helpers/utils';
import s from './index.module.scss';

export const PaymentBlock = ({ isMain, payment, cash, className }) => {
  if (!payment) {
    return null;
  }

  const { deadline, total_amount, fundraisings } = payment;

  const showWarning = isMain && (!cash || (cash && Number(total_amount) > Number(cash)));

  return (
    <div className={classnames(s.payment_block, isMain && s.payment_block__main, className)}>
      <div className={s.payment_block__right}>
        <p className="f-16">Due date</p>
        <p className={`${isMain ? 'f-42' : 'f-26'} f-500`}>
          {moment(deadline).format(isMain ? DATE_FORMAT_DD_MMMM_YYYY : DATE_FORMAT_DD_MMMM)}
        </p>
      </div>
      <div className={s.payment_block__left}>
        <div>
          <p className="f-16">Total amount</p>
          <div className={s.payment_block__amount}>
            <p className={`${isMain ? 'f-42' : 'f-26'} f-500 tl`}>
              {prettyFloatMoney(total_amount)}
            </p>
            {showWarning && <WarningIcon style={{ marginLeft: '10px' }} />}
          </div>
          {showWarning && (
            <p className={classnames(s.payment_block__error, 'f-16')}>
              Insufficient funds for a next repayment. Please add funds to your account.
            </p>
          )}
        </div>

        <div className={s.fundraisings}>
          {fundraisings.map(fundraising => (
            <div key={fundraising.fundraising_name} className={s.fundraising}>
              <div>
                <p className="f-16">Fundraising</p>
                <p className={`${isMain ? 'f-26' : 'f-18'} f-500 tl`}>
                  {fundraising.fundraising_name}
                </p>
              </div>

              <div className={s.sources}>
                {fundraising.sources.map(source => (
                  <div key={source.source}>
                    <p className="f-16">{source.source_text}</p>
                    <p className={`${isMain ? 'f-26' : 'f-18'} f-500 tl`}>
                      {prettyFloatMoney(source.amount)}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
