export const METADATA = {
  default: {
    loading: false,
    error: null,
  },
  request: {
    loading: true,
    error: null,
  },
  success: {
    loading: false,
    error: null,
  },
  error: {
    loading: false,
    error: 'Invalid response. Something went wrong',
  },
};
