import * as React from 'react';
import * as classnames from 'classnames';
import { Field } from 'formik';
import './index.scss';

class SelectField extends React.Component {
  render() {
    const { label, name, options = [], value, error, onChange, className } = this.props;
    return (
      <div className={classnames('select-field', error && 'select-field_error', className)}>
        <div className="select-field__inner">
          <Field
            name={name}
            id={name}
            as="select"
            onChange={onChange}
            className={classnames('select-field__select', value && 'select-field__select-filled')}
            value={value}
          >
            <option value="">Not selected</option>
            {options.map(item => (
              <option value={item.value} key={item.value} className="select-field__option">
                {item.label}
              </option>
            ))}
          </Field>
          <label htmlFor={name} className="select-field__label">
            {label}
          </label>
          <svg
            className="select-field__arrow-icon"
            width="16"
            height="9"
            viewBox="0 0 16 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.43111 8.75869C7.75111 9.08044 8.26667 9.08044 8.56889 8.75869L15.7333 1.55511C16.0889 1.19762 16.0889 0.625621 15.7333 0.268123C15.3778 -0.0893744 14.8089 -0.0893744 14.4533 0.268123L8.56889 6.18471C8.24889 6.50645 7.73333 6.50645 7.43111 6.18471L1.54667 0.285998C1.19111 -0.0714994 0.622222 -0.0714994 0.266667 0.285998C-0.0888889 0.643496 -0.0888889 1.21549 0.266667 1.57299L7.43111 8.75869Z"
              fill="#9E98A3"
            />
          </svg>
        </div>
        <div className={classnames('select-field__error', error && 'select-field__error_visible')}>
          {error}
        </div>
      </div>
    );
  }
}

export default SelectField;
