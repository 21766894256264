import React from 'react';
import qs from 'qs';
import { Link } from 'react-router-dom';
import parse, { domToReact } from 'html-react-parser';
import { MESSAGES } from './toaster';
import { CURRENCY, LOAN_GROUPS_SHORT } from './constants';
import config from '../config';

export const padNumber = (id, totalLength = 6) => {
  const zeros = new Array(totalLength).fill(0).join('');
  return !id
    ? zeros
    : `${id}`.length >= totalLength
    ? `'F' + ${id}`
    : `F${(zeros + id).slice(totalLength * -1)}`;
};

export const scrollTop = () => {
  const main = document.querySelector('main');
  main && main.scrollTo(0, 0);
  window.scrollTo(0, 0);
};

export const getMessage = (res, customMsg) => {
  return (res && res.message) || customMsg || MESSAGES.somethingwrong;
};

export const getFullname = (firstname, lastname, defaultName = '') => {
  if (firstname || lastname) {
    return `${firstname || ''} ${lastname || ''}`.trim();
  }
  return defaultName;
};

export const getNameInitials = (originFirstname, originLastname) => {
  const firstname = originFirstname || '';
  const lastname = originLastname || '';
  return [firstname, lastname]
    .map(name => name.charAt(0))
    .filter(initial => initial)
    .join('')
    .toUpperCase()
    .trim();
};

export const getLoansShortNames = groups => {
  if (!groups || (groups && groups.length === 0)) {
    return '-';
  }
  return groups
    .map(groupID => LOAN_GROUPS_SHORT[groupID])
    .sort()
    .join('+');
};

export const shortenDigit = (value, disableCurrency) => {
  const x = Number(value);
  if (x) {
    if (x < 1000) {
      return !disableCurrency ? `${CURRENCY}${String.fromCharCode(160)}${x}` : x;
    }
    if (x < 1000000) {
      return !disableCurrency
        ? `${CURRENCY}${String.fromCharCode(160)}${Number((x / 1000).toFixed(1))}k`
        : `${Number((x / 1000).toFixed(1))}k`;
    }
    if (x < 1000000000) {
      return !disableCurrency
        ? `${CURRENCY}${String.fromCharCode(160)}${Number((x / 1000000).toFixed(1))}m`
        : `${Number((x / 1000000).toFixed(1))}m`;
    }
    return !disableCurrency ? `${CURRENCY}${String.fromCharCode(160)}${x}` : x;
  }
  return !disableCurrency ? `${CURRENCY}${String.fromCharCode(160)}0` : 0;
};

export const prettyMoney = (value, disableCurrency) => {
  const x = parseInt(value);
  if (x) {
    const digit = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'");
    return `${!disableCurrency ? `${CURRENCY} ` : ''}${digit}`;
  }
  return !disableCurrency ? `${CURRENCY} 0` : 0;
};

export const prettyFloatMoney = (value, disableCurrency) => {
  try {
    if (!value || !Number(value)) {
      return !disableCurrency ? `${CURRENCY} 0` : 0;
    }
    const x = round(value, 2);
    const parts = x.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, "'");
    return `${!disableCurrency ? `${CURRENCY} ` : ''}${parts.join('.')}`;
  } catch (e) {
    return !disableCurrency ? `${CURRENCY} 0` : 0;
  }
};

export const getQuery = (params, exclude = []) => {
  let datasets = '';
  if (params) {
    const filteredParams = {
      ...params,
      search: params.search ? params.search : null,
    };
    const excludeList = [...exclude, 'hasMore'];
    excludeList.map(key => {
      delete filteredParams[key];
    });
    datasets = qs.stringify(
      { ...filteredParams },
      { strictNullHandling: true, arrayFormat: 'comma', skipNulls: true }
    );
    if (datasets) {
      return `?${datasets}`;
    }
  }
  return '';
};

export const dataURItoBlob = (dataURI, mime) => {
  // convert base64/URLEncoded data component to raw binary data held in a string
  let byteString;
  if (dataURI.split(',')[0].indexOf('base64') >= 0) byteString = atob(dataURI.split(',')[1]);
  else byteString = unescape(dataURI.split(',')[1]);

  // separate out the mime component
  const mimeString = mime || dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to a typed array
  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ia], { type: mimeString });
};

export const checkForValidFile = (file, allowedTypes, allowedMaxSize, allowedMinSize) => {
  const result = { isValid: true };

  if (file) {
    const { type, size } = file;
    if (allowedTypes && !allowedTypes.includes(type)) {
      result.isValid = false;
      result.type = 'File type is invalid';
    }

    if (allowedMaxSize && size >= allowedMaxSize) {
      result.isValid = false;
      result.size = 'File size is too big';
    }

    if (allowedMinSize && size <= allowedMinSize) {
      result.isValid = false;
      result.size = 'File size is too small';
    }
  }

  return result;
};

export const round = (num, decimalPlaces) => {
  try {
    num = Math.round(`${num}e${decimalPlaces}`);
    return Number(`${num}e${-decimalPlaces}`) || 0;
  } catch (e) {
    return 0;
  }
};

export const convertStringToDate = dateStr => {
  if (dateStr) {
    const result = dateStr.split('-');
    return {
      month: Number(result[1]),
      year: Number(result[0]),
      day: Number(result[2]),
    };
  }
  return null;
};

export const convertDateToString = dateObj => {
  if (dateObj) {
    return `${dateObj.year}-${dateObj.month}-${dateObj.day}`;
  }
  return null;
};

export const getLoanName = loanGroups => {
  if (!loanGroups || (loanGroups && loanGroups.length === 0)) {
    return '-';
  }
  if (loanGroups.length === 1) {
    return `Group ${LOAN_GROUPS_SHORT[loanGroups[0]]}`;
  }

  if (loanGroups.length === 2) {
    return `Groups ${LOAN_GROUPS_SHORT[loanGroups[0]]} and ${LOAN_GROUPS_SHORT[loanGroups[1]]}`;
  }

  if (loanGroups.length === 3) {
    return `Groups ${LOAN_GROUPS_SHORT[loanGroups[0]]}, ${LOAN_GROUPS_SHORT[loanGroups[1]]} and ${
      LOAN_GROUPS_SHORT[loanGroups[2]]
    }`;
  }
};

export const canGoBack = history => history && history.action !== 'POP';

export const getShortUserInfo = data =>
  data
    ? {
        id: data.id,
        first_name: data.first_name,
        last_name: data.last_name,
        email: data.email,
        photo: data.photo,
        status: data.status,
        role: data.role,
        role_id: data.role_id,
        unique_id: data.unique_id,
        is_new_user: data.is_new_user,
      }
    : null;

export const capitalizeFirstLetter = text =>
  text ? `${text.charAt(0).toUpperCase() + text.slice(1)}` : '';

export const isMobile = {
  Android: function () {
    return navigator.userAgent.match(/Android/i);
  },
  BlackBerry: function () {
    return navigator.userAgent.match(/BlackBerry/i);
  },
  iOS: function () {
    return navigator.userAgent.match(/iPhone|iPad|iPod/i);
  },
  Opera: function () {
    return navigator.userAgent.match(/Opera Mini/i);
  },
  Windows: function () {
    return navigator.userAgent.match(/IEMobile/i);
  },
  any: function () {
    return (
      isMobile.Android() ||
      isMobile.BlackBerry() ||
      isMobile.iOS() ||
      isMobile.Opera() ||
      isMobile.Windows()
    );
  },
};
export const withHTTP = url => (/^(https|http)/.test(url) ? url : `https://${url}`);

export const shortenUserInfo = (userData, prevUserData) => {
  if (!userData && !prevUserData) {
    return null;
  }

  const user = { ...prevUserData, ...userData };
  const { id, email, first_name, last_name, photo, status, role, role_id, unique_id, is_new_user } =
    user;

  return {
    id,
    email: email || '',
    first_name: first_name || '',
    last_name: last_name || '',
    photo,
    is_new_user,
    status,
    role,
    role_id,
    unique_id,
  };
};

export const extractHostname = url => {
  let hostname;
  //find & remove protocol (http, ftp, etc.) and get hostname

  if (url.indexOf('//') > -1) {
    hostname = url.split('/')[2];
  } else {
    hostname = url.split('/')[0];
  }

  //find & remove port number
  hostname = hostname.split(':')[0];
  //find & remove "?"
  hostname = hostname.split('?')[0];

  return hostname;
};

export const getFlagURL = countryCode =>
  countryCode ? `https://flagcdn.com/${countryCode.toLowerCase()}.svg` : '';

export const parseWithLinks = text => {
  if (!text) {
    return null;
  }

  const DOMAIN_URL_REGEX = new RegExp('^' + config.baseURL, 'g');
  const options = {
    replace: ({ name, attribs, children }) => {
      if (name === 'a' && attribs.href && DOMAIN_URL_REGEX.test(attribs.href)) {
        return <Link to={attribs.href.replace(config.baseURL, '')}>{domToReact(children)}</Link>;
      }
    },
  };

  return parse(text, options);
};
