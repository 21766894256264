import React from 'react';
import { connect } from 'react-redux';
import * as classnames from 'classnames';
import FR_Summary from './summary';
import FR_Result from './result';
import './index.scss';

export const FR_SETTINGS_THEME = {
  grey: 'grey',
  white: 'white',
  black: 'black',
};

class FundraisingInfo extends React.Component {
  getThemeColor = ({ step, is_active }) => {
    if ((step === 3 && is_active) || step === 4 || step === 5) {
      return FR_SETTINGS_THEME.black;
    }
    return '';
  };

  render() {
    const { status, onGoalEdit, disableSummary, className } = this.props;
    if (!status) {
      return null;
    }

    const currentTheme = this.getThemeColor(status);
    const isFinishedFR = status.step === 4;

    return (
      <div className={classnames('fundraising-info', className)}>
        {!disableSummary && <FR_Summary status={status} onEdit={onGoalEdit} theme={currentTheme} />}
        {isFinishedFR && <FR_Result status={status} theme={currentTheme} />}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.userStore.user,
  status: state.fundraisingStore.status.data,
  partnerCounts: state.fundraisingStore.partnerCounts,
});

export default connect(mapStateToProps)(FundraisingInfo);
