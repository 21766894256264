import axios from '../../axios-api';
import Pathes from '../../helpers/pathes';
import { getMessage, getQuery } from '../../helpers/utils';
import { UPDATE_USER } from './actionTypes';
import Notify from '../../components/Notification';
import { updateUser } from './userActions';
import {
  GET_BANK_STATEMENTS,
  GET_USER_PROFILE,
  SET_PROFILE_MISSING_DETAILS,
  SET_USER_PROFILE,
  UPDATE_COMPANY_PROFILE,
  UPDATE_CONTACT_PROFILE,
  UPDATE_PHOTO_PROFILE,
} from '../types/profileTypes';

const getUserProfileRequest = () => ({ type: GET_USER_PROFILE.REQUEST });
const getUserProfileSuccess = payload => ({
  type: GET_USER_PROFILE.SUCCESS,
  payload,
});
const getUserProfileFailure = error => ({
  type: GET_USER_PROFILE.FAILURE,
  error,
});

const getBankStatementsRequest = () => ({
  type: GET_BANK_STATEMENTS.REQUEST,
});
const getBankStatementsSuccess = payload => ({
  type: GET_BANK_STATEMENTS.SUCCESS,
  payload,
});
const getBankStatementsFailure = error => ({
  type: GET_BANK_STATEMENTS.FAILURE,
  error,
});

const setProfileMissingDetails = payload => ({
  type: SET_PROFILE_MISSING_DETAILS,
  payload,
});

export const getUserProfile = () => {
  return dispatch => {
    dispatch(getUserProfileRequest());
    return axios
      .get(Pathes.User.profile)
      .then(response => {
        const { status, data } = response;
        const message = getMessage(data);
        if (status === 200) {
          dispatch(updateUser(data.user));
          dispatch(getUserProfileSuccess(data));
          return data;
        }
        throw new Error(message);
      })
      .catch(e => dispatch(getUserProfileFailure(e.message)));
  };
};

export const setUserProfile = profile => {
  return dispatch => dispatch({ type: SET_USER_PROFILE, profile });
};

export const contactProfileUpdate = userData => {
  return (dispatch, getState) => {
    return axios
      .post(Pathes.User.contactInfo, userData)
      .then(response => {
        const { status, data } = response;
        if (status === 200) {
          const prevData = getState().profileStore.profile.data;
          if (prevData) {
            const updatedData = {
              ...prevData,
              user: {
                ...prevData.user,
                ...data,
                photo: prevData.user.photo,
              },
            };

            dispatch({ type: UPDATE_CONTACT_PROFILE, data: updatedData });
          }

          Notify.success({ text: 'Contact information updated successfully' });

          const userData = getState().userStore.user;
          if (userData) {
            const updatedUser = { ...userData, ...data };
            dispatch({ type: UPDATE_USER, user: updatedUser });
          }

          return { data, success: true };
        }

        throw new Error();
      })
      .catch(() => Notify.error({ text: 'Could not update profile' }));
  };
};

export const companyProfileUpdate = payload => {
  return dispatch => {
    return axios
      .put(Pathes.User.profile, payload)
      .then(response => {
        const { status, data } = response;
        if (status === 200) {
          dispatch({ type: UPDATE_COMPANY_PROFILE, payload: data });
          Notify.success({ text: 'Profile updated successfully' });
          return { data, success: true };
        }

        throw new Error();
      })
      .catch(() => Notify.error({ text: 'Could not update profile' }));
  };
};

export const photoProfileUpdate = payload => {
  return (dispatch, getState) => {
    return axios
      .post(Pathes.User.photo, payload)
      .then(response => {
        const { status, data } = response;
        if (status === 200) {
          const prevData = getState().profileStore.profile.data;
          if (prevData) {
            const updatedData = {
              ...prevData,
              user: {
                ...prevData.user,
                photo: data.photo,
              },
            };

            dispatch({ type: UPDATE_PHOTO_PROFILE, payload: updatedData });
          }
          Notify.success({ text: 'Profile photo updated successfully' });

          const userData = getState().userStore.user;
          if (userData) {
            const updatedUser = { ...userData, ...data };
            dispatch({ type: UPDATE_USER, user: updatedUser });
          }

          return { data, success: true };
        }

        throw new Error();
      })
      .catch(() => Notify.error({ text: 'Could not update profile photo' }));
  };
};

export const getMissingProfileDetails = () => {
  return (dispatch, getState) => {
    return axios
      .get(Pathes.User.profileMissingDetails)
      .then(response => {
        const { status, data } = response;
        const message = getMessage(data);
        if (status === 200) {
          const userStatus = getState().userStore.user && getState().userStore.user.status;
          data.status !== userStatus && dispatch(updateUser({ status: data.status }));
          dispatch(setProfileMissingDetails(data));
          return { data, success: true };
        }
        throw new Error(message);
      })
      .catch(() => {
        setProfileMissingDetails({});
        return { success: false };
      });
  };
};

export const getBankStatements = (params, isNext) => {
  return (dispatch, getState) => {
    dispatch(getBankStatementsRequest());
    return axios
      .get(Pathes.User.startupBankStatements + getQuery(params))
      .then(response => {
        const { status, data } = response;
        const message = getMessage(data);
        if (status === 200) {
          const prevData = getState().profileStore.bankStatementList.data;
          if (!isNext || !prevData) {
            dispatch(getBankStatementsSuccess(data));
            return { data, success: true, message };
          }

          const updatedData = {
            total_count: data.total_count,
            total_pages: data.total_pages,
            list: [...prevData.list, ...data.list],
          };

          dispatch(getBankStatementsSuccess(updatedData));
          return { data: updatedData, success: true, message };
        }
        throw new Error(message);
      })
      .catch(e => dispatch(getBankStatementsFailure(e.message)));
  };
};
