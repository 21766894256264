import React, { Component } from 'react';
import * as classnames from 'classnames';
import './index.scss';

class NotificationBlock extends Component {
  render() {
    const { notifications, className } = this.props;
    if (!notifications || (notifications && !notifications.length)) {
      return null;
    }

    return (
      <div className={classnames(className)}>
        <ul className="notification-block__list">
          {notifications.map((render, index) => render(index))}
        </ul>
      </div>
    );
  }
}

export default NotificationBlock;
