import * as React from 'react';
import * as classnames from 'classnames';
import './index.scss';

const Avatar = ({ src, alt, nameInitials, className, active, size = 50 }) => {
  const [showImg, setShow] = React.useState(true);
  const styles = {
    minWidth: size,
    minHeight: size,
    width: size,
    height: size,
  };

  return (
    <div
      className={classnames('avatar__wrap', active && 'avatar__active', className)}
      style={styles}
    >
      <div className="avatar">
        {src && showImg ? (
          <img
            className="avatar__image"
            src={src}
            alt={alt || 'avatar'}
            onError={() => setShow(false)}
          />
        ) : (
          <div className="avatar__initials">
            <span style={{ fontSize: `${(22 * size) / 60}px` }}>{nameInitials || 'S'}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default Avatar;
