import React, { Component } from 'react';
import classnames from 'classnames';
import qs from 'qs';
import { connect } from 'react-redux';
import FundraisingGoalForm from '../../components/Forms/FundraisingGoalForm';
import Preloader from '../../components/UI/Preloader';
import FundraisingStartModule from './FundraisingStartModule';
import FundraisingResultsModule from './FundraisingResultsModule';
import FundraisingSettings from './FundraisingSettings';
import FundraisingOnHold from './FundraisingOnHold';
import { getMissingProfileDetails, getUserProfile } from '../../store/actions/profileActions';
import { ROLES, USER_STATUSES } from '../../helpers/constants';
import { isVerifiedPhoneNumber } from '../../helpers/user';
import {
  getCoFoundersList,
  getFundraisingStatus,
  getNextFundraisingStatus,
  getPartnerCounts,
  resetFundraisingGoal,
  setFundraisingGoal,
} from '../../store/actions/fundraisingActions';
import './index.scss';

class FundraisingModule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentStep: 1,
    };
  }

  componentDidMount() {
    this.props.getUserProfile();
    this.props.getPartnerCounts();
    this.props.getMissingProfileDetails();
    this.props.getFundraisingStatus().then(res => {
      if (res && res.success) {
        let step = res.data.step;
        step === 1 && this.props.getCoFoundersList();
        const redirectStep = Number(qs.parse(this.props.location.search.replace('?', '')).to);
        if (
          (redirectStep === 1 || redirectStep === 2) &&
          !res.data.is_active &&
          res.data.step < 4
        ) {
          step = redirectStep;
        }
        redirectStep && this.props.history.replace('/fundraising');
        this.setState(prevState => ({
          ...prevState,
          currentStep: step,
        }));
      }
    });
  }

  setGoal = values => {
    const { goal, has_accepted_terms } = values;
    const payload = {
      goal,
      has_accepted_terms,
    };
    this.props.setFundraisingGoal(payload).then(res => {
      res &&
        res.success &&
        this.setState(prevState => ({
          ...prevState,
          currentStep: res.data.step,
        }));
    });
  };

  resetFundraising = () => {
    this.props.resetFundraisingGoal().then(res => {
      res &&
        res.success &&
        this.setState(prevState => ({
          ...prevState,
          currentStep: res.data.step,
        }));
    });
  };

  setNewFundraising = () => {
    this.props.getNextFundraisingStatus().then(res => {
      res &&
        res.success &&
        this.setState(prevState => ({
          ...prevState,
          currentStep: res.data.step,
        }));
    });
  };

  setStep = step => this.setState(prevState => ({ ...prevState, currentStep: step }));

  render() {
    const { currentStep } = this.state;
    const { status, missingInfo, coFounders, user, profile } = this.props;
    const disabled =
      user.status === USER_STATUSES.blocked ||
      user.status === USER_STATUSES.new ||
      user.role !== ROLES.founder ||
      !status.data?.max_goal ||
      !isVerifiedPhoneNumber(profile?.data?.user);

    return (
      <div className="fundraising-module__wrap">
        <div className="container-inner">
          {status.loading ? (
            <Preloader className="fundraising-module__preloader" />
          ) : (
            status.data && (
              <div className={classnames('fundraising-module__content', user.role)}>
                {currentStep === 1 && user.role === ROLES.founder && (
                  <FundraisingGoalForm
                    status={status.data}
                    onSubmit={this.setGoal}
                    onReset={this.resetFundraising}
                    missingInfo={missingInfo}
                    disabled={disabled}
                    user={user}
                    coFounders={coFounders.data}
                  />
                )}
                {(currentStep === 2 || (user.role === ROLES.co_founder && currentStep < 2)) &&
                  (status.data.is_active ? (
                    <FundraisingStartModule />
                  ) : (
                    <FundraisingSettings
                      onGoalEdit={() => this.setStep(1)}
                      onReset={this.resetFundraising}
                      currentStep={currentStep}
                    />
                  ))}
                {currentStep === 3 && <FundraisingOnHold />}
                {currentStep === 4 && (
                  <FundraisingResultsModule setNewFundraising={this.setNewFundraising} />
                )}
              </div>
            )
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.userStore.user,
  profile: state.profileStore.profile,
  status: state.fundraisingStore.status,
  missingInfo: state.profileStore.profileMissingDetails,
  coFounders: state.fundraisingStore.coFounders,
});

const mapDispatchToProps = dispatch => ({
  getFundraisingStatus: () => dispatch(getFundraisingStatus()),
  setFundraisingGoal: payload => dispatch(setFundraisingGoal(payload)),
  resetFundraisingGoal: () => dispatch(resetFundraisingGoal()),
  getPartnerCounts: () => dispatch(getPartnerCounts()),
  getNextFundraisingStatus: () => dispatch(getNextFundraisingStatus()),
  getMissingProfileDetails: () => dispatch(getMissingProfileDetails()),
  getCoFoundersList: () => dispatch(getCoFoundersList()),
  getUserProfile: () => dispatch(getUserProfile()),
});

export default connect(mapStateToProps, mapDispatchToProps)(FundraisingModule);
