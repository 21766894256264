import React from 'react';
import { useParams } from 'react-router-dom';
import LoanTermsVersion01 from './versions/v1';
import LoanTermsVersion02 from './versions/v2';
import LoanTermsVersion03 from './versions/v3';
import LoanTermsVersion04 from './versions/v4';

const LoanTermsPage = () => {
  const { version } = useParams();

  switch (version) {
    case 'v1':
      return <LoanTermsVersion01 />;
    case 'v2':
      return <LoanTermsVersion02 />;
    case 'v3-standard':
      return <LoanTermsVersion03 plan="standard" />;
    case 'v3-advanced':
      return <LoanTermsVersion03 plan="advanced" />;
    case 'v4-standard':
      return <LoanTermsVersion04 plan="standard" />;
    case 'v4-advanced':
      return <LoanTermsVersion04 plan="advanced" />;
    default:
      return <LoanTermsVersion04 plan="standard" />;
  }
};

export default LoanTermsPage;
