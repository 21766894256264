import React from 'react';
import classnames from 'classnames';
import { XIcon } from '../UI/Icons';
import s from './index.module.scss';

export const FileName = ({ file, className, onRemove }) => (
  <div className={classnames(s.uploaded, className)}>
    {file.name}
    {onRemove && (
      <button type="button" onClick={onRemove} className={s.remove}>
        <XIcon />
      </button>
    )}
  </div>
);
