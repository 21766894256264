import React from 'react';
import classnames from 'classnames';
import { prettyFloatMoney } from '../../helpers/utils';
import './index.scss';

export const CashDetailBox = ({ balance, className }) => {
  if (!balance) {
    return null;
  }

  return (
    <div className={classnames('cash-detail-box', className)}>
      <div className="cash-detail-box__item">
        <div className="cash-detail-box__item-title">Available cash</div>
        <div className="f-500 cash-detail-box__item-value">
          {prettyFloatMoney(balance && balance.cash)}
        </div>
      </div>
      {!!balance?.frozen_amount && (
        <div className="cash-detail-box__item">
          <div className="cash-detail-box__item-title">Frozen amount</div>
          <div className="f-500 cash-detail-box__item-value">
            {prettyFloatMoney(balance?.frozen_amount)}
          </div>
        </div>
      )}
    </div>
  );
};
