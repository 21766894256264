import { METADATA } from '../../helpers/metadata';
import {
  SET_PARTICIPATING_STARTUPS,
  SET_INACTIVE_STARTUPS,
  GET_STARTUP_DETAIL,
} from '../types/startupTypes';

const initialState = {
  participatingStartups: null,
  inactiveStartups: null,
  startupDetail: { ...METADATA.default, data: null },
};

const startupReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PARTICIPATING_STARTUPS:
      return { ...state, participatingStartups: action.payload };
    case SET_INACTIVE_STARTUPS:
      return { ...state, inactiveStartups: action.payload };
    case GET_STARTUP_DETAIL.REQUEST:
      return {
        ...state,
        startupDetail: { ...state.startupDetail, ...METADATA.request },
      };
    case GET_STARTUP_DETAIL.SUCCESS:
      return {
        ...state,
        startupDetail: { ...METADATA.success, data: action.payload },
      };
    case GET_STARTUP_DETAIL.FAILURE:
      return {
        ...state,
        startupDetail: {
          ...METADATA.error,
          data: null,
          error: action.error,
        },
      };
    default:
      return state;
  }
};

export default startupReducer;
