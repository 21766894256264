import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import qs from 'qs';
import { contactInfoUpdate, getUserLocation, registerUser } from '../../store/actions/userActions';
import { uploadFile, uploadPhoto } from '../../store/actions/commonActions';
import { getUserProfile } from '../../store/actions/profileActions';
import RegisterForm from '../../components/Forms/RegisterForm';
import VerifyForm from '../../components/VerifyForm';
import { cropAvatar } from '../../components/AvatarEdit';
import ContactInfoForm from '../../components/Forms/ContactInfoForm';
import Preloader from '../../components/UI/Preloader';
import PhoneVerificationForm from '../../components/Forms/PhoneVerificationForm';
import { ROLES } from '../../helpers/constants';
import {
  sendVerificationCode,
  validateCoFounderInviteCode,
  validatePhoneNumber,
} from '../../store/services/userServices';
import './index.scss';

const STEPS = {
  main: 'main',
  contact: 'contact',
  phone_verification: 'phone_verification',
  veriff: 'veriff',
};

const SignUpCoFounder = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const { step: queryStep } = qs.parse(location.search.replace('?', ''));
  const { user, userLocation } = useSelector(state => state.userStore);
  const profile = useSelector(state => state.profileStore.profile.data);

  const company = useRef(null);
  const inviteCode = useRef(params.inviteCode);

  const [step, setStep] = useState(
    user && Object.keys(STEPS).includes(queryStep)
      ? queryStep
      : profile
      ? STEPS.contact
      : STEPS.main
  );

  const [phone, setPhone] = useState('');
  const [isValidating, setIsValidating] = useState(true);
  const [error, setError] = useState('');

  const validateCode = async () => {
    try {
      const res = await validateCoFounderInviteCode(inviteCode.current);
      if (res && res.success) {
        dispatch(getUserLocation());
      }
      if (res && res.message) {
        setError(res.message);
      }
    } catch (e) {
      // Do nothing
    } finally {
      setIsValidating(false);
    }
  };

  useEffect(() => {
    validateCode();
    user && dispatch(getUserProfile());
  }, []);

  useEffect(() => {
    if (profile && step === STEPS.main) {
      setStep(STEPS.contact);
    }
  }, [profile]);

  const getStepNumber = step => {
    const index = Object.keys(STEPS).findIndex(key => key === step);
    return index >= 0 ? index + 1 : 1;
  };

  const onRegister = async (data, { setSubmitting }) => {
    const payload = {
      email: data.email,
      password: data.password,
      invite_code: inviteCode.current || null,
    };
    const res = await dispatch(registerUser(payload));
    setSubmitting(false);
    if (res && res.success) {
      company.current = res.data.user.company;
      setStep(STEPS.contact);
    }
  };

  const onContactInfoSubmit = async (data, formikProps) => {
    const { setSubmitting, setErrors } = formikProps;

    try {
      const payload = {
        phone: data.phone,
        first_name: data.first_name,
        last_name: data.last_name,
        citizenship: data.citizenship,
        citizenship_code: data.citizenship_code,
        company_name: data.company_name,
        company_description: data.description,
        description: data.about,
        cv: null,
        photo: null,
      };

      if (data.photo) {
        if (!data.photo.id && data.editorRef) {
          const croppedAvatar = cropAvatar(data.editorRef);
          const res = await dispatch(uploadPhoto(croppedAvatar));
          res && res.id && (payload.photo = res.id);
        }

        if (data.photo.id) {
          payload.photo = data.photo.id;
        }
      }

      if (data.resume) {
        if (!data.resume.id) {
          const res = await dispatch(uploadFile(data.resume));
          res && res.id && (payload.cv = res.id);
        }

        if (data.resume.id) {
          payload.cv = data.resume.id;
        }
      }

      const res = await dispatch(contactInfoUpdate(payload));
      if (res && res.success) {
        setPhone(data.phone);
        await sendVerificationCode(data.phone);
        setStep(STEPS.phone_verification);
      }

      if (res && !res.success && res.errors) {
        setErrors({ ...errors, ...res.errors });
        window.scrollTo(0, 0);
      }
    } catch (e) {
      // Do nothing
    } finally {
      setSubmitting(false);
    }
  };

  const profileUser = profile && profile.user;

  const userData = {
    ...user,
    ...profileUser,
    cv: profile ? profile.cv : null,
    description: profile ? profile.description : '',
    company: company.current || profile ? (profile ? profile.company : company.current) : null,
  };

  const onPhoneVerification = async (values, { setErrors }) => {
    if (values.code.length < 6) {
      return setErrors({ code: 'Verification code is required' });
    }

    const res = await validatePhoneNumber({
      phone_number: phone,
      code: values.code.join(''),
    });

    if (res && res.success) {
      setStep(STEPS.veriff);
    } else {
      setErrors({ code: res.message });
    }
  };

  return (
    <div className="sign-up-co-founder">
      <div className="sign-up-co-founder__inner">
        {isValidating ? (
          <Preloader className="sign-up-co-founder__preloader" />
        ) : error ? (
          <div className="sign-up-co-founder__error">
            <h1 className="sign-up-co-founder__error-title f-34 f-500">{error}</h1>
            <div className="sign-up-co-founder__error-desc f-16 f-400">
              Please check the link and try again
            </div>
          </div>
        ) : (
          <>
            <div className="sign-up-co-founder__step-number">
              Step {getStepNumber(step)} of {Object.keys(STEPS).length}
            </div>

            {step === STEPS.main && (
              <RegisterForm
                onSubmit={onRegister}
                nextStep={() => setStep(STEPS.contact)}
                role={ROLES.co_founder}
              />
            )}

            {step === STEPS.contact && (
              <ContactInfoForm
                onSubmit={onContactInfoSubmit}
                userLocation={userLocation}
                user={userData}
                role={ROLES.co_founder}
              />
            )}

            {step === STEPS.phone_verification && (
              <PhoneVerificationForm
                phone={phone}
                onSubmit={onPhoneVerification}
                onLater={() => setStep(STEPS.veriff)}
              />
            )}

            {step === STEPS.veriff && <VerifyForm />}
          </>
        )}
      </div>
    </div>
  );
};

export default SignUpCoFounder;
