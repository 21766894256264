import React from 'react';
import { prettyFloatMoney } from '../../../../helpers/utils';

export const SecurityDeposit = ({ insurance }) => {
  if (!insurance) return null;

  return (
    <div className="loan-info-insurance">
      <h2 className="f-26 f-500">Security deposit</h2>
      {insurance ? (
        <div className="loan-info-insurance__table">
          <div className="loan-info-insurance__thead">
            <div className="loan-info-insurance__thead-col">Total amount</div>
            <div className="loan-info-insurance__thead-col">Outstanding</div>
            <div className="loan-info-insurance__thead-col">Repaid back</div>
          </div>

          <div className="loan-info-insurance__tbody">
            <div className="loan-info-insurance__tbody-row">
              <div className="loan-info-insurance__tbody-col">
                {prettyFloatMoney(insurance.total_amount)}
              </div>
              <div className="loan-info-insurance__tbody-col">
                {prettyFloatMoney(insurance.outstanding)}
              </div>
              <div className="loan-info-insurance__tbody-col">
                {prettyFloatMoney(insurance.repaid_back)}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="loan-info__empty-message f-16">No mutual insurance</div>
      )}
    </div>
  );
};
