import React from 'react';
import classnames from 'classnames';
import { padNumber } from '../../helpers/utils';
import CopyButton from '../UI/CopyButton';
import { CONTACT_EMAIL } from '../../helpers/constants';
import { ReactComponent as IconWarning } from '../../assets/icons/icon_warning_black_16.svg';
import './index.scss';

const BANK_REQUISITES = {
  iban: 'EE947700771006351972',
  visibleIban: 'EE94 7700 7710 0635 1972',
  bankSwiftCode: 'LHVBEE22',
  beneficiaryName: 'Scramble OU',
  beneficiaryBankName: 'AS LHV Pank',
  bankAddress: 'Tartu mnt 2, 10145 Tallinn',
};

export const BankRequisite = ({ userID, className }) => (
  <div className={classnames('bank_requisite__bank-details', className)}>
    <h3 className="f-20 f-500">Bank details</h3>
    <ul className="bank_requisite__bank-details-list">
      <li className="bank_requisite__bank-details-list-item">
        <div className="bank_requisite__bank-details-list-title">Beneficiary Name</div>
        <div className="bank_requisite__bank-details-list-value">
          {BANK_REQUISITES.beneficiaryName}
        </div>
        <CopyButton text={BANK_REQUISITES.beneficiaryName} />
      </li>

      <li className="bank_requisite__bank-details-list-item">
        <div className="bank_requisite__bank-details-list-title">
          Beneficiary Bank Account Number / IBAN
        </div>
        <div className="bank_requisite__bank-details-list-value">{BANK_REQUISITES.visibleIban}</div>
        <CopyButton text={BANK_REQUISITES.iban} />
      </li>

      <li className="bank_requisite__bank-details-list-item">
        <div className="bank_requisite__bank-details-list-title">Payment Details</div>
        <div className="bank_requisite__bank-details-list-value">{padNumber(userID)}</div>
        <div className="bank_requisite__bank-details-list-note f-12 f-400">
          <IconWarning /> Include this so we can find your payment
        </div>
        <CopyButton text={padNumber(userID)} />
        <span className="bank_requisite__bank-details-list-required hidable f-14 f-400" id="hide">
          required
        </span>
      </li>

      <li className="bank_requisite__bank-details-list-item">
        <div className="bank_requisite__bank-details-list-title">
          Beneficiary Bank SWIFT/BIC Code
        </div>
        <div className="bank_requisite__bank-details-list-value">
          {BANK_REQUISITES.bankSwiftCode}
        </div>
        <CopyButton text={BANK_REQUISITES.bankSwiftCode} />
      </li>

      <li className="bank_requisite__bank-details-list-item">
        <div className="bank_requisite__bank-details-list-title">Beneficiary Bank Name</div>
        <div className="bank_requisite__bank-details-list-value">
          {BANK_REQUISITES.beneficiaryBankName}
        </div>
        <CopyButton text={BANK_REQUISITES.beneficiaryBankName} />
      </li>

      <li className="bank_requisite__bank-details-list-item">
        <div className="bank_requisite__bank-details-list-title">Beneficiary Bank Address</div>
        <div className="bank_requisite__bank-details-list-value">{BANK_REQUISITES.bankAddress}</div>
        <CopyButton text={BANK_REQUISITES.bankAddress} />
      </li>

      <li className="bank_requisite__bank-details-list-item">
        <div className="bank_requisite__bank-details-list-title">Company Email</div>
        <div className="bank_requisite__bank-details-list-value">{CONTACT_EMAIL}</div>
        <CopyButton text={CONTACT_EMAIL} />
      </li>
    </ul>
  </div>
);
