const ENVIRONMENT = process.env.REACT_APP_ENV || 'production';
const DEV_API_URL = process.env.REACT_APP_DEV_API_URL || 'http://localhost:8000';
const API_URL = process.env.REACT_APP_API_URL || '';
const INVESTOR_PROMO_DOMAIN = process.env.REACT_APP_INVESTOR_PROMO_DOMAIN || '';
const FOUNDER_PROMO_DOMAIN = process.env.REACT_APP_FOUNDER_PROMO_DOMAIN || '';
const MAIN_PROMO_DOMAIN = process.env.REACT_APP_MAIN_PROMO_DOMAIN || '';
const FACEBOOK_LINK = process.env.REACT_APP_FACEBOOK_LINK || '#';
const LINKEDIN_LINK = process.env.REACT_APP_LINKEDIN_LINK || '#';
const INSTAGRAM_LINK = process.env.REACT_APP_INSTAGRAM_LINK || '#';
const GOOGLE_RECAPTCHA_SITE_KEY_V2 = process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY_V2 || '';
const VERIFF_API_KEY = process.env.REACT_APP_VERIFF_PUBLISHABLE_KEY || '';
const VERIFF_HOST_URL = 'https://stationapi.veriff.com';
const PREFIX = '/api/';

const getApiURL = () => (ENVIRONMENT === 'development' ? DEV_API_URL : API_URL);

const config = {
  mainPromoDomain: MAIN_PROMO_DOMAIN,
  investorPromoDomain: INVESTOR_PROMO_DOMAIN,
  founderPromoDomain: FOUNDER_PROMO_DOMAIN,
  facebookLink: FACEBOOK_LINK,
  linkedinLink: LINKEDIN_LINK,
  instagramLink: INSTAGRAM_LINK,
  baseURL: window.location.origin.toString(),
  apiURL: getApiURL() + PREFIX,
  veriffApiKey: VERIFF_API_KEY,
  veriffHostURL: VERIFF_HOST_URL,
  environment: ENVIRONMENT,
  googleReCaptchaSiteKeyV2: GOOGLE_RECAPTCHA_SITE_KEY_V2,
};

export default config;
