import React from 'react';
import { components } from 'react-select';
import classnames from 'classnames';
import s from './index.module.scss';

export const Control = props => {
  const { isFocused, selectProps, hasValue } = props;
  return (
    <components.Control {...props}>
      {props.children}

      {selectProps['aria-label'] && !selectProps.placeholder.trim() && (
        <label
          htmlFor={selectProps.name}
          className={classnames(s.label, (isFocused || hasValue) && s.label__focused)}
        >
          {selectProps['aria-label']}
        </label>
      )}
    </components.Control>
  );
};
