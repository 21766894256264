import React, { useEffect, useRef } from 'react';
import * as classnames from 'classnames';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import OutsideClickHandler from 'react-outside-click-handler';
import { createPortal } from 'react-dom';
import { setModals } from '../../store/actions/commonActions';
import { CloseIcon } from '../UI/Icons';
import './index.scss';
import CoFounderInvitation from '../../modals/CoFounderInvitation';
import ProfileAvatarModal from '../../modals/ProfileAvatarModal';
import ProfileAboutModal from '../../modals/ProfileAboutModal';
import ProfileContactsModal from '../../modals/ProfileContactsModal';
import ProfileCareerModal from '../../modals/ProfileCareerModal';
import ProfileEducationModal from '../../modals/ProfileEducationModal';
import ProfileStartupModal from '../../modals/ProfileStartupModal';
import ChangePasswordModal from '../../modals/ChangePasswordModal';
import LoanInfoModal from '../../modals/LoanInfoModal';
import PhoneVerificationModal from '../../modals/PhoneVerificationModal';
import ConfirmModal from '../../modals/ConfirmModal';
import TermsOfUseModal from '../../modals/TermsOfUseModal';

export const MODAL_TYPES = {
  co_founder_invitation: 'co_founder_invitation',
  profile_avatar: 'profile_avatar',
  profile_about: 'profile_about',
  profile_contacts: 'profile_contacts',
  profile_career: 'profile_career',
  profile_education: 'profile_education',
  profile_company: 'profile_company',
  profile_bank_statement: 'profile_bank_statement',
  change_password: 'change_password',
  loan_info: 'loan_info',
  phone_verification: 'phone_verification',
  confirm_modal: 'confirm_modal',
  terms_of_use: 'terms_of_use',
};

export const ModalProvider = () => {
  const elementID = 'modals';
  const modals = useSelector(state => state.commonStore.modals);
  const location = useLocation();
  const dispatch = useDispatch();
  const root = useRef(document.getElementById(elementID));

  const onClose = type => {
    type
      ? dispatch(setModals(modals.filter(view => view.type !== type)))
      : !!modals.length && dispatch(setModals([]));
  };

  useEffect(() => {
    if (modals && !!modals.length) {
      document.body.style.overflow = 'hidden';
      root.current.classList.add('active');
    } else {
      document.body.style.overflow = 'unset';
      root.current.classList.remove('active');
    }
  }, [modals]);

  useEffect(() => {
    return () => (document.body.style.overflow = 'unset');
  }, []);

  useEffect(() => {
    onClose();
  }, [location]);

  const renderModal = modal => {
    switch (modal.type) {
      case MODAL_TYPES.co_founder_invitation:
        return <CoFounderInvitation onClose={() => onClose(modal.type)} {...modal} />;
      case MODAL_TYPES.profile_avatar:
        return <ProfileAvatarModal onClose={() => onClose(modal.type)} {...modal} />;
      case MODAL_TYPES.profile_about:
        return <ProfileAboutModal onClose={() => onClose(modal.type)} {...modal} />;
      case MODAL_TYPES.profile_contacts:
        return <ProfileContactsModal onClose={() => onClose(modal.type)} {...modal} />;
      case MODAL_TYPES.profile_career:
        return <ProfileCareerModal onClose={() => onClose(modal.type)} {...modal} />;
      case MODAL_TYPES.profile_education:
        return <ProfileEducationModal onClose={() => onClose(modal.type)} {...modal} />;
      case MODAL_TYPES.profile_company:
        return <ProfileStartupModal onClose={() => onClose(modal.type)} {...modal} />;
      case MODAL_TYPES.change_password:
        return <ChangePasswordModal onClose={() => onClose(modal.type)} {...modal} />;
      case MODAL_TYPES.loan_info:
        return <LoanInfoModal onClose={() => onClose(modal.type)} {...modal} />;
      case MODAL_TYPES.phone_verification:
        return <PhoneVerificationModal onClose={() => onClose(modal.type)} {...modal} />;
      case MODAL_TYPES.confirm_modal:
        return <ConfirmModal onClose={() => onClose(modal.type)} {...modal} />;
      case MODAL_TYPES.terms_of_use:
        return <TermsOfUseModal onClose={() => onClose(modal.type)} {...modal} />;

      default:
        return null;
    }
  };

  const content =
    modals &&
    !!modals.length &&
    modals.map((modal, index) => (
      <div
        key={modal.type}
        id={`modals_wrap_${modal.type}`}
        className={classnames('modals-wrap', modal.type)}
      >
        <div className="modals-wrap__inner" onClick={e => e.stopPropagation()}>
          <OutsideClickHandler
            onOutsideClick={() => onClose(modal.type)}
            disabled={!!modal.onOutsideClick || modals.length - 1 !== index}
          >
            <div className="modals-wrap__content">
              <button
                type="button"
                onClick={() => onClose(modal.type)}
                className="modals-wrap__close"
              >
                <CloseIcon />
              </button>
              {renderModal(modal)}
            </div>
          </OutsideClickHandler>
        </div>
      </div>
    ));

  return root.current && createPortal(content, root.current);
};
