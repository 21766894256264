import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as IconTrash } from '../../../assets/icons/icon_trash_red_22.svg';
import { ReactComponent as IconEdit } from '../../../assets/icons/icon_edit_22.svg';
import { removeBankDetail } from '../../../store/services/paymentServices';
import { getBankDetailsList } from '../../../store/actions/paymentActions';
import { RoundLink } from '../../../components/UI/RoundLink';
import { APP_LINKS } from '../../../helpers/links';
import { setModals } from '../../../store/actions/commonActions';
import { MODAL_TYPES } from '../../../components/ModalProvider';
import { Tooltip } from '../../../components/Tooltip';
import s from './index.module.scss';

export const BankDetailsTable = () => {
  const dispatch = useDispatch();
  const { bankDetailsList } = useSelector(state => state.paymentStore);
  const isEmpty = !bankDetailsList?.length;

  useEffect(() => {
    dispatch(getBankDetailsList());
  }, []);

  const onRemove = async ({ id }) => {
    dispatch(
      setModals({
        type: MODAL_TYPES.confirm_modal,
        onDelete: async () => {
          await removeBankDetail(id);
          dispatch(getBankDetailsList());
          dispatch(setModals([]));
        },
        title: 'Are you sure delete your bank account?',
        desc: 'The delete bank account button requires confirmation before erasing your banking information. It asks directly if you are sure, to prevent accidental deletion. Take a moment to decide before clicking.',
      })
    );
  };

  return (
    <>
      <div className={s.wrapper}>
        <h1 className="f-26 f-500">Current bank accounts</h1>

        {isEmpty ? (
          <div className={classnames(s.empty, 'f-20')}>
            You don’t have any bank accounts. Please add one.
          </div>
        ) : (
          <div className={s.table__wrapper}>
            <div className={s.table__inner}>
              <div className={s.table}>
                <div className={s.table__thead}>
                  <div className={s.table__thead_col}>Bank account</div>
                  <div className={s.table__thead_col}>Status</div>
                  <div className={s.table__thead_col}>Actions</div>
                </div>

                <div className={s.table__tbody}>
                  {bankDetailsList.map((item, index) => (
                    <div className={s.table__tbody_row} key={item.id || index}>
                      <div className={s.table__tbody_col}>{item.account_number}</div>
                      <div className={s.table__tbody_col}>
                        <div className={s.status}>
                          <span
                            className={item.review_status}
                            style={{ textTransform: 'capitalize' }}
                          >
                            {item.review_status}
                          </span>
                          {item.review_status === 'rejected' && (
                            <Tooltip
                              renderContent={() => (
                                <div className={classnames(s.status_tooltip, 'f-16 f-400')}>
                                  {item.rejected_reason}
                                </div>
                              )}
                            />
                          )}
                        </div>
                      </div>
                      <div className={s.table__tbody_col}>
                        <div className={s.controls}>
                          <Link
                            to={APP_LINKS.bankAccountsEdit(item.id)}
                            className={classnames(
                              s.button,
                              s.button_edit,
                              !['rejected', 'pending'].includes(item.review_status) &&
                                s.button_hidden
                            )}
                            disabled={!['rejected', 'pending'].includes(item.review_status)}
                          >
                            <IconEdit /> <span>Edit</span>
                          </Link>
                          <button
                            type="button"
                            onClick={() => onRemove(item)}
                            className={classnames(s.button, s.button_remove)}
                          >
                            <IconTrash /> <span>Delete</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <RoundLink
        type="button"
        path={APP_LINKS.bankAccountsCreate}
        className={s.button_add}
        label="Add a new bank account"
      />
    </>
  );
};
