import axios from '../../axios-api';
import Pathes from '../../helpers/pathes';
import { getMessage } from '../../helpers/utils';
import {
  GET_BALANCE,
  GET_DASH_BALANCE_INFO,
  GET_DASH_DEPOSIT_INFO,
  GET_DASH_PAYMENT_INFO,
  GET_FR_DATES,
} from '../types/dashboardTypes';

export const getFundraisingDates = () => {
  return dispatch => {
    return axios
      .get(Pathes.Dashboard.fundraisingDates)
      .then(response => {
        const { status, data } = response;
        const message = getMessage(data);
        if (status === 200) {
          dispatch({ type: GET_FR_DATES, payload: data });
          return { data, success: true };
        }
        throw new Error(message);
      })
      .catch(e => ({ error: e.message }));
  };
};

export const getBalance = () => {
  return dispatch => {
    return axios
      .get(Pathes.Dashboard.balance)
      .then(response => {
        const { status, data } = response;
        const message = getMessage(data);
        if (status === 200) {
          dispatch({ type: GET_BALANCE, balance: data });
          return data;
        }
        throw new Error(message);
      })
      .catch(e => ({ error: e.message }));
  };
};

export const getDashBalanceInfo = () => {
  return dispatch => {
    return axios
      .get(Pathes.Dashboard.balanceInfo)
      .then(response => {
        const { status, data } = response;
        const message = getMessage(data);
        if (status === 200) {
          dispatch({ type: GET_DASH_BALANCE_INFO, payload: data });
          return data;
        }

        throw new Error(message);
      })
      .catch(e => ({ error: e.message }));
  };
};

export const getDashPaymentInfo = () => {
  return dispatch => {
    return axios
      .get(Pathes.Dashboard.paymentInfo)
      .then(response => {
        const { status, data } = response;
        const message = getMessage(data);
        if (status === 200) {
          dispatch({ type: GET_DASH_PAYMENT_INFO, payload: data });
          return data;
        }

        throw new Error(message);
      })
      .catch(e => ({ error: e.message }));
  };
};

export const getDashDepositInfo = () => {
  return dispatch => {
    return axios
      .get(Pathes.Dashboard.depositInfo)
      .then(response => {
        const { status, data } = response;
        const message = getMessage(data);
        if (status === 200) {
          dispatch({ type: GET_DASH_DEPOSIT_INFO, payload: data });
          return data;
        }

        throw new Error(message);
      })
      .catch(e => ({ error: e.message }));
  };
};
