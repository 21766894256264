import momentTZ from 'moment-timezone';
import moment from 'moment';
import { TIMEZONE_ESTONIA } from './constants';
import { DATE_FORMAT_DD_MMMM_YYYY } from './common';

export const canChangeUntil = date => {
  if (!date) {
    return '';
  }
  const timezone = moment.tz.guess();
  const roundTime = momentTZ.tz(date, TIMEZONE_ESTONIA).add(12, 'hours').subtract(1, 'minute');
  return `${momentTZ.tz(roundTime, timezone).format('DD MMM, HH:mm')} GMT`;
};

export const showDate = (date, format = DATE_FORMAT_DD_MMMM_YYYY) => moment(date).format(format);

export const roundStarts = (date, format = 'DD MMM, HH:mm') => {
  if (!date) {
    return '';
  }
  const timezone = moment.tz.guess();
  const roundTime = momentTZ.tz(date, TIMEZONE_ESTONIA).add(12, 'hours');
  return `${momentTZ.tz(roundTime, timezone).format(format)} GMT`;
};
