import { SET_INNER_WIDTH, SET_MODALS } from '../actions/actionTypes';

const initialState = {
  innerWidth: window.innerWidth,
  modals: [],
};

const commonReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_INNER_WIDTH:
      return { ...state, innerWidth: action.width };
    case SET_MODALS:
      return { ...state, modals: action.modals };
    default:
      return state;
  }
};

export default commonReducer;
