import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { PasswordField } from '../../UI/PasswordField';
import { RoundButton } from '../../UI/RoundButton';
import { strongPasswordRegex } from '../../../helpers/common';
import './index.scss';

const VALIDATION_SCHEMA = Yup.object().shape({
  oldPassword: Yup.string()
    .matches(strongPasswordRegex, "Password doesn't meet requirements")
    .required('Password is required'),
  password1: Yup.string()
    .matches(strongPasswordRegex, "Password doesn't meet requirements")
    .required('Password is required'),
  password2: Yup.string().required('Confirm password'),
});

export const ChangePassForm = ({ onSubmit, onCancel }) => (
  <Formik
    onSubmit={onSubmit}
    validationSchema={VALIDATION_SCHEMA}
    initialValues={{
      oldPassword: '',
      password1: '',
      password2: '',
    }}
  >
    {({ values, errors, setFieldValue, touched, handleChange, handleSubmit }) => (
      <form onSubmit={handleSubmit} className="change-pass-form">
        <h1 className="change-pass-form__title f-32 f-500">Change password</h1>
        <h3 className="change-pass-form__subtitle">Type old password</h3>
        <PasswordField
          name="oldPassword"
          label="Old password *"
          value={values.oldPassword}
          onChange={handleChange}
          onClear={() => setFieldValue('oldPassword', '')}
          error={errors.oldPassword && touched.oldPassword && errors.oldPassword}
          className="change-pass-form__password"
        />

        <h3 className="change-pass-form__subtitle">Set a new password</h3>
        <PasswordField
          name="password1"
          label="Password *"
          value={values.password1}
          onChange={handleChange}
          onClear={() => setFieldValue('password1', '')}
          error={errors.password1 && touched.password1 && errors.password1}
          className="change-pass-form__password"
        />

        <PasswordField
          name="password2"
          label="Confirm password *"
          value={values.password2}
          onChange={handleChange}
          onClear={() => setFieldValue('password2', '')}
          error={
            (errors.password2 && touched.password2 && errors.password2) ||
            (values.password1 !== values.password2 &&
              touched.password2 &&
              'Password does not match') ||
            ''
          }
          className="change-pass-form__password"
        />

        <div className="change-pass-form__buttons">
          {onCancel && <RoundButton type="button" onClick={onCancel} label="Cancel" />}
          <RoundButton label="Save" type="submit" onSubmit={handleSubmit} fillBackground />
        </div>
      </form>
    )}
  </Formik>
);

export default ChangePassForm;
