import React from 'react';
import classnames from 'classnames';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { checkForValidFile } from '../../../helpers/utils';
import { CV_ALLOWED_FORMATS } from '../../../helpers/common';
import BankStatementUpload from '../../BankStatementUpload';
import { RoundButton } from '../../UI/RoundButton';
import Preloader from '../../UI/Preloader';
import { uploadFile } from '../../../store/actions/commonActions';
import { uploadBankStatements } from '../../../store/services/userServices';
import { getBankStatements } from '../../../store/actions/profileActions';
import Notify from '../../Notification';
import { FileName } from '../../FileName';
import s from './index.module.scss';

export const BankStatementUploadForm = ({ className }) => {
  const dispatch = useDispatch();

  const onSubmit = async ({ files }, formikBag) => {
    const { setSubmitting, resetForm } = formikBag;
    const payload = {};

    try {
      if (files.length) {
        const result = await Promise.all(files.map(file => dispatch(uploadFile(file))));
        if (result && result.filter(file => file).length) {
          payload.file_ids = result.filter(file => file).map(file => file.id);
          await uploadBankStatements(payload);
          dispatch(getBankStatements({ page: 1, limit: 20 }));
          resetForm();
        } else {
          return Notify.error({ text: 'Could not upload files' });
        }
      }
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={{
        files: [],
      }}
    >
      {({ values, setFieldError, errors, setFieldValue, handleSubmit, isSubmitting }) => (
        <form className={className} onSubmit={handleSubmit}>
          <h4 className={classnames(s.title, 'f-20', 'f-500')}>Upload bank statement</h4>
          <BankStatementUpload
            name="files"
            error={errors.files}
            className={classnames(s.field, 'f-24')}
            accept={CV_ALLOWED_FORMATS}
            onChange={e => {
              const files = Object.keys(e.target.files).map(idx => e.target.files[idx]);

              const validFiles = files.filter(file => {
                const { isValid, type, size } = checkForValidFile(
                  file,
                  CV_ALLOWED_FORMATS,
                  12582912
                );

                !isValid && size && setFieldError('files', 'File size is too big');
                !isValid &&
                  type &&
                  setFieldError('files', 'Incorrect file type. Upload file in pdf or doc format.');
                !isValid &&
                  type &&
                  setFieldError('files', 'Incorrect file type. Upload file in pdf or doc format.');

                return isValid;
              });

              setFieldValue('files', [...values.files, ...validFiles]);
            }}
          />

          {isSubmitting ? (
            <Preloader className={s.preloader} />
          ) : (
            !!values.files.length && (
              <div className={s.uploads}>
                {values.files.map((file, idx) => (
                  <FileName
                    key={idx}
                    file={file}
                    onRemove={() =>
                      setFieldValue(
                        'files',
                        values.files.filter((_, index) => index !== idx)
                      )
                    }
                  />
                ))}
              </div>
            )
          )}

          {!isSubmitting && (
            <div className={s.buttons}>
              <RoundButton
                label={() => 'Cancel'}
                type="button"
                disabled={isSubmitting}
                onClick={() => setFieldValue('files', [])}
              />
              <RoundButton
                label={() => 'Save'}
                fillBackground
                type="submit"
                disabled={isSubmitting}
                onSubmit={handleSubmit}
              />
            </div>
          )}
        </form>
      )}
    </Formik>
  );
};
