import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { getInactiveStartups } from '../../store/actions/startupActions';
import { RoundButton } from '../../components/UI/RoundButton';
import { StartupCard } from '../../components/Cards/StartupCard/v3';
import { APP_LINKS } from '../../helpers/links';
import Preloader from '../../components/UI/Preloader';
import s from './index.module.scss';

export const StartupsAll = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  const startups = useSelector(state => state.startupStore.inactiveStartups);

  useEffect(() => {
    !startups && getStartups();
  }, []);

  const getStartups = () => {
    dispatch(getInactiveStartups()).finally(() => setLoading(false));
  };

  const hasStartups = !!startups?.list.length;

  return (
    <div className={s.startups_all}>
      <h2 className="f-26 f-500">Other awesome startups</h2>
      {loading && !hasStartups ? (
        <Preloader className={s.startups_all__preloader} />
      ) : hasStartups ? (
        <div className={s.startups_all__list}>
          {startups.list.map(startup => (
            <StartupCard
              key={startup.id}
              startup={startup}
              href={APP_LINKS.startupDetail(startup.slug)}
              className={s.startups_all__card}
              hideFR
            />
          ))}
        </div>
      ) : (
        <div className={s.startups_all__empty}>
          <span className={classnames(s.startups_all__empty_title, 'f-16 f-400')}>
            No startups yet.
          </span>
        </div>
      )}

      {hasStartups && startups.page < startups.total_pages && (
        <div className={s.startups_all__buttons}>
          <RoundButton
            type="button"
            label="Show more startups"
            className={s.startups_all__button}
            onClick={getStartups}
          />
        </div>
      )}
    </div>
  );
};
