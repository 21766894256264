import React from 'react';
import classnames from 'classnames';
import { ReactComponent as Icon } from '../../assets/icon_pricing.svg';
import s from './index.module.scss';

export const AdvancedPricing = () => {
  return (
    <div className={s.wrapper}>
      <div className={classnames(s.header, 'f-16 f-500')}>
        <Icon /> Pricing
      </div>
      <div className={classnames(s.plan, 'f-20 f-500')}>for Advanced Plan</div>
      <table className={s.table_advanced}>
        <tbody>
          <tr>
            <td />
            <td>
              <div className="f-16 f-500">
                <span>8%</span> monthly repayment
              </div>
            </td>
            <td />
            <td>
              <div className="f-16 f-500">
                <span>4%</span> monthly repayment
              </div>
            </td>
            <td />
          </tr>
          <tr>
            <td />
            <td>Flat fee</td>
            <td>
              Effective IRR <br />
              (for reference use)
            </td>
            <td>Flat fee</td>
            <td>
              Effective IRR <br />
              (for reference use)
            </td>
          </tr>
          <tr>
            <td>Low risk</td>
            <td>6%</td>
            <td>16%</td>
            <td>7.5%</td>
            <td>18.1%</td>
          </tr>
          <tr>
            <td>Medium risk</td>
            <td>6.25%</td>
            <td>16.7%</td>
            <td>7.75%</td>
            <td>18.8%</td>
          </tr>
          <tr>
            <td>High risk</td>
            <td>6.5%</td>
            <td>17.5%</td>
            <td>8%</td>
            <td>19.5%</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
