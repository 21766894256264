import axios from '../../axios-api';
import Pathes from '../../helpers/pathes';
import { getMessage, getQuery } from '../../helpers/utils';

export const getPaymentMethods = () => {
  return axios
    .get(Pathes.LHV.methods)
    .then(response => {
      const { status, data } = response;
      const message = getMessage(data);
      if (status === 200) {
        return { data, success: true };
      }
      throw new Error(message);
    })
    .catch(e => ({ error: e.message, success: false }));
};

export const createLHVPaymentSession = payload => {
  return axios
    .post(Pathes.LHV.session, payload)
    .then(response => {
      const { status, data } = response;
      const message = getMessage(data);
      if (status === 200) {
        return { data, success: true };
      }
      throw new Error(message);
    })
    .catch(e => ({ error: e.message, success: false }));
};

export const getPaymentInfoByPaymentRef = paymentRef => {
  return axios
    .get(Pathes.LHV.paymentSession + getQuery({ payment_reference: paymentRef }))
    .then(response => {
      const { status, data } = response;
      const message = getMessage(data);
      if (status === 200) {
        return { data, success: true };
      }
      throw new Error(message);
    })
    .catch(e => ({ error: e.message, success: false }));
};
