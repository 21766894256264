import axios from '../../axios-api';
import Pathes from '../../helpers/pathes';
import { getMessage } from '../../helpers/utils';
import Notify from '../../components/Notification';

export const cancelWithdrawRequest = reqID => {
  return axios
    .delete(Pathes.Payments.withdrawRequest(reqID))
    .then(response => {
      const { status, data } = response;
      const message = getMessage(data);
      if (status === 200) {
        return { data, success: true };
      }
      throw new Error(message);
    })
    .catch(e => ({ success: false, message: e.message }));
};

// Get deadline details
export const getDeadlineDetails = deadline => {
  return axios
    .get(Pathes.Payments.deadlineDetails(deadline))
    .then(response => {
      const { status, data } = response;
      const message = getMessage(data);
      if (status === 200) {
        return { data, success: true };
      }
      throw new Error(message);
    })
    .catch(e => ({ success: false, message: e.message }));
};

// Create bank details
export const createBankDetail = payload => {
  return axios
    .post(Pathes.Payments.bankDetails, payload)
    .then(response => {
      const { status, data } = response;
      const message = getMessage(data);
      if (status === 200) {
        Notify.success({ text: 'Bank details successfully submitted' });
        return { data, success: true, message };
      }
      throw new Error(message);
    })
    .catch(e => ({ success: false, message: e.message }));
};

// Update bank details
export const updateBankDetail = (id, payload) => {
  return axios
    .put(Pathes.Payments.bankDetailByID(id), payload)
    .then(response => {
      const { status, data } = response;
      const message = getMessage(data);
      if (status === 200) {
        Notify.success({ text: 'Bank details successfully updated' });
        return { data, success: true, message };
      }
      throw new Error(message);
    })
    .catch(e => ({ success: false, message: e.message }));
};

// Check if country is in SEPA zone
export const checkCountryForSepa = countryCode => {
  return axios
    .get(Pathes.Payments.countryInSepa(countryCode))
    .then(response => {
      const { status, data } = response;
      const message = getMessage(data);
      if (status === 200) {
        return { data, success: true, message };
      }
      throw new Error(message);
    })
    .catch(e => ({ success: false, message: e.message }));
};

// Validate iban
export const checkIBAN = payload => {
  return axios
    .post(Pathes.Payments.ibanCheck, payload)
    .then(response => {
      const { status, data } = response;
      const message = getMessage(data);
      if (status === 200) {
        return { data, success: true, message };
      }
      throw new Error(message);
    })
    .catch(e => ({ success: false, message: e.message }));
};

// Delete bank details
export const removeBankDetail = id => {
  return axios
    .delete(Pathes.Payments.bankDetailByID(id))
    .then(response => {
      const { status, data } = response;
      const message = getMessage(data);
      if (status === 200) {
        Notify.success({ text: 'Bank details successfully removed' });
        return { data, success: true, message };
      }
      throw new Error(message);
    })
    .catch(e => ({ success: false, message: e.message }));
};

// Get bank detail
export const getBankDetailById = id => {
  return axios
    .get(Pathes.Payments.bankDetailByID(id))
    .then(response => {
      const { status, data } = response;
      const message = getMessage(data);
      if (status === 200) {
        return { data, success: true, message };
      }
      throw new Error(message);
    })
    .catch(e => ({ success: false, message: e.message }));
};
