import * as React from 'react';
import { connect } from 'react-redux';
import { renderRoutes } from 'react-router-config';
import Navigation from '../../components/Navigation';
import TabletNavigation from '../../components/TabletNavigation';
import TopHeader from '../../components/TopHeader';
import Footer from '../../components/Footer';
import { getFundraisingStatus } from '../../store/actions/fundraisingActions';
import { logoutUser } from '../../store/actions/userActions';
import { getTermsOfUseStatus } from '../../store/services/userServices';
import { setModals } from '../../store/actions/commonActions';
import { MODAL_TYPES } from '../../components/ModalProvider';
import { TermsAcceptWarning } from '../../components/Warnings/TermsAcceptWarning';
import { getUserProfile } from '../../store/actions/profileActions';
import './index.scss';

class RootPage extends React.Component {
  componentDidMount() {
    const { user, token, logoutUser } = this.props;
    if (!user || typeof token === 'string') {
      return logoutUser();
    }
    this.props.getFundraisingStatus();
    this.props.getUserProfile();

    // getTermsOfUseStatus().then(res => {
    //   if (res.success) {
    //     res.data.has_participated_in_fundraising &&
    //       !res.data.has_accepted &&
    //       this.props.setModals({ type: MODAL_TYPES.terms_of_use, onOutsideClick: true });
    //   }
    // });
  }

  allowedRoutes(user) {
    const { routes } = this.props && this.props.route;
    return routes ? routes.filter(route => (route.auth ? route.auth(user) : true)) : [];
  }

  render() {
    const { user, innerWidth } = this.props;

    return (
      <div className="root_page">
        {innerWidth > 1380 ? <Navigation /> : <TabletNavigation />}
        <main>
          <div className="root_page__content">
            <TopHeader />
            <div className="container-inner">
              {/*<TermsAcceptWarning className="root_page__warning" />*/}
            </div>
            {renderRoutes(this.allowedRoutes(user), { user })}
          </div>
          {innerWidth >= 1380 && <Footer />}
        </main>
        {innerWidth < 1380 && <Footer />}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.userStore.user,
  token: state.userStore.token,
  innerWidth: state.commonStore.innerWidth,
});

const mapDispatchToProps = dispatch => ({
  getFundraisingStatus: () => dispatch(getFundraisingStatus()),
  getUserProfile: () => dispatch(getUserProfile()),
  logoutUser: () => dispatch(logoutUser()),
  setModals: value => dispatch(setModals(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RootPage);
