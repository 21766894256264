import React from 'react';
import * as classnames from 'classnames';
import './index.scss';

const ProgressBar = ({ value, text, risky }) => {
  return (
    <div className="progress-bar__container">
      <div
        className={classnames(
          'progress-bar',
          value >= 100 && 'progress-bar__completed',
          risky && 'progress-bar__risky'
        )}
        style={{ width: `${value}%` }}
      >
        <span className="progress-bar__value f-500 f-14">{text || `${parseInt(value)}%`}</span>
      </div>
    </div>
  );
};

export default ProgressBar;
