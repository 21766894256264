import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { prettyFloatMoney } from '../../helpers/utils';
import {
  DATE_FORMAT_D,
  DATE_FORMAT_D_MMMM,
  DATE_FORMAT_DD_MMMM,
  DATE_FORMAT_M,
} from '../../helpers/common';
import { useOutsideClick } from '../../hooks/useOutsideClick';
import InfoToolboxRed from '../UI/InfoToolboxRed';
import { getBalance, getFundraisingDates } from '../../store/actions/dashboardActions';
import { SpecTriangleIcon } from '../UI/Icons';
import { CashDetailBox } from '../CashDetailBox';
import './index.scss';

const TopHeader = () => {
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const { balance, fundraisingDates } = useSelector(state => state.dashboardStore);
  const ref = useRef();
  useOutsideClick(ref, () => setShow(false), show);

  useEffect(() => {
    dispatch(getFundraisingDates());
    dispatch(getBalance());
  }, []);

  const getFundraisingState = () => {
    if (!fundraisingDates) {
      return '';
    }

    if (fundraisingDates.is_active && fundraisingDates.end_date) {
      return `Active fundraising until ${moment(fundraisingDates.end_date).format(
        DATE_FORMAT_DD_MMMM
      )}`;
    }

    if (!fundraisingDates.is_active && fundraisingDates.start_date && fundraisingDates.end_date) {
      if (
        moment(fundraisingDates.end_date).format(DATE_FORMAT_M) ===
        moment(fundraisingDates.start_date).format(DATE_FORMAT_M)
      ) {
        return `Next fundraising: ${moment(fundraisingDates.start_date).format(
          DATE_FORMAT_D
        )} - ${moment(fundraisingDates.end_date).format(DATE_FORMAT_D_MMMM)}`;
      }
      return `Next fundraising: ${moment(fundraisingDates.start_date).format(
        DATE_FORMAT_D_MMMM
      )} - ${moment(fundraisingDates.end_date).format(DATE_FORMAT_D_MMMM)}`;
    }
    // Fundraising not set on Database
    return '';
  };

  const fundraisingState = getFundraisingState();
  const showWarning = balance && balance.next_payment > balance.cash;

  return (
    <div className="top-header">
      <div className="container-inner">
        <div className="top-header__inner">
          <div className="top-header__dates f-16 f-400">{fundraisingState}</div>
          <div className="top-header__summary">
            <div className="top-header__payment">
              <span className="f-16 f-400">Next repayment:</span>
              <span className="f-16 f-500 black">
                {prettyFloatMoney(balance && balance.next_payment)}
              </span>
              {showWarning && (
                <InfoToolboxRed style={{ marginLeft: '5px' }} place="bottom">
                  <p className="top-header__warning">
                    Insufficient funds for a next repayment. Please add funds to your account.
                  </p>
                </InfoToolboxRed>
              )}
            </div>
            <div className="top-header__cash" ref={ref} onClick={() => setShow(!show)}>
              <span className="f-16 f-400">Cash: </span>
              <span className="f-16 f-500 black">{prettyFloatMoney(balance && balance.cash)}</span>
              {show && (!!balance?.frozen_amount || !!balance?.reserve_amount) && (
                <div className="top-header__cash_popup" onClick={e => e.stopPropagation()}>
                  <SpecTriangleIcon className="top-header__cash_popup_triangle" />
                  <CashDetailBox balance={balance} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopHeader;
