import React, { useState } from 'react';
import * as classnames from 'classnames';
import { Calendar } from 'react-modern-calendar-datepicker';
import OutsideClickHandler from 'react-outside-click-handler';

import './index.scss';

const DateSelect = ({ label, value, initialValue, onChange, onReset, error, className }) => {
  const [show, setShow] = useState(false);

  return (
    <div className={classnames('date-select', error && 'error', className)}>
      <div className="date-select__input">
        {value && <div className="date-select__label f-12">{label}</div>}
        <div
          onClick={() => setShow(true)}
          style={{ color: value ? '#000' : '#9E98A3' }}
          className="date-select__placeholder f-16"
        >
          {value ? `${value.day}/${value.month}/${value.year}` : label}
        </div>
      </div>
      {error && <div className="date-select__error f-14">{error}</div>}
      <div className={classnames('date-select__calendar-wrap', show && 'active')}>
        <OutsideClickHandler onOutsideClick={() => setShow(false)} disabled={!show}>
          <Calendar
            value={value || initialValue}
            onChange={date => {
              setShow(false);
              onChange(date);
            }}
            shouldHighlightWeekends
            renderFooter={() => (
              <div className="date-select__reset-wrap">
                <button
                  type="button"
                  className="date-select__reset f-14"
                  onClick={() => {
                    setShow(false);
                    onReset();
                  }}
                >
                  Reset
                </button>
              </div>
            )}
          />
        </OutsideClickHandler>
      </div>
    </div>
  );
};

export default DateSelect;
