import React from 'react';
import { Formik } from 'formik';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { RoundButton } from '../../UI/RoundButton';
import AccountSelect from '../../UI/AccountSelect';
import { MONEY_REGEX } from '../../../helpers/common';
import { prettyFloatMoney } from '../../../helpers/utils';
import { MoneyField } from '../../UI/MoneyField';
import { ROLES } from '../../../helpers/constants';
import WarningBlock from '../../UI/WarningBlock';
import { RoundLink } from '../../UI/RoundLink';
import { APP_LINKS } from '../../../helpers/links';
import './index.scss';

const VALIDATION_SCHEMA = Yup.object().shape({
  account: Yup.object().shape({
    id: Yup.number().required(),
  }),
  amount: Yup.string().matches(MONEY_REGEX, 'Enter valid amount').required('Please enter amount'),
});

const WithdrawForm = ({ balance, accounts, onSubmit, children }) => {
  const user = useSelector(state => state.userStore.user);
  const finances = useSelector(state => state.dashboardStore.balance);

  return (
    <Formik
      enableReinitialize
      validationSchema={VALIDATION_SCHEMA}
      onSubmit={(values, formikBag) => onSubmit(values, formikBag)}
      initialValues={{
        amount: '',
        account: (accounts && accounts[0]) || null,
      }}
    >
      {({ values, errors, touched, handleSubmit, setFieldValue }) => (
        <>
          {user.role !== ROLES.founder && (
            <WarningBlock className="withdraw-form__cofounder" withBorder>
              <div className="f-16 f-500">
                Withdraw funds from the company’s Scramble cash account is available only to the
                company administrator.
              </div>
            </WarningBlock>
          )}

          <form onSubmit={handleSubmit} className="withdraw-form">
            <p className="withdraw-form__from f-16">From cash balance</p>
            <p className="withdraw-form__from-amount f-20 f-500">{prettyFloatMoney(balance)}</p>

            {!!finances?.frozen_amount && (
              <div className="withdraw-form__frozen">
                <p className="withdraw-form__frozen_title f-16">Frozen amount</p>
                <p className="withdraw-form__frozen_amount f-20 f-500">
                  {prettyFloatMoney(finances?.frozen_amount)}
                </p>
              </div>
            )}

            <p className="withdraw-form__to f-16">To bank account</p>
            <AccountSelect
              value={values.account}
              options={accounts || []}
              onSelect={option => setFieldValue('account', option)}
              className="withdraw-form__select"
              error={errors.account && touched.account && errors.account}
              disabled={user.role !== ROLES.founder}
            />

            <div className="withdraw-form__add_bank_account">
              <RoundLink path={APP_LINKS.bankAccountsCreate} label="Add a new bank account" />
            </div>

            <MoneyField
              label="Amount"
              name="amount"
              onChange={(_, amount) => setFieldValue('amount', amount)}
              value={values.amount}
              onClear={() => setFieldValue('amount', '')}
              className="withdraw-form__amount"
              error={errors.amount && touched.amount && errors.amount}
              disabled={user.role !== ROLES.founder}
            />

            <p className="f-16 withdraw-form__commission">No commission</p>

            <RoundButton
              type="submit"
              label="Withdraw"
              onSubmit={handleSubmit}
              fillBackground
              className="withdraw-form__submit"
              disabled={user.role !== ROLES.founder}
            />

            {children}

            <div className="withdraw-form__support f-16">
              <p>Scramble transfers money in EUR.</p>
              <p>
                If your account uses a different currency, the currency conversion to Euro will
                depend on your bank/card issuers.
              </p>{' '}
              Our manager will process your withdrawal request within one business day and transfer
              the specified amount to your bank account. Usually the transfers processed within 2-4
              working days. If your transfer is not processed in 4 working days, please email us at{' '}
            </div>
          </form>
        </>
      )}
    </Formik>
  );
};

export default WithdrawForm;
