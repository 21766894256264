import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import qs from 'qs';
import { contactInfoUpdate, getUserLocation, registerUser } from '../../store/actions/userActions';
import { ROLES } from '../../helpers/constants';
import { getUserProfile } from '../../store/actions/profileActions';
import { uploadFile, uploadPhoto } from '../../store/actions/commonActions';
import RegisterForm from '../../components/Forms/RegisterForm';
import VerifyForm from '../../components/VerifyForm';
import { cropAvatar } from '../../components/AvatarEdit';
import ContactInfoForm from '../../components/Forms/ContactInfoForm';
import PhoneVerificationForm from '../../components/Forms/PhoneVerificationForm';
import './index.scss';
import { sendVerificationCode, validatePhoneNumber } from '../../store/services/userServices';

const STEPS = {
  main: 'main',
  contact: 'contact',
  phone_verification: 'phone_verification',
  veriff: 'veriff',
};

const SignUp = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { step: queryStep } = qs.parse(location.search.replace('?', ''));
  const { user, userLocation } = useSelector(state => state.userStore);
  const profile = useSelector(state => state.profileStore.profile.data);

  const [phone, setPhone] = useState('');
  const [step, setStep] = useState(
    user && Object.keys(STEPS).includes(queryStep)
      ? queryStep
      : profile
      ? STEPS.contact
      : STEPS.main
  );

  useEffect(() => {
    user && dispatch(getUserProfile());
    dispatch(getUserLocation());
  }, []);

  useEffect(() => {
    if (profile && step === STEPS.main) {
      setStep(STEPS.contact);
    }
  }, [profile]);

  const getStepNumber = step => {
    const index = Object.keys(STEPS).findIndex(key => key === step);
    return index >= 0 ? index + 1 : 1;
  };

  const onRegister = async (data, { setSubmitting }) => {
    const payload = {
      email: data.email,
      password: data.password,
      invite_code: null,
    };
    const res = await dispatch(registerUser(payload));
    setSubmitting(false);
    res && res.success && setStep(STEPS.contact);
  };

  const onContactInfoSubmit = async (data, formikProps) => {
    const { setSubmitting, errors, setErrors } = formikProps;

    try {
      const payload = {
        phone: data.phone,
        first_name: data.first_name,
        last_name: data.last_name,
        citizenship: data.citizenship,
        citizenship_code: data.citizenship_code,
        company_name: data.company_name,
        company_description: data.description,
        description: data.about,
        cv: null,
        photo: null,
      };

      if (data.photo && data.editorRef) {
        const croppedAvatar = cropAvatar(data.editorRef);
        const res = await dispatch(uploadPhoto(croppedAvatar));
        res && res.id && (payload.photo = res.id);
      }

      if (data.resume) {
        const res = await dispatch(uploadFile(data.resume));
        res && res.id && (payload.cv = res.id);
      }

      const res = await dispatch(contactInfoUpdate(payload));
      if (res && res.success) {
        setPhone(data.phone);
        await sendVerificationCode(data.phone);
        setStep(STEPS.phone_verification);
      }

      if (res && !res.success && res.errors) {
        setErrors({ ...errors, ...res.errors });
        window.scrollTo(0, 0);
      }
    } catch (e) {
      // Do nothing
    } finally {
      setSubmitting(false);
    }
  };

  const onPhoneVerification = async (values, { setErrors }) => {
    if (values.code.length < 6) {
      return setErrors({ code: 'Verification code is required' });
    }

    const res = await validatePhoneNumber({
      phone_number: phone,
      code: values.code.join(''),
    });

    if (res && res.success) {
      setStep(STEPS.veriff);
    } else {
      setErrors({ code: res.message });
    }
  };

  const profileUser = profile?.user;

  const userData = useMemo(
    () => ({
      ...user,
      ...profileUser,
      cv: profile ? profile.cv : null,
      description: profile ? profile.description : '',
      company: profile ? profile.company : null,
    }),
    [user, profile]
  );

  return (
    <div className="sign-up">
      <div className="sign-up__inner">
        <div className="sign-up__step-number">
          Step {getStepNumber(step)} of {Object.keys(STEPS).length}
        </div>

        {step === STEPS.main && (
          <RegisterForm
            onSubmit={onRegister}
            nextStep={() => setStep(STEPS.contact)}
            role={ROLES.founder}
          />
        )}

        {step === STEPS.contact && (
          <ContactInfoForm
            onSubmit={onContactInfoSubmit}
            userLocation={userLocation}
            user={userData}
            role={ROLES.founder}
          />
        )}

        {step === STEPS.phone_verification && (
          <PhoneVerificationForm
            phone={phone}
            onSubmit={onPhoneVerification}
            onLater={() => setStep(STEPS.veriff)}
          />
        )}

        {step === STEPS.veriff && <VerifyForm />}
      </div>
    </div>
  );
};

export default SignUp;
