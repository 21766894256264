import React from 'react';
import classnames from 'classnames';
import { FileName } from '../../FileName';
import { FileUploadField } from '../../../components/UI/FileUploadField';
import { ALLOWED_FILE_SIZES, BANK_STATEMENT_ALLOWED_FORMATS } from '../../../helpers/common';
import { checkForValidFile } from '../../../helpers/utils';
import { Tooltip } from '../../Tooltip';
import s from './index.module.scss';

export const BankStatementUploadField = ({
  name,
  value = [],
  error,
  onBlur,
  onChange,
  onError,
  onRemove,
}) => {
  return (
    <div className={s.wrapper}>
      <div className={classnames(s.label, 'f-16')}>
        Bank statement *
        <Tooltip
          renderContent={() => (
            <div className={s.tooltip_text}>
              Under AML requirements, a bank statement is required to ensure that the bank account
              belongs to you. The statement must clearly show your IBAN and full name. You can
              download your bank statement from your bank&apos;s website or app. Please note that
              screenshots of your bank statement will not be accepted.
            </div>
          )}
        />
      </div>
      {value.length ? (
        <div className={s.files}>
          {value.map((file, index) => (
            <FileName key={index} file={file} onRemove={onRemove} />
          ))}
        </div>
      ) : (
        <FileUploadField
          name={name}
          multiple={false}
          label="Upload document in PDF format"
          description="Upload file up to 1Mb"
          accept={BANK_STATEMENT_ALLOWED_FORMATS}
          error={!!error}
          className={s.upload}
          onBlur={onBlur}
          onChange={e => {
            const files = Object.keys(e.target.files).map(idx => e.target.files[idx]);
            const validFiles = files.filter(file => {
              const { isValid, type, size } = checkForValidFile(
                file,
                BANK_STATEMENT_ALLOWED_FORMATS,
                ALLOWED_FILE_SIZES['1mb']
              );

              !isValid && size && onError('File size is too big');
              !isValid && type && onError('Incorrect file type. Upload file in PDF format.');

              return isValid;
            });
            onChange(validFiles);
          }}
        />
      )}
    </div>
  );
};
