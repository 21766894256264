import * as React from 'react';
import { connect } from 'react-redux';
import LoginForm from '../../components/Forms/LoginForm';
import { loginUser } from '../../store/actions/userActions';
import './index.scss';

class SignIn extends React.Component {
  componentDidMount() {
    const { user, history } = this.props;
    user && history.push('/dashboard');
  }

  onSubmit = values => {
    const payload = {
      email: values.email,
      password: values.password,
    };
    this.props.login(payload);
  };

  render() {
    const { loginError } = this.props;
    return (
      <div className="sign-in">
        <div className="sign-in__inner">
          <LoginForm onSubmit={this.onSubmit} error={loginError} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.userStore.user,
  loginError: state.userStore.loginError,
});

const mapDispatchToProps = dispatch => ({
  login: data => dispatch(loginUser(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
