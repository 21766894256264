import { METADATA } from '../../helpers/metadata';
import { LOGOUT_USER } from '../actions/actionTypes';
import {
  GET_LOAN_DETAIL,
  GET_LOAN_SWAP_PARTNERS,
  GET_LOAN_TERMS,
  GET_LOANS_LIST,
  GET_PAYMENT_HISTORY,
} from '../types/loanTypes';

const initialState = {
  loansList: { ...METADATA.default, data: null },
  loanDetail: { ...METADATA.default, data: null },
  swapPartners: { ...METADATA.default, data: null },
  loanTerms: { ...METADATA.default, data: null },
  paymentHistory: { ...METADATA.default, data: null },
};

const loanReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LOANS_LIST.REQUEST:
      return {
        ...state,
        loansList: { ...state.loansList, ...METADATA.request },
      };
    case GET_LOANS_LIST.SUCCESS:
      return {
        ...state,
        loansList: { ...METADATA.success, data: action.payload },
      };
    case GET_LOANS_LIST.FAILURE:
      return {
        ...state,
        loansList: {
          ...state.loansList,
          ...METADATA.error,
          error: action.error,
        },
      };
    case GET_LOAN_DETAIL.REQUEST:
      return {
        ...state,
        loanDetail: { ...state.loanDetail, ...METADATA.request, data: null },
      };
    case GET_LOAN_DETAIL.SUCCESS:
      return {
        ...state,
        loanDetail: { ...METADATA.success, data: action.payload },
      };
    case GET_LOAN_DETAIL.FAILURE:
      return {
        ...state,
        loanDetail: {
          ...state.loanDetail,
          ...METADATA.error,
          error: action.error,
        },
      };
    case GET_LOAN_SWAP_PARTNERS.REQUEST:
      return {
        ...state,
        swapPartners: { ...state.swapPartners, ...METADATA.request },
      };
    case GET_LOAN_SWAP_PARTNERS.SUCCESS:
      return {
        ...state,
        swapPartners: { ...METADATA.success, data: action.payload },
      };
    case GET_LOAN_SWAP_PARTNERS.FAILURE:
      return {
        ...state,
        swapPartners: {
          ...state.swapPartners,
          ...METADATA.error,
          error: action.error,
        },
      };
    case GET_LOAN_TERMS.REQUEST:
      return {
        ...state,
        loanTerms: { ...state.loanTerms, ...METADATA.request },
      };
    case GET_LOAN_TERMS.SUCCESS:
      return {
        ...state,
        loanTerms: { ...METADATA.success, data: action.payload },
      };
    case GET_LOAN_TERMS.FAILURE:
      return {
        ...state,
        loanTerms: {
          ...state.loanTerms,
          ...METADATA.error,
          error: action.error,
        },
      };
    case GET_PAYMENT_HISTORY.REQUEST:
      return {
        ...state,
        paymentHistory: { ...state.paymentHistory, ...METADATA.request },
      };
    case GET_PAYMENT_HISTORY.SUCCESS:
      return {
        ...state,
        paymentHistory: { ...METADATA.success, data: action.payload },
      };
    case GET_PAYMENT_HISTORY.FAILURE:
      return {
        ...state,
        paymentHistory: {
          ...state.paymentHistory,
          ...METADATA.error,
          error: action.error,
        },
      };

    case LOGOUT_USER:
      return initialState;
    default:
      return state;
  }
};

export default loanReducer;
