import React from 'react';
import classnames from 'classnames';
import LoanRepayments from './assets/loan_terms_repayments.svg';
import ImageTwo from './assets/loan_terms_flexible.svg';
import ImageThree from './assets/loan_terms_repayments_option1.svg';
import ImageFour from './assets/loan_terms_repayments_option2.svg';
import ImageFive from './assets/loan_terms_repayments_option3.svg';
import withScroll from '../../../../hoc/withScroll';
import { MyLink } from '../../../../components/MyLink';
import { APP_LINKS } from '../../../../helpers/links';
import { ExampleTable } from './components/ExampleTable';
import Dropdown from '../../../../components/Dropdown';
import {
  EXAMPLE_DATA_12,
  EXAMPLE_DATA_18,
  EXAMPLE_DATA_24,
  EXAMPLE_DATA_6,
} from './components/ExampleTable/data';
import s from './index.module.scss';

const LoanTermsVersion02 = () => (
  <div className={s.page}>
    <div className="container-inner">
      <h1 className="f-42 f-500">Loan terms</h1>
      <section className={s.page__pricing}>
        <h2 className="f-26 f-500">Loan pricing</h2>
        <p className={classnames(s.page__pricing_desc, 'f-16 f-400')}>
          Simple flat fee, no hidden costs
        </p>
        <div className={s.page__pricing_groups}>
          <div className={s.page__pricing_group}>
            <div className="f-28 f-600 nowrap">6% flat fee</div>
            <div className="f-16 f-400">for each 6 months</div>
          </div>
          <div className={s.page__pricing_group}>
            <div className="f-28 f-600 nowrap">Up to 2 years</div>
            <div className="f-16 f-400">
              Use money for 6 months or <br /> up to 2 years
            </div>
          </div>
          <div className={s.page__pricing_group}>
            <div className="f-28 f-600 nowrap">4% flat repayment</div>
            <div className="f-16 f-400">
              Flat monthly principal <br /> repayment
            </div>
          </div>
        </div>
        <ul className={s.page__pricing_list}>
          <li>No interest rate</li>
          <li>No dilution of ownership</li>
          <li>No hidden charges or fees</li>
        </ul>
      </section>

      <section className={s.page__repayments}>
        <h2 className="f-26 f-500">Loan repayments</h2>
        <p className={classnames(s.page__repayments_desc, 'f-16 f-400')}>
          Monthly loan repayments are deducted from your Scramble cash account on the 5th day, the
          month after the loan is issued.
        </p>
        <h3 className="f-20 f-500">Regular repayment schedule</h3>
        <p className={classnames(s.page__repayments_desc, 'f-16 f-400')}>
          Right after the end of a round, we charge a 6% flat fee. Then, you pay off 4% of the loan
          principal for the first 5 months, and 80% of the loan principal in the 6th month.
        </p>
        <p className="f-16 f-400" style={{ marginTop: '10px' }}>
          You can prepay up to 100% of the loan without any extra fees or penalties.
        </p>
        <img
          src={LoanRepayments}
          alt="Loan Terms Repayments"
          className={s.page__repayments_image}
        />
        <Dropdown
          label={<span className="f-16 f-400">Regular repayment schedule example</span>}
          className={s.page__repayments_dropdown}
          defaultShow
        >
          <div className={s.page__summary}>
            <div className={s.page__summary_block}>
              <div className="f-16 f-400">Loan amount</div>
              <div className="f-20 f-600">€10’000</div>
            </div>
            <div className={s.page__summary_block}>
              <div className="f-16 f-400">Origination date</div>
              <div className="f-20 f-600">05.01.2022</div>
            </div>
          </div>
          <ExampleTable data={EXAMPLE_DATA_6} />
        </Dropdown>

        <h3 className="f-20 f-500">Flexible repayment schedule</h3>
        <p className={classnames(s.page__repayments_desc, 'f-16 f-400')}>
          Based on your actual cash flow, each repayment can be turned into “bullet” (to be paid in
          the last month).
        </p>
        <ul className={s.page__grouped_list}>
          <li>
            <div className="f-18 f-500">1% flat fee for each additional bullet conversion</div>
          </li>
        </ul>
        <img
          src={ImageTwo}
          alt="Loan Terms Flexible repayments"
          className={s.page__repayments_image}
        />

        <h3 className="f-20 f-500">What if I want to extend my loan repayment period?</h3>
        <p className={(s.page__repayments_desc, 'f-16 f-400')}>
          Yes, you can do it. However, it will cost you 6% of loan amount for every 6 months.
        </p>
        <Dropdown
          label={<span className="f-16 f-500"> Option 1: Extend your loan term to 12 months</span>}
          defaultShow={false}
          className={s.page__repayments_option}
        >
          <div className="f-16 f-400" style={{ marginTop: '10px' }}>
            What happens if you don’t pay off the remaining part of the loan principal in the 6th
            month?
            <br />
            Then your payment schedule is automatically extended for the{' '}
            <span className="f-500">additional 6 months</span>.
            <br />A <span className="f-500">6% flat fee</span>, as well as{' '}
            <span className="f-500">4% monthly loan principal</span> repayment, are deducted in the{' '}
            <span className="f-500">6th month</span>.
            <br />
            Then from <span className="f-500">7th to 11th months</span>, you pay{' '}
            <span className="f-500">4% of the loan principal</span> each month. In the{' '}
            <span className="f-500">12th month</span>, you pay off the remaining{' '}
            <span className="f-500">56% of the loan principal</span>.
          </div>
          <img src={ImageThree} alt="Option 1" className={s.page__repayments_image} />

          <Dropdown
            label={<span className="f-16 f-500">12 month repayment schedule example</span>}
            defaultShow
            className={s.page__repayments_dropdown}
          >
            <ExampleTable data={EXAMPLE_DATA_12} />
          </Dropdown>
        </Dropdown>

        <Dropdown
          label={<span className="f-16 f-500">Option 2: Extend your loan term to 18 months</span>}
          defaultShow={false}
          className={s.page__repayments_option}
        >
          <div className="f-16 f-400" style={{ marginTop: '10px' }}>
            What happens if you don’t pay off the remaining part of the loan principal in the 12th
            month?
            <br />
            Then your payment schedule is automatically extended for the{' '}
            <span className="f-500">additional 6 months</span>.
            <br />A <span className="f-500">6% flat fee</span>, as well as{' '}
            <span className="f-500">4% monthly loan principal</span> repayment, are deducted in the{' '}
            <span className="f-500">12th month</span>.
            <br />
            Then from <span className="f-500">13th to 17th months</span>, you pay{' '}
            <span className="f-500">4% of the loan principal</span> each month. In the{' '}
            <span className="f-500">18th month</span>, you pay off the remaining{' '}
            <span className="f-500">32% of the loan principal</span>.
          </div>
          <img src={ImageFour} alt="Option 2" className={s.page__repayments_image} />

          <Dropdown
            label={<span className="f-16 f-500">18 month repayment schedule example</span>}
            defaultShow
            className={s.page__repayments_dropdown}
          >
            <ExampleTable data={EXAMPLE_DATA_18} />
          </Dropdown>
        </Dropdown>

        <Dropdown
          label={<span className="f-16 f-500">Option 3: Extend your loan term to 24 months</span>}
          defaultShow={false}
          className={s.page__repayments_option}
        >
          <div className="f-16 f-400" style={{ marginTop: '10px' }}>
            What happens if you don’t pay off the remaining part of the loan principal in the 18th
            month?
            <br />
            Then your repayment schedule is automatically extended for the{' '}
            <span className="f-500">additional 6 months</span>.
            <br />A <span className="f-500">6% flat fee</span>, as well as{' '}
            <span className="f-500">4% monthly loan principal</span> repayment, are deducted in the{' '}
            <span className="f-500">18th month</span>.
            <br />
            Then from <span className="f-500">19th to 23th months</span>, you pay{' '}
            <span className="f-500">4% of the loan principal</span> each month. In the{' '}
            <span className="f-500">24th month</span>, you pay off the remaining{' '}
            <span className="f-500">8% of the loan principal</span>.
          </div>
          <img src={ImageFive} alt="Option 2" className={s.page__repayments_image} />

          <Dropdown
            label={<span className="f-16 f-500">24 month repayment schedule example</span>}
            defaultShow
            className={s.page__repayments_dropdown}
          >
            <ExampleTable data={EXAMPLE_DATA_24} />
          </Dropdown>
        </Dropdown>
      </section>

      <section className={s.page__wrong}>
        <h2 className="f-26 f-500">What if something goes wrong?</h2>
        <ul className={s.page__grouped_list}>
          <li>
            <div className="f-18 f-500">Need more capital?</div>
            <div className="f-16 f-400">
              As long as a business is operational, funding is available every month.
            </div>
          </li>
          <li>
            <div className="f-18 f-500">Can’t pay a loan on time?</div>
            <div className="f-16 f-400">
              Turn your missed payments into «bullet» with an extra 1% flat fee
            </div>
          </li>
          <li>
            <div className="f-18 f-500">Business fails completely?</div>
            <div className="f-16 f-400">
              The co-founder team provides a joint personal guarantee for a maximum of 20% of the
              borrowed principal.
              <br />
              Co-founders have up to 5 years to pay back loans from their personal income.
            </div>
          </li>
        </ul>
      </section>

      <section className={s.page__agreements}>
        <h2 className="f-26 f-500">Loan agreements</h2>
        <p className={classnames(s.page__agreements_desc, 'f-16 f-400')}>
          When you set up your fundraising settings, you accept the terms of the Finiancing
          Agreement and the Subordinated Financing Agreement. These documents for each created loan
          are available inside a loan card on the <MyLink href={APP_LINKS.loans}>Loans page</MyLink>
          .
        </p>
        <ul className={s.page__agreements_list}>
          <li>
            <MyLink href={APP_LINKS.unsubordinatedFinancialAgreement} isExternal>
              Download Financing Agreement template (pdf)
            </MyLink>
          </li>
          <li>
            <MyLink href={APP_LINKS.subordinatedFinancialAgreement} isExternal>
              Download Subordinated Financing Agreement template (pdf)
            </MyLink>
          </li>
          <li>
            <MyLink href={APP_LINKS.suretyAgreement} isExternal>
              Download Surety Agreement template (pdf)
            </MyLink>
          </li>
        </ul>
      </section>
    </div>
  </div>
);

export default withScroll(LoanTermsVersion02);
