import * as React from 'react';
import { connect } from 'react-redux';
import ForgotPasswordForm from '../../components/Forms/ForgotPasswordForm';
import { forgotPassword } from '../../store/actions/userActions';
import './index.scss';

class ForgotPassword extends React.Component {
  onSubmit = async ({ email }, formikBag) => {
    const res = await this.props.forgotPassword({ email });
    if (res && res.success) {
      formikBag.resetForm();
    }
  };

  render() {
    return (
      <div className="forgot-password">
        <div className="forgot-password__inner">
          <ForgotPasswordForm onSubmit={this.onSubmit} />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  forgotPassword: email => dispatch(forgotPassword(email)),
});

export default connect(null, mapDispatchToProps)(ForgotPassword);
