import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as classnames from 'classnames';
import { Formik } from 'formik';
import { RoundButton } from '../../../components/UI/RoundButton';
import { getActivityTypesList } from '../../../store/actions/activityActions';
import { InputTextField } from '../../../components/UI/InputTextField';
import Checkbox from '../../../components/UI/Checkbox';
import DateSelect from '../../../components/UI/DateSelect';
import ActivityTypeSelect from './select';
import './index.scss';

const ActivityFilters = ({ onSubmit }) => {
  const dispatch = useDispatch();
  const { activityTypes } = useSelector(state => state.activityStore);

  useEffect(() => {
    dispatch(getActivityTypesList());
  }, []);

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={{
        startDate: null,
        endDate: null,
        types: [],
        groupResults: true,
        groupByFundraising: false,
        loanNumber: '',
      }}
    >
      {({ values, setFieldValue, setValues, handleChange, handleSubmit }) => (
        <form className="activity_filters" onSubmit={handleSubmit}>
          <div className="activity_filters__row">
            <DateSelect
              name="startDate"
              label="Start date"
              value={values.startDate}
              onChange={date => setFieldValue('startDate', date)}
              onReset={() => setFieldValue('startDate', null)}
              className="activity_filters__date"
            />

            <DateSelect
              name="endDate"
              label="End date"
              value={values.endDate}
              onChange={date => setFieldValue('endDate', date)}
              onReset={() => setFieldValue('endDate', null)}
              className="activity_filters__date"
            />

            <InputTextField
              name="loanNumber"
              label="Loan number"
              onChange={handleChange}
              onClear={() => setFieldValue('loanNumber', '')}
              value={values.loanNumber}
              className="activity_filters__input"
            />

            <ActivityTypeSelect
              label="Hello"
              className="activity_filters__select"
              selected={[]}
              onChange={selected => setFieldValue('types', selected)}
              options={
                activityTypes
                  ? Object.keys(activityTypes).map(key => ({
                      value: key,
                      label: activityTypes[key],
                    }))
                  : []
              }
            />

            <RoundButton
              label="Search"
              type="submit"
              fullWidth
              onSubmit={handleSubmit}
              className="activity_filters__submit"
            />
          </div>

          <div className="activity_filters__row_bottom">
            <Checkbox
              name="groupResults"
              isChecked={values.groupResults}
              onChange={e => {
                const newValue = !values.groupResults;
                const updatedValues = {
                  ...values,
                  groupResults: newValue,
                  groupByFundraising:
                    newValue && values.groupByFundraising ? false : values.groupByFundraising,
                };
                setValues(updatedValues);
                onSubmit(updatedValues);
                handleChange(e);
              }}
              className="activity_filters__group"
              render={() => (
                <span className={classnames('f-16')}>Group results by operation type</span>
              )}
            />

            <Checkbox
              name="groupByFundraising"
              isChecked={values.groupByFundraising}
              onChange={e => {
                const newValue = !values.groupByFundraising;
                const updatedValues = {
                  ...values,
                  groupByFundraising: newValue,
                  groupResults: newValue && values.groupResults ? false : values.groupResults,
                };
                setValues(updatedValues);
                onSubmit(updatedValues);
                handleChange(e);
              }}
              className="activity_filters__group"
              render={() => (
                <span className={classnames('f-16')}>Group results by fundraisings</span>
              )}
            />
          </div>
        </form>
      )}
    </Formik>
  );
};

export default ActivityFilters;
