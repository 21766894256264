import React from 'react';
import * as classnames from 'classnames';
import './index.scss';

const Checkbox = ({ name, isChecked, render, error, onChange, className, disabled, ...other }) => (
  <label
    className={classnames('checkbox', error && 'error', disabled && 'disabled', className)}
    htmlFor={name}
  >
    {render && render()}
    <input
      id={name}
      name={name}
      type="checkbox"
      className="checkbox__input"
      checked={isChecked}
      onChange={onChange}
      disabled={disabled}
      {...other}
    />
    <span className="checkbox__checkmark" />
  </label>
);

export default Checkbox;
