import Decimal from 'decimal.js-light';

export const convertCurrencyMaskToNumber = value => {
  try {
    if (value && typeof value === 'string') {
      const formattedValue = value.replaceAll("'", '');
      return new Decimal(formattedValue).toNumber();
    }
    return 0;
  } catch (e) {
    return 0;
  }
};

export const convertCurrencyMaskToString = value => {
  try {
    if (value && typeof value === 'string') {
      return value.replaceAll("'", '');
    }
    return '';
  } catch (e) {
    return '';
  }
};
