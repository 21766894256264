import React from 'react';
import { MyLink } from '../MyLink';
import { CONTACT_PHONE } from '../../helpers/constants';
import { ReactComponent as IconWhatsApp } from '../../assets/icons/icon_whatsapp.svg';
import s from './index.module.scss';

export const WhatsAppChatLink = () => {
  const phone = CONTACT_PHONE.replace('+', '').replaceAll(' ', '');

  return (
    <MyLink href={`https://wa.me/${phone}`} className={s.link} isExternal newWindow>
      <IconWhatsApp />
      <span>Message us on WhatsApp</span>
    </MyLink>
  );
};
