import React, { useEffect } from 'react';
import qs from 'qs';
import Question from '../../components/Question';
import { FAQ } from '../../components/FAQ/faq';
import './index.scss';

export const FAQ_SECTIONS_LIST = [
  'getting_started',
  'fundraising',
  'repayments',
  'withdraw',
  'loans',
  'verification',
];

const HelpPage = ({ location }) => {
  useEffect(() => {
    const { to } = qs.parse(location.search.replace('?', ''));
    if (to && FAQ_SECTIONS_LIST.includes(to)) {
      const scrollEl = document.getElementById(to);
      const mainEl = document.querySelector('main');
      if (scrollEl) {
        scrollEl && scrollEl.scrollIntoView({ block: 'start', behavior: 'smooth' });
      } else {
        mainEl && mainEl.scrollTo(0, 0);
        window.scrollTo(0, 0);
      }
    }
  }, []);

  return (
    <div className="help-page">
      <div className="container-inner">
        <h1 className="help-page__title f-500 f-42">FAQ</h1>
        <section id={FAQ_SECTIONS_LIST[0]}>
          <h2 className="f-24 f-500">Getting started</h2>
          <div>
            <Question faq={FAQ.how_it_works} />
            <Question faq={FAQ.what_about_the_loan_terms} />
            <Question faq={FAQ.how_much_does_it_cost} />
          </div>
        </section>
        <section id={FAQ_SECTIONS_LIST[1]}>
          <h2 className="f-24 f-500">Fundraising</h2>
          <div>
            <Question faq={FAQ.how_to_participate_in_a_fundraising_round} />
            <Question faq={FAQ.how_much_money_can_i_get} />
            <Question faq={FAQ.when_do_fundraising_rounds_usually_start} />
            <Question faq={FAQ.which_loan_groups_should_i_choose} />
            <Question faq={FAQ.which_strategy_should_i_choose} />
            <Question faq={FAQ.who_are_the_mutual_insurance_partners} />
            <Question faq={FAQ.how_to_get_a_loan} />
            <Question faq={FAQ.can_i_refuse_the_loans_i_received} />
          </div>
        </section>
        <section id={FAQ_SECTIONS_LIST[2]}>
          <h2 className="f-24 f-500">Repayments</h2>
          <div>
            <Question faq={FAQ.where_can_i_see_the_repayment_schedule} />
            <Question faq={FAQ.how_do_i_repay_loans} />
            <Question faq={FAQ.what_if_i_late_on_my_loan_repayment} />
            <Question faq={FAQ.what_is_the_regular_loan_repayment_schedule} />
            <Question faq={FAQ.what_if_i_want_to_repay_the_loan_early} />
            <Question faq={FAQ.what_about_the_overdue_loans} />
            <Question faq={FAQ.my_insurance_was_triggered_how_and_when_will_i_be_repaid} />
            <Question faq={FAQ.what_is_the_order_in_which_my_debts_are_paid_off} />
            <Question faq={FAQ.what_if_my_startup_fails} />
          </div>
        </section>
        <section id={FAQ_SECTIONS_LIST[3]}>
          <h2 className="f-24 f-500">Add / Withdraw funds</h2>
          <div>
            <Question faq={FAQ.how_can_i_add_funds_to_my_cash_account} />
            <Question faq={FAQ.how_can_i_withdraw_funds} />
            <Question faq={FAQ.can_i_change_the_bank_account_i_withdraw_funds_to} />
            <Question faq={FAQ.where_can_i_find_the_account_statement} />
          </div>
        </section>
        <section id={FAQ_SECTIONS_LIST[4]}>
          <h2 className="f-24 f-500">Loans</h2>
          <div>
            <Question faq={FAQ.what_about_the_financing_agreements} />
            <Question faq={FAQ.what_is_the_loan_group} />
            <Question faq={FAQ.what_is_the_annual_interest_rate} />
            <Question faq={FAQ.what_is_the_success_fee} />
            <Question faq={FAQ.what_is_the_grace_period} />
            <Question faq={FAQ.what_is_the_disbursement_fee} />
            <Question faq={FAQ.what_is_the_refinancing_fee} />
            <Question faq={FAQ.what_is_the_interest_beyond_grace} />
            <Question faq={FAQ.what_do_the_senior_subordinated_and_junior_loans_mean} />
            <Question faq={FAQ.where_can_i_find_a_list_of_loans} />
          </div>
        </section>
        <section id={FAQ_SECTIONS_LIST[5]}>
          <h2 className="f-24 f-500">Verification</h2>
          <div>
            <Question faq={FAQ.why_do_i_need_to_verify} />
            <Question faq={FAQ.how_can_i_start_the_verification_process} />
            <Question faq={FAQ.which_data_will_be_stored_during_verification_with_veriff} />
          </div>
        </section>
      </div>
    </div>
  );
};

export default HelpPage;
