import axios from '../../axios-api';
import Pathes from '../../helpers/pathes';
import { getMessage, getQuery } from '../../helpers/utils';
import {
  GET_LOAN_DETAIL,
  GET_LOAN_SWAP_PARTNERS,
  GET_LOAN_TERMS,
  GET_LOANS_LIST,
  GET_PAYMENT_HISTORY,
} from '../types/loanTypes';

export const getLoansListRequest = () => ({ type: GET_LOANS_LIST.REQUEST });
export const getLoansListSuccess = payload => ({
  type: GET_LOANS_LIST.SUCCESS,
  payload,
});
export const getLoansListFailure = error => ({
  type: GET_LOANS_LIST.FAILURE,
  error,
});

export const getLoanSwapPartnersRequest = () => ({
  type: GET_LOAN_SWAP_PARTNERS.REQUEST,
});
export const getLoanSwapPartnersSuccess = payload => ({
  type: GET_LOAN_SWAP_PARTNERS.SUCCESS,
  payload,
});
export const getLoanSwapPartnersFailure = error => ({
  type: GET_LOAN_SWAP_PARTNERS.FAILURE,
  error,
});

export const getLoanDetailRequest = () => ({ type: GET_LOAN_DETAIL.REQUEST });
export const getLoanDetailSuccess = payload => ({
  type: GET_LOAN_DETAIL.SUCCESS,
  payload,
});
export const getLoanDetailFailure = error => ({
  type: GET_LOAN_DETAIL.FAILURE,
  error,
});

export const getLoanTermsRequest = () => ({ type: GET_LOAN_TERMS.REQUEST });
export const getLoanTermsSuccess = payload => ({
  type: GET_LOAN_TERMS.SUCCESS,
  payload,
});
export const getLoanTermsFailure = error => ({
  type: GET_LOAN_TERMS.FAILURE,
  error,
});

export const getPaymentHistoryRequest = () => ({
  type: GET_PAYMENT_HISTORY.REQUEST,
});
export const getPaymentHistorySuccess = payload => ({
  type: GET_PAYMENT_HISTORY.SUCCESS,
  payload,
});
export const getPaymentHistoryFailure = error => ({
  type: GET_PAYMENT_HISTORY.FAILURE,
  error,
});

export const getLoansList = (params, isNext) => {
  return (dispatch, getState) => {
    dispatch(getLoansListRequest());
    return axios
      .get(Pathes.Loans.list + getQuery(params))
      .then(response => {
        const { status, data } = response;
        const message = getMessage(data);
        if (status === 200) {
          const prevData = getState().loanStore.loansList.data;
          if (!isNext || !prevData) {
            dispatch(getLoansListSuccess(data));
            return { data, success: true, message };
          }

          const updatedData = {
            total_count: data.total_count,
            total_pages: data.total_pages,
            list: [...prevData.list, ...data.list],
          };

          dispatch(getLoansListSuccess(updatedData));
          return { ...updatedData, success: true, message };
        }
        throw new Error(message);
      })
      .catch(e => dispatch(getLoansListFailure(e.message)));
  };
};

export const getLoanDetail = id => {
  return dispatch => {
    dispatch(getLoanDetailRequest());
    return axios
      .get(Pathes.Loans.detail(id))
      .then(response => {
        const { status, data } = response;
        const message = getMessage(data);
        if (status === 200) {
          dispatch(getLoanDetailSuccess(data));
          return { data, success: true, message };
        }

        throw new Error(message);
      })
      .catch(e => dispatch(getLoanDetailFailure(e.message)));
  };
};

export const getLoanSwapPartners = (params, isNext) => {
  return (dispatch, getState) => {
    dispatch(getLoanSwapPartnersRequest());
    return axios
      .get(Pathes.Loans.swapPartners + getQuery(params))
      .then(response => {
        const { status, data } = response;
        const message = getMessage(data);
        if (status === 200) {
          const prevData = getState().loanStore.swapPartners.data;
          if (!isNext || !prevData) {
            dispatch(getLoanSwapPartnersSuccess(data));
            return { data, success: true, message };
          }

          const updatedData = {
            total_count: data.total_count,
            total_pages: data.total_pages,
            list: [...prevData.list, ...data.list],
          };

          dispatch(getLoanSwapPartnersSuccess(updatedData));
          return { data: updatedData, success: true, message };
        }
        throw new Error(message);
      })
      .catch(e => dispatch(getLoanSwapPartnersFailure(e.message)));
  };
};

export const getLoanTerms = () => {
  return dispatch => {
    dispatch(getLoanTermsRequest());
    return axios
      .get(Pathes.Loans.loanTerms)
      .then(response => {
        const { status, data } = response;
        const message = getMessage(data);
        if (status === 200) {
          const groups = data.reduce((acc, item) => {
            acc[item.id] = { ...item };
            return acc;
          }, {});

          dispatch(getLoanTermsSuccess(groups));
          return { data: groups, success: true, message };
        }
        throw new Error(message);
      })
      .catch(e => dispatch(getLoanTermsFailure(e.message)));
  };
};

export const getPaymentHistory = (id, params, isNext) => {
  return (dispatch, getState) => {
    dispatch(getPaymentHistoryRequest());
    return axios
      .get(Pathes.Loans.paymentHistory(id) + getQuery(params))
      .then(response => {
        const { status, data } = response;
        const message = getMessage(data);
        if (status === 200) {
          const prevData = getState().loanStore.paymentHistory.data;
          if (!isNext || !prevData) {
            dispatch(getPaymentHistorySuccess(data));
            return { data, success: true, message };
          }

          const updatedData = {
            total_count: data.total_count,
            total_pages: data.total_pages,
            list: [...prevData.list, ...data.list],
          };

          dispatch(getPaymentHistorySuccess(updatedData));
          return { data: updatedData, success: true, message };
        }
        throw new Error(message);
      })
      .catch(e => dispatch(getPaymentHistoryFailure(e.message)));
  };
};
