import * as React from 'react';
import { connect } from 'react-redux';
import { resetPassword, verifyToken } from '../../store/actions/userActions';
import RecoverPasswordForm from '../../components/Forms/RecoverPasswordForm';
import Notify from '../../components/Notification';
import './index.scss';

class RecoverPassword extends React.Component {
  token = null;

  componentDidMount() {
    this.getToken();
  }

  getToken = async () => {
    const { token } = this.props;
    this.token = await verifyToken({ token });
  };

  onSubmit = values => {
    if (this.token) {
      this.props.resetPassword({
        password: values.password1,
        token: this.token,
      });
    } else {
      Notify.error({ text: 'Verification URL is expired or not valid' });
    }
  };

  render() {
    return (
      <div className="recover-password">
        <div className="recover-password__inner">
          <RecoverPasswordForm onSubmit={this.onSubmit} />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  resetPassword: data => dispatch(resetPassword(data)),
});

export default connect(null, mapDispatchToProps)(RecoverPassword);
