import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import { getPaymentSchedule } from '../../../store/actions/paymentActions';
import { getDeadlineDetails } from '../../../store/services/paymentServices';
import { Table } from './table';
import s from './index.module.scss';

export const RepaymentSchedule = () => {
  const dispatch = useDispatch();

  const [params, setParams] = useState({
    page: 1,
    limit: 20,
  });

  const [rowDetails, setRowDetails] = useState({});

  const { data } = useSelector(state => state.paymentStore.paymentSchedule);

  useEffect(() => {
    dispatch(getPaymentSchedule(params));
  }, []);

  const getNext = totalPage => {
    const { page, limit } = params;
    if (page < totalPage) {
      const nexPage = page + 1;
      setParams({ ...params, page: nexPage });
      dispatch(getPaymentSchedule({ page: nexPage, limit }));
    }
  };

  const handleToggle = async deadline => {
    const res = await getDeadlineDetails(deadline);
    if (res && res.success) {
      setRowDetails({ ...rowDetails, [deadline]: res.data });
    }
  };

  return (
    <div className={s.wrapper}>
      <h2 className={classnames(s.title, 'f-500 f-26')}>Repayment Schedule</h2>
      <div className={s.content}>
        {!data || (data && !data.list.length) ? (
          <div className={classnames(s.empty, 'f-16')}>No repayments.</div>
        ) : (
          <>
            <Table data={data} rowDetails={rowDetails} onToggleRow={handleToggle} />
            {params.page < data.total_pages && (
              <div className={s.more}>
                <button type="button" onClick={() => getNext(data.total_pages)}>
                  Load more
                </button>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};
