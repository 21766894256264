import React from 'react';
import classnames from 'classnames';
import { MyLink } from '../../components/MyLink';
import { ReactComponent as XIcon } from '../../assets/icons/icon_close_grey_24.svg';
import s from './index.module.scss';

export const FileName = ({ file, className, onRemove }) => {
  const href = file.file || '';

  const content = (
    <>
      <div className="tl" title={file.name}>
        {file.name}
      </div>

      <div className={s.controls}>
        {onRemove && (
          <button
            type="button"
            onClick={e => {
              e.preventDefault();
              onRemove();
            }}
            className={s.remove}
          >
            <XIcon />
          </button>
        )}
      </div>
    </>
  );

  return href ? (
    <MyLink
      href={href}
      className={classnames(s.file, className)}
      underline
      isExternal
      style={{ cursor: 'pointer' }}
    >
      {content}
    </MyLink>
  ) : (
    <div className={classnames(s.file, className)}>{content}</div>
  );
};
