import React, { useEffect } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { prettyFloatMoney } from '../../helpers/utils';
import { DATE_FORMAT_DD_MM_YYYY } from '../../helpers/common';
import Preloader from '../../components/UI/Preloader';
import { getLoanDetail, getPaymentHistory } from '../../store/actions/loanActions';
import { LoanDetails } from './components/LoanDetails';
import { FinancingAgreements } from './components/FinancingAgreements';
import { SecurityDeposit } from './components/SecurityDeposit';
import { RepaymentHistory } from './components/RepaymentHistory';
import './index.scss';

const LoanInfo = ({ loan }) => {
  const dispatch = useDispatch();
  const { loanDetail } = useSelector(state => state.loanStore);
  const { amounts } = loan;
  const { data, loading } = loanDetail;

  useEffect(() => {
    dispatch(getLoanDetail(loan.fundraising_id));
    dispatch(getPaymentHistory(loan.fundraising_id, { page: 1, limit: 100 }));
  }, []);

  return (
    <div className="loan-info">
      {loading ? (
        <Preloader className="loan-info__preloader" />
      ) : (
        <>
          <div className="loan-info__header">
            <div>
              <p className="f-16">Started</p>
              <p className="f-22 f-500">
                {data ? moment(data.started).format(DATE_FORMAT_DD_MM_YYYY) : '-'}
              </p>
            </div>

            <div>
              <p className="f-16">End date</p>
              <p className="f-22 f-500">
                {data ? moment(data.end_of_grace_period).format(DATE_FORMAT_DD_MM_YYYY) : '-'}
              </p>
            </div>

            <div>
              <p className="f-16">Loan total</p>
              <p className="f-22 f-500 tl">{prettyFloatMoney(amounts.total_loan_amount)}</p>
            </div>

            <div>
              <p className="f-16">Outstanding</p>
              <p className="f-22 f-500 tl">{prettyFloatMoney(amounts.outstanding)}</p>
            </div>
          </div>

          {data && (
            <>
              <LoanDetails loans={data.loans} />
              <FinancingAgreements fundraisingId={loan.fundraising_id} data={data} />
              <SecurityDeposit insurance={data.insurance} />
              <RepaymentHistory />
            </>
          )}
        </>
      )}
    </div>
  );
};

export default LoanInfo;
