import axios from '../../axios-api';
import { getMessage } from '../../helpers/utils';
import Pathes from '../../helpers/pathes';
import { GET_MINI_FAQ } from '../types/helpTypes';

const getMiniFaqSuccess = payload => ({ type: GET_MINI_FAQ.SUCCESS, payload });

export const getMiniFaq = source => {
  return dispatch => {
    return axios
      .get(Pathes.Help.section(source))
      .then(response => {
        const { status, data } = response;
        const message = getMessage(data);
        if (status === 200) {
          dispatch(getMiniFaqSuccess(data));
          return { data, success: true };
        }

        throw new Error(message);
      })
      .catch(e => ({ error: e.message, success: false }));
  };
};
