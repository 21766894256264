export const EXCLUDED_COUNTRIES = [
  'AF',
  'AS',
  'BS',
  'BW',
  'KP',
  'ET',
  'GH',
  'GU',
  'IR',
  'IQ',
  'LY',
  'PK',
  'PA',
  'PR',
  'WS',
  'SA',
  'LK',
  'SY',
  'TT',
  'TN',
  'VI',
  'YE',
];
