import * as React from 'react';
import * as classnames from 'classnames';
import AvatarEditor from 'react-avatar-editor';
import { dataURItoBlob } from '../../helpers/utils';
import { RotateIcon, XIcon } from '../UI/Icons';
import './index.scss';

export const cropAvatar = editorRef => {
  try {
    const canvasScaled = editorRef.getImageScaledToCanvas().toDataURL('image/jpeg');
    const imageBlob = dataURItoBlob(canvasScaled);
    const formData = new FormData();
    formData.append('file', imageBlob, `avatar_${new Date().toISOString()}.jpg`);
    return formData;
  } catch (e) {
    console.warn('Could not resolve image');
    return null;
  }
};

class AvatarEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scale: 1,
      rotate: 0,
    };
  }

  rotate = () => {
    this.setState(prevState => ({
      ...prevState,
      rotate: prevState.rotate + 90,
    }));
  };

  setEditorRef = editor => {
    this.props.setFieldValue('editorRef', editor);
  };

  render() {
    const { src, className, name, size = 250, setFieldValue, error } = this.props;
    return (
      <div
        className={classnames('avatar-edit__container', error && 'avatar-edit__error', className)}
      >
        <AvatarEditor
          ref={this.setEditorRef}
          image={src}
          width={size}
          height={size}
          border={0}
          color={[255, 255, 255, 0.6]} // RGBA
          scale={this.state.scale}
          rotate={this.state.rotate}
          className="avatar-edit"
        />
        <RotateIcon onClick={this.rotate} className="avatar-edit__rotate" />
        <button
          type="button"
          onClick={() => setFieldValue(name, '')}
          className="avatar-edit__remove"
        >
          <XIcon className="avatar-edit__remove-icon" />
        </button>
        <div />
      </div>
    );
  }
}

export default AvatarEdit;
