import React from 'react';
import classnames from 'classnames';
import { ReactComponent as Icon } from '../../assets/icon_pricing.svg';
import s from './index.module.scss';

export const StandardPricing = () => {
  return (
    <div className={s.wrapper}>
      <div className={classnames(s.header, 'f-16 f-500')}>
        <Icon /> Pricing
      </div>
      <div className={classnames(s.plan, 'f-20 f-500')}>for Standard Plan</div>
      <table className={s.table_standard}>
        <tbody>
          <tr>
            <td />
            <td>Monthly repayment</td>
            <td>Flat fee</td>
            <td>
              Effective IRR <br />
              (for reference use)
            </td>
          </tr>
          <tr>
            <td>Low risk</td>
            <td>2%</td>
            <td>9%</td>
            <td>20,8%</td>
          </tr>
          <tr>
            <td>Medium risk</td>
            <td>4%</td>
            <td>9%</td>
            <td>22%</td>
          </tr>
          <tr>
            <td>High risk</td>
            <td>6%</td>
            <td>9%</td>
            <td>23,4%</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
