import * as React from 'react';
import * as classnames from 'classnames';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import './index.scss';

export class PhoneNumberField extends React.Component {
  componentDidMount() {
    const phoneInputElement = document.querySelector(`.${this.props.containerID || 'PhoneInput'}`);
    if (phoneInputElement) {
      phoneInputElement.appendChild(this.getLabel());
    }
  }

  componentDidUpdate(prevProps) {
    const { formikBag, value, isValid } = this.props;
    if (prevProps.value !== value) {
      const validationPass = !!(value && isValidPhoneNumber(value));
      if (isValid !== validationPass) {
        formikBag.setFieldValue('is_valid_phone', validationPass);
      }
    }
  }

  getLabel = () => {
    const node = document.createElement('label');
    node.innerText = this.props.label;
    node.setAttribute('class', 'phone-number-field__label');
    node.setAttribute('htmlFor', this.props.name);
    return node;
  };

  render() {
    const {
      error,
      name,
      value,
      onChange,
      countryCode = 'US',
      onClear,
      disabled,
      className,
      containerID,
    } = this.props;
    return (
      <div
        className={classnames('phone-number-field', error && 'phone-number-field_error', className)}
      >
        <div className="phone-number-field__group">
          <PhoneInput
            id={name}
            name={name}
            international
            value={value}
            placeholder=" "
            disabled={disabled}
            defaultCountry={countryCode}
            onChange={onChange}
            className={containerID || ''}
            error={
              value
                ? isValidPhoneNumber(value)
                  ? undefined
                  : 'Invalid phone number'
                : 'Phone number required'
            }
          />
          {onClear && (
            <svg
              onClick={onClear}
              className={classnames(
                'phone-number-field__clear',
                !value && 'phone-number-field__clear-hidden'
              )}
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8 0C3.57333 0 0 3.57333 0 8C0 12.4267 3.57333 16 8 16C12.4267 16 16 12.4267 16 8C16 3.57333 12.4267 0 8 0ZM10.3111 11.3778C9.54667 10.6133 8.76444 9.83111 8 9.06667C7.23556 9.83111 6.45333 10.6133 5.68889 11.3778C4.99556 12.0711 3.94667 11.0044 4.62222 10.3111C5.38667 9.54667 6.16889 8.76444 6.93333 8C6.16889 7.23556 5.38667 6.45333 4.62222 5.68889C3.92889 4.99556 4.99556 3.94667 5.68889 4.62222C6.45333 5.38667 7.23556 6.16889 8 6.93333C8.76444 6.16889 9.54667 5.38667 10.3111 4.62222C11.0044 3.92889 12.0533 4.99556 11.3778 5.68889C10.6133 6.45333 9.83111 7.23556 9.06667 8C9.83111 8.76444 10.6133 9.54667 11.3778 10.3111C12.0533 10.9867 10.9867 12.0533 10.3111 11.3778Z"
                fill="#D8D6DA"
              />
            </svg>
          )}
        </div>
        <div
          className={classnames(
            'phone-number-field__error',
            error && 'phone-number-field__error_visible'
          )}
        >
          {error}
        </div>
      </div>
    );
  }
}

export default PhoneNumberField;
