import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PaymentModule from '../../containers/PaymentModule';
import { getUserProfile } from '../../store/actions/profileActions';
import WarningBlock from '../../components/UI/WarningBlock';
import { ROLES } from '../../helpers/constants';
import s from './index.module.scss';

const PaymentsAddFundsPage = () => {
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.userStore);

  useEffect(() => {
    dispatch(getUserProfile());
  }, []);

  const canAddCash = user.role === ROLES.founder;

  return (
    <div className={s.wrapper}>
      {!canAddCash && (
        <WarningBlock withBorder className={s.message}>
          <div className="f-16 f-400">
            Add funds is avaialable only to the company administrator.
          </div>
        </WarningBlock>
      )}
      <PaymentModule disabled={!canAddCash} />
    </div>
  );
};

export default PaymentsAddFundsPage;
