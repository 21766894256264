import * as React from 'react';
import * as classnames from 'classnames';
import { MyLink } from '../../MyLink';
import './index.scss';

export const RoundLink = ({
  label,
  path,
  fillBackground,
  fullWidth,
  style,
  className,
  isExternal,
  ...other
}) => (
  <MyLink
    href={path}
    className={classnames(
      'round-link',
      'hov',
      fillBackground && 'round-link__filled',
      fullWidth && 'round-link__fullwidth',
      className
    )}
    style={style}
    isExternal={isExternal}
  >
    <span className="round-link__label f-500" {...other}>
      {label}
    </span>
  </MyLink>
);
