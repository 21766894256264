import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ProfileStartupForm from '../../components/Forms/ProfileStartupForm';
import { uploadImage } from '../../store/services/commonServices';
import { updateCompany, updatePromo } from '../../store/services/userServices';
import { getUserProfile } from '../../store/actions/profileActions';
import { uploadFile } from '../../store/actions/commonActions';

const ProfileStartupModal = ({ onClose }) => {
  const { company } = useSelector(state => state.profileStore.profile.data);
  const { userLocation } = useSelector(state => state.userStore);
  const dispatch = useDispatch();

  const onSubmit = async values => {
    const payload = {
      name: values.name,
      country: values.country,
      country_code: values.country_code,
      sectors: values.sectors.map(sector => sector.id),
      foundation_date: values.foundation_year ? `${values.foundation_year}-01-01` : null,
      site: values.site,
      description: values.description,
      ownership_structure: values.ownership_structure,
      management_board: values.management_board,
      company_registry_exact: values.companyRegistry[0]?.id || null,
      full_name: values.full_name,
      address: values.address,
      email: values.email,
      registration_number: values.registration_number,
      phone_number: values.phone_number,
    };

    if (values.logo) {
      const result = await uploadImage(values.logo);
      if (result && result.success) {
        payload.image = result.data.id;
      }
    } else if (!values.logo && values.logoURL) {
      payload.image = company.image.id;
    } else {
      payload.image = null;
    }

    if (values.promoImage) {
      const result = await uploadImage(values.promoImage);
      if (result && result.success) {
        const promo = await updatePromo({ image: result.data.id });
        if (promo && promo.success) {
          dispatch(getUserProfile());
          onClose();
        }
      }
    } else if (!values.promoImage && !values.promoImageURL) {
      const promo = await updatePromo({ image: null });
      if (promo && promo.success) {
        dispatch(getUserProfile());
        onClose();
      }
    }

    if (values.companyRegistry[0] && !values.companyRegistry[0].id) {
      const result = await dispatch(uploadFile(values.companyRegistry[0]));
      if (result && result.success) {
        payload.company_registry_exact = result.id;
      }
    }

    const res = await updateCompany(payload);
    if (res && res.success) {
      dispatch(getUserProfile());
      onClose();
    }
  };

  return (
    <ProfileStartupForm
      company={company}
      location={userLocation}
      onSubmit={onSubmit}
      onCancel={onClose}
    />
  );
};

export default ProfileStartupModal;
