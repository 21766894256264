import React from 'react';
import * as classnames from 'classnames';
import { Link, NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Logo from '../UI/Icons/Logo';
import { NAV_ROUTES } from '../Navigation';
import UserIcon from '../UI/Icons/UserIcon';
import { getNameInitials } from '../../helpers/utils';
import './index.scss';

const TabletNavigation = () => {
  const [showAvatar, setShow] = React.useState(true);
  const user = useSelector(state => state.userStore.user);
  const isParticipating = useSelector(state => state.fundraisingStore.isParticipating);

  return (
    <div className="tablet-navigation__wrap">
      <div className="container-inner">
        <div className="tablet-navigation">
          <Link to="/" className="tablet-navigation__logo">
            <Logo />
          </Link>
          <div className="tablet-navigation__links">
            {NAV_ROUTES.map(route => (
              <NavLink
                key={route.label}
                to={route.path}
                className="tablet-navigation__link f-16 f-500"
                activeClassName="tablet-navigation__link-active"
              >
                {route.label}
              </NavLink>
            ))}
          </div>
          {user && (
            <Link className="tablet-navigation__user" to="/profile">
              {showAvatar && user.photo ? (
                <div
                  className={classnames(
                    'tablet-navigation__user-avatar',
                    isParticipating && 'active'
                  )}
                >
                  <img
                    src={user.photo.file}
                    alt={getNameInitials(user.first_name, user.last_name)}
                    onError={() => setShow(false)}
                  />
                </div>
              ) : (
                <UserIcon
                  className="tablet-navigation__user-icon"
                  strokeColor={isParticipating && '#FFD200'}
                />
              )}
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default TabletNavigation;
