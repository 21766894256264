import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import Decimal from 'decimal.js-light';
import { prettyFloatMoney } from '../../../../helpers/utils';
import { getStripeCalculatedAmount } from '../../../../store/services/stripeServices';
import s from '../index.module.scss';

export const TotalPaymentAmount = ({ amount, method, setCommission }) => {
  const [total, setTotal] = useState(amount);

  useEffect(() => {
    calculate(amount, method);
  }, [amount, method]);

  const calculate = () => {
    getStripeCalculatedAmount({ amount, payment_method: method }).then(res => {
      if (res && res.success) {
        setTotal(res.data.amount);
        setCommission(new Decimal(res.data.amount).sub(amount).toNumber());
      }
    });
  };

  return (
    <div className={classnames(s.total)}>
      <div className={classnames(s.total_label, 'f-16-24 f-400')}>Total payment amount</div>
      <div className={classnames(s.total_amount, 'f-42 f-500')}>{prettyFloatMoney(total)}</div>
    </div>
  );
};
