import React from 'react';
import Logo from '../../components/UI/Icons/Logo';
import { CONTACT_EMAIL } from '../../helpers/constants';
import './index.scss';

const TermsOfUsePage = () => {
  return (
    <div className="terms-of-use-page">
      <div className="terms-of-use-page__menu">
        <a
          href="https://founder-promo.scrambleup.com/"
          className="terms-of-use-page__logo"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Logo />
        </a>
      </div>
      <div className="terms-of-use-page__content">
        <div className="container-inner">
          <h1 className="terms-of-use-page__title f-26 f-500">
            SCRAMBLE GENERAL TERMS OF USING THE PLATFORM
          </h1>

          <h5 className="terms-of-use-page__subtitle f-20 f-500">
            1. Applicability of the Terms of Use
          </h5>
          <ul className="terms-of-use-page__list">
            <li className="terms-of-use-page__item f-16 f-400" data-index="1.1.">
              These Scramble General Terms of Using the Platform (hereinafter the{' '}
              <b>Terms of Use</b>) regulate the legal relations between the registered users
              (hereinafter the <b>User</b>) of the website{' '}
              <a href="https://scrambleup.com">
                <b>www.scrambleup.com</b>
              </a>{' '}
              and its subpages (hereinafter the <b>Platform</b>) and <b>Scramble OÜ</b> (registry
              code 14991448, located at Harju maakond, Tallinn, Kesklinna linnaosa, Pärnu mnt 22,
              10141, e-mail {CONTACT_EMAIL}; hereinafter <b>Scramble</b>), arising from the usage of
              the Platform.
            </li>
            <li className="terms-of-use-page__item f-16 f-400" data-index="1.2.">
              By registering a user account with the Platform, the User agrees to the terms and
              conditions of these Terms of Use and thereby an agreement under the Terms of Use is
              concluded between the User and Scramble.
            </li>
            <li className="terms-of-use-page__item f-16 f-400" data-index="1.3.">
              Scramble reserves the right to amend the Terms of Use or include additional
              conditions. These amendments and additions shall enter into force from the moment the
              User agrees to the new version of Terms of Use via the Platform.
            </li>
            <li className="terms-of-use-page__item f-16 f-400" data-index="1.4.">
              By starting to use the Platform, the User confirms to have read and understood the
              Terms of Use and agrees to comply with them.
            </li>
          </ul>

          <h5 className="terms-of-use-page__subtitle f-20 f-500">
            2. Service Provided by Scramble and Prices
          </h5>
          <ul className="terms-of-use-page__list">
            <li className="terms-of-use-page__item f-16 f-400" data-index="2.1.">
              Scramble shall enable the registered Users of the Platform to use the Platform for its
              intended purpose, first and foremost, enter and/or become acquainted with financing
              proposals (fundraising projects for start-up businesses), make declarations of intent
              and conclude transactions (conclude Financing agreements and grant guarantees and
              sureties). Scramble has the sole right to change the functionality of the Platform and
              the Terms of Use without advance notice, including limiting existing functionality or
              adding additional functionality to the Platform.
            </li>
            <li className="terms-of-use-page__item f-16 f-400" data-index="2.2.">
              Any and all expenses and fees related to the use of the Platform shall be paid by the
              User according to these Terms of Use and the price list of the Platform (hereinafter
              the <b>Price List</b>) and Scramble shall automatically debit (without an additional
              authorization from the User) the expenses and fees from the User’s Virtual Account (as
              defined in Clause 4.1), for the transaction made in relation to which Scramble has the
              right to request reimbursement of expenses or payment of a fee.
            </li>
            <li className="terms-of-use-page__item f-16 f-400" data-index="2.3.">
              Scramble is entitled to unilaterally amend the Price List at any time, including to
              establish new fees related to the use of the Platform. The valid Price List is
              available on the Platform. Changes to the Price List are notified to the Users at
              least 10 (ten) days in advance through the Platform.
            </li>
          </ul>

          <h5 className="terms-of-use-page__subtitle f-20 f-500">3. User Account</h5>
          <ul className="terms-of-use-page__list">
            <li className="terms-of-use-page__item f-16 f-400" data-index="3.1.">
              In order to use the Platform a person is required to register a user account in their
              name (hereinafter the <b>User Account</b>). Only persons who passed Scramble’s User
              due diligence process to the extent that Scramble deems necessary can register a User
              Account in their name. A person applying for registration of a User Account is
              required to submit to Scramble any and all information that Scramble requests.
              Scramble has the sole discretion to open or refuse to open a User Account without
              giving any reasons for doing so. Scramble may repeat the due diligence checks as it
              sees fit and the User must provide any and all information required by Scramble for
              such purpose.
            </li>
            <li className="terms-of-use-page__item f-16 f-400" data-index="3.2.">
              The Platform can be used via two different types of User Accounts – Founder and
              Investor (each as defined in Clauses 3.4 and 3.5). Each type of User Account enables
              the User to use the Platform in different set of rights and abilities as allowed by
              the designated functionalities of the Platform.
            </li>
            <li className="terms-of-use-page__item f-16 f-400" data-index="3.3.">
              Upon registering a User Account, each person chooses the type of User Account they
              wish to register and enters and/or imports from an available third party their contact
              information and other data required by Scramble into the respective application form
              of the Platform.
            </li>
            <li className="terms-of-use-page__item f-16 f-400" data-index="3.4.">
              The User Account of a founder can only be registered by a natural person (hereinafter
              the <b>Founder</b>). It enables the Founder to enter into Surety Agreements (as
              defined in Clause 8.1) to secure obligations of their business(es), which are
              incorporated and validly existing legal persons that are linked to the Founders, i.e.
              in which a Founder has a direct or indirect shareholding of at least 50% (hereinafter
              the <b>Business</b>). It enables the Business to use the Platform for fundraising,
              including to enter into Financing Agreements (as defined in Clause 5.2) and provide
              Guarantees (as defined in Clause 7.1). When registering the User Account, the Founder
              shall present Scramble with the relevant registration certificate, register extract or
              similar document as proof of the Business’s legal representatives and its link to the
              Founder.
            </li>
            <li className="terms-of-use-page__item f-16 f-400" data-index="3.5.">
              The User Account of an investor can be registered by a natural person (hereinafter the{' '}
              <b>Investor</b>) and it enables the Investor to lend funds to Founders with associated
              Business(es).
            </li>
            <li className="terms-of-use-page__item f-16 f-400" data-index="3.6.">
              The User on whose behalf the User Account has been created shall be responsible for
              the rights and obligations related to the User Account. Among other, the User shall be
              a party to the transactions concluded via the User Account.
            </li>
            <li className="terms-of-use-page__item f-16 f-400" data-index="3.7.">
              Scramble shall have the right to limit the rights of each User (separately or
              collectively) using the Platform via different types of User Accounts in terms of
              specific usage rights, including limitation of access and/or usage rights with respect
              to certain parts of the Platform.
            </li>
            <li className="terms-of-use-page__item f-16 f-400" data-index="3.8.">
              To enter the Platform, User is required to enter their username and password. In order
              to use the Platform, Scramble may unilaterally request the User to identify themselves
              via electronic identification (e.g., ID card or Mobile-ID), bank link, identity
              verification platform operated by a third person or any other identification method
              acceptable to Scramble. To submit declarations of intent and give approvals that bear
              legal consequences, Scramble may request the verification of such declarations and
              approvals by the User’s electronic signature or any other method of authentication.
            </li>
          </ul>

          <h5 className="terms-of-use-page__subtitle f-20 f-500">4. Virtual Account</h5>
          <ul className="terms-of-use-page__list">
            <li className="terms-of-use-page__item f-16 f-400" data-index="4.1.">
              After registering a User Account, the Platform creates (as a part of the User Account)
              an individual virtual account for the User for performing transactions via the
              Platform and for keeping track of financial obligations and claims, which have arisen
              on the basis of the Terms of Use and/or as a result of the transactions concluded on
              the Platform (hereinafter the <b>Virtual Account</b>
              ).
            </li>
            <li className="terms-of-use-page__item f-16 f-400" data-index="4.2.">
              To be able to use the Virtual Account, the User shall provide the Platform with any
              and all accurate information required by Scramble in the respective application form
              of the Platform. Scramble preserves the right to unilaterally prohibit and prevent the
              use of the Virtual Account if there is any erroneous, incomplete or misleading
              information provided by a User or for any other reason whatsoever with or without
              assigning the reason.
            </li>
            <li className="terms-of-use-page__item f-16 f-400" data-index="4.3.">
              The Virtual Account shall reflect the amount of funds transferred by the User to
              Scramble for the purpose of performing transactional obligations entered into via the
              Platform and the funds received from other Users as a result of transactions concluded
              by the User via the Platform.
            </li>
            <li className="terms-of-use-page__item f-16 f-400" data-index="4.4.">
              The User may transfer funds to Scramble only from a payment account registered to the
              name of the User in a licensed credit institution, registered or operating in a state
              which is a Contracting Party to the EEA Agreement or in a state where equivalent money
              laundering and terrorist financing prevention measures are applied and which is
              accepted by Scramble. Scramble shall have the right to request documents, which
              confirm compliance of the payment account with the conditions referred to herein. The
              User is required to indicate the individual reference number provided by the Platform
              for the User in the payment order.
            </li>
            <li className="terms-of-use-page__item f-16 f-400" data-index="4.5.">
              If the payment account from which a transfer was made to Scramble does not belong to
              the respective User or does not meet any other conditions set forth in Clause 4.4,
              also if the reference number set forth in Clause 4.4 is not indicated in the payment
              order, Scramble shall have the right to transfer the amount back to the account from
              which the payment was made and request compensation from the User for expenses related
              to returning the payment. Scramble shall have the same right in case Scramble suspects
              that the funds are related to money laundering or any other unlawful activity.
            </li>
            <li className="terms-of-use-page__item f-16 f-400" data-index="4.6.">
              The amount of the payment made by the User to Scramble in accordance with Clause 4.4
              is registered in the Virtual Account and the User shall use it in order to perform
              obligations arising from transactions concluded via the Platform or the Terms of Use
              to the parties to the transactions made via the Platform as well as to Scramble in
              accordance with Section 11.
            </li>
            <li className="terms-of-use-page__item f-16 f-400" data-index="4.7.">
              Scramble shall make payments to the User only to the payment account disclosed to
              Scramble by the User. The User has the right to request from Scramble to make the
              payments only to the payment account registered to the name of the User in a licensed
              credit institution, which is registered or operating in a state which is a Contracting
              Party to the EEA Agreement or in a state where equivalent money laundering and
              terrorist financing prevention measures are applied and is accepted by Scramble.
              Scramble shall have the right to refuse to make a payment to a payment account that
              does not belong to the User or does not meet any other condition set forth in this
              Clause. Scramble shall also have the right to request documents, which confirm
              compliance of the payment account with the conditions referred to herein.
            </li>
            <li className="terms-of-use-page__item f-16 f-400" data-index="4.8.">
              The User shall cover the transfer expenses of payments made by the User to Scramble.
              Expenses related to the payments made by Scramble to the User and other expenses
              related to the payments made for the purposes of execution of transactions and
              activities by the User shall be reimbursed to Scramble by the User according to the
              Price List established by Scramble.
            </li>
            <li className="terms-of-use-page__item f-16 f-400" data-index="4.9.">
              As a mandatary, Scramble shall keep the funds transferred to it by the Users in the
              bank account, which Scramble has opened on its name solely for this purpose in a
              licensed credit or payment institution, which is registered or operating in state,
              which is a Contracting Party to the EEA Agreement. The funds on Scramble’s respective
              bank account are excluded from Scramble’s assets. Scramble shall keep the funds of the
              Users separately from its own funds. The funds transferred by the User to the bank
              account of Scramble shall belong to the User to the extent of the positive balance of
              the Virtual Account. Scramble shall not calculate interest on the funds of the User
              kept with Scramble and no such interest shall be payable to the User.
            </li>
            <li className="terms-of-use-page__item f-16 f-400" data-index="4.10.">
              Scramble shall be entitled to withhold payment transfer fees due by the User to
              Scramble in accordance with the Price List from the funds on the Virtual Account.
              Scramble shall be entitled to make deductions from the Virtual Account in order to
              ensure that the User’s obligations arising from the Terms of Use are performed,
              including for the payment of Scramble’s commission fees.
            </li>
          </ul>

          <h5 className="terms-of-use-page__subtitle f-20 f-500">5. Investing</h5>
          <ul className="terms-of-use-page__list">
            <li className="terms-of-use-page__item f-16 f-400" data-index="5.1.">
              In order to invest funds on the Platform the Investor must set up and activate the
              investing function on the Platform. The investing function submits automatic
              declarations of intent to accept the businesses’ offers to issue loans to the
              businesses meeting the criteria determined by the Investor.
            </li>
            <li className="terms-of-use-page__item f-16 f-400" data-index="5.2.">
              Upon setting up the investing function the Investor shall determine the criteria of
              their investment strategy as made available in the investment function on the Platform
              (including criteria regarding the businesses and their Founders) and approve the
              standard terms and conditions to the Financing agreements concluded via the investing
              function (hereinafter the <b>Financing Agreement(s)</b>).
            </li>
            <li className="terms-of-use-page__item f-16 f-400" data-index="5.3.">
              Scramble may at its sole discretion remove or include criteria to be determined by the
              Investor in the investing function. If Scramble adds an additional criterion for a
              specific investment opportunity or permanently to the function, the Investor may
              change existing settings of the investing function and make a choice based on the
              additional criterion.
            </li>
            <li className="terms-of-use-page__item f-16 f-400" data-index="5.4.">
              By activating the investing function, the Investor confirms:
              <ul className="terms-of-use-page__sublist">
                <li className="terms-of-use-page__subitem f-16 f-400" data-index="5.4.1">
                  that they have fully understand the operation principles of the investing function
                  of the Platform and the potential consequences associated with its activation;
                </li>
                <li className="terms-of-use-page__subitem f-16 f-400" data-index="5.4.2">
                  that they have read and fully understand this Section 5 and shall comply with it;
                </li>
                <li className="terms-of-use-page__subitem f-16 f-400" data-index="5.4.3">
                  that they have the necessary experience and knowledge to understand the risks
                  involved, or are able to take advice from a relevant qualified professional or
                  advisor to assist in understanding the risks involved;
                </li>
                <li className="terms-of-use-page__subitem f-16 f-400" data-index="5.4.4">
                  that they have carefully considered the risks involved in lending money through
                  the Platform and that their participation in any Financing Agreement is entirely
                  at their own risk;
                </li>
                <li className="terms-of-use-page__subitem f-16 f-400" data-index="5.4.5">
                  their intent and consent for the investing function to automatically submit
                  declarations of intent on behalf of the Investor (granting acceptances for
                  concluding Financing Agreements via the Platform on the standard terms and
                  conditions) according to the criteria specified by the Investor upon setting up
                  the investing function and on the principles provided in Section 5.
                </li>
              </ul>
            </li>
            <li className="terms-of-use-page__item f-16 f-400" data-index="5.5.">
              Any declaration of intent submitted via the activated investing function is deemed to
              have been done and confirmed by the Investor without a request for an additional
              confirmation of the wish to submit a declaration of intent and, as such, is binding
              and irrevocable to the Investor.
            </li>
            <li className="terms-of-use-page__item f-16 f-400" data-index="5.6.">
              The investing function submits declarations of intent (acceptances to issue loan) only
              in case of sufficient available balance of funds on the Virtual Account of the
              Investor, i.e. the funds from which any other financial obligations of the Investor
              are not subject to execution in accordance with the Terms of Use.
            </li>
            <li className="terms-of-use-page__item f-16 f-400" data-index="5.7.">
              The Investor can review the Financing Agreements concluded via the investing function
              at any given time and get an overview of the crediting and debiting of funds in the
              Virtual Account, which has taken place on the basis of the Financing Agreements
              concluded as a result of declarations of intent submitted via investing function.
            </li>
            <li className="terms-of-use-page__item f-16 f-400" data-index="5.8.">
              The Investor may activate, temporarily pause, deactivate, or change the settings of
              investing function according to their discretion, however, pausing, deactivating, or
              changing the settings of the investing function is not available during the
              Fundraising period.{' '}
            </li>
            <li className="terms-of-use-page__item f-16 f-400" data-index="5.9.">
              If the Virtual Account of the Investor does not have a sufficient positive balance for
              submitting declarations of intent via the investing function, it does not
              automatically permanently pause or deactivate the investing function. The investing
              function shall automatically continue to submit declarations of intent as soon as a
              sum equal to at least the minimum loan amount defined in the criteria of the investing
              function is transferred to the Virtual Account (incl. if payments from under Financing
              Agreements are received).
            </li>
          </ul>

          <h5 className="terms-of-use-page__subtitle f-20 f-500">6. Initiating the Fundraising</h5>
          <ul className="terms-of-use-page__list">
            <li className="terms-of-use-page__item f-16 f-400" data-index="6.1.">
              If a Founder wishes to borrow funds from the Investors for financing their
              Business(es), they will use the respective functionality of the Platform for
              submitting the request for fundraising (hereinafter the <b>Fundraising Request</b>).
              For submitting the Fundraising Request, the Founder shall provide any and all data
              requested in the application form, including data concerning the Business and its
              Founders, determine the maximum aggregate loan amount to be borrowed from the
              Investors (hereinafter the <b>Target Amount</b>), the loan period and the maximum
              interest rate applicable to the loans issued by the Investors and approve the standard
              terms and conditions of the Financing Agreements that will be concluded with the
              Investors as a result of that Fundraising Request.
            </li>
            <li className="terms-of-use-page__item f-16 f-400" data-index="6.2.">
              If a Founder does not reach the Target Amount during the Fundraising, they shall
              accept to borrow funds from Investors in the amount which has been reached during the
              Fundraising.
            </li>
            <li className="terms-of-use-page__item f-16 f-400" data-index="6.3.">
              Approving the terms and conditions of the Financing Agreements as specified in Clause
              6.1, shall mean a binding offer for borrowing funds on these terms and conditions made
              to all Investors in the Platform. The open terms of the Financing Agreements (the date
              of concluding of the Financing Agreement, details of the Investor issuing the loan,
              interest rate applicable to the loan, the loan amount and securing the loan) are
              specified as provided in individual Financing Agreements.
            </li>
            <li className="terms-of-use-page__item f-16 f-400" data-index="6.4.">
              The information included in the Fundraising Request and any other information provided
              by the Founder at Scramble’s request may be published to all other Users on the
              Platform. The Founder shall be liable to each User and Scramble for the correctness
              and completeness of such information. The information and conditions included in the
              Fundraising Request are binding to the Founder and cannot be amended afterwards.
            </li>
          </ul>

          <h5 className="terms-of-use-page__subtitle f-20 f-500">7. Sureties and Guarantees</h5>
          <ul className="terms-of-use-page__list">
            <li className="terms-of-use-page__item f-16 f-400" data-index="7.1.">
              All the Founders who have submitted the Fundraising Requests by the date settled and
              announced by Scramble, shall be eligible to participate in the marketplace for
              guarantees’ exchange taking place on the Platform during the period settled and
              announced by Scramble. During this period, the Founders can make proposals for
              providing guarantees to secure the Financing Agreements concluded by the Founders as a
              result of a specific Fundraising Request. Only those Founders who have secured their
              Business(es)’ loan with guarantees in the volume indicated by Scramble, shall be
              eligible for the funding.
            </li>
            <li className="terms-of-use-page__item f-16 f-400" data-index="7.2.">
              After the Fundraising Request has been submitted, the Founder shall enter into the
              Surety Agreement. With the Surety the Founder (as a guarantor) undertakes to pay to
              Scramble (as beneficiary) any due liabilities of the other Founder with associated
              Business(es) (as principal obligor) in the amount specified in the Surety Agreement,
              on the terms and conditions specified in the Surety Agreement, under the Financing
              Agreements which are concluded as a result of a specific Fundraising Request.
            </li>
            <li className="terms-of-use-page__item f-16 f-400" data-index="7.3.">
              The Surety Agreement generated by the Platform respectively for the Founders and their
              partners shall be approved by its acceptance in the format determined by Scramble.
            </li>
            <li className="terms-of-use-page__item f-16 f-400" data-index="7.4.">
              With the Surety Agreement each of the Founders undertakes to be liable, on the terms
              and conditions specified in the Surety Agreement, for the obligations arising from the
              Financing Agreements that will be concluded by the Founder as a result of a specific
              Fundraising Request.
            </li>
          </ul>

          <h5 className="terms-of-use-page__subtitle f-20 f-500">
            8. Conclusion of the Financing Agreements
          </h5>
          <ul className="terms-of-use-page__list">
            <li className="terms-of-use-page__item f-16 f-400" data-index="8.1.">
              The Founder is eligible to conclude Financing Agreements as a result of the
              Fundraising Request only if (cumulatively):
              <ul className="terms-of-use-page__sublist">
                <li className="terms-of-use-page__subitem f-16 f-400" data-index="8.1.1">
                  the Business(es) have validly provided Guarantees to secure the Financing
                  Agreements that will be concluded by the Business as a result of the specific
                  Fundraising Request; and
                </li>
                <li className="terms-of-use-page__subitem f-16 f-400" data-index="8.1.2">
                  the Founders have validly entered into Surety Agreements to be liable for
                  obligations arising from the Financing Agreements that will be concluded as a
                  result of the specific Fundraising Request.
                </li>
              </ul>
            </li>
            <li className="terms-of-use-page__item f-16 f-400" data-index="8.2.">
              The Investors shall give acceptances to the Founders’ offers to issue loans to them
              via the investing function as described in Section 5.
            </li>
            <li className="terms-of-use-page__item f-16 f-400" data-index="8.3.">
              The acceptance granted by the Investor via the investing function of the Platform
              shall be binding to the Investor and the Investor cannot unilaterally withdraw or
              cancel the acceptance, unless provided differently by imperative legal provisions or
              the Financing Agreement.
            </li>
            <li className="terms-of-use-page__item f-16 f-400" data-index="8.4.">
              As parties to the Financing Agreement the Investor and the Founder can review the
              Financing Agreement on the Platform via their User Account after it has become
              effective.
            </li>
            <li className="terms-of-use-page__item f-16 f-400" data-index="8.5.">
              Upon disbursement of the loan in accordance with the effective Financing Agreement
              Scramble shall automatically debit the Investor’s Virtual Account in the loan amount
              and credit the Founder’s Virtual Account in the loan amount excluding the applicable
              fees from the Price List.
            </li>
          </ul>

          <h5 className="terms-of-use-page__subtitle f-20 f-500">9. Recovery Fund</h5>
          <ul className="terms-of-use-page__list">
            <li className="terms-of-use-page__item f-16 f-400" data-index="9.1.">
              The Founder shall pay to a recovery fund set up by Scramble (hereinafter the Recovery
              Fund) a fee in the amount specified in the Price List per each loan amount disbursed
              to the Founder under each effective Financing Agreement (irrespective of any partial
              repayments)
            </li>
            <li className="terms-of-use-page__item f-16 f-400" data-index="9.2.">
              Scramble shall use the Recovery Fund at its own discretion to cover any expenses
              related to the collection of any unpaid amount from the Founders (including procedural
              expenses and expenses Scramble has been ordered to bear as a result of the procedures
              initiated by Scramble for the purpose of debt collection) and any and all damages,
              fees, expenses and state fees incurred by Scramble in connection with the performance
              of its obligations as a security agent under the Financing Agreements.
            </li>
            <li className="terms-of-use-page__item f-16 f-400" data-index="9.3.">
              If there are insufficient funds in the Recovery Fund to cover the expenses specified
              in Clause 9.2, the respective provisions of the Financing Agreement(s) shall be
              applicable to covering such expenses.{' '}
            </li>
          </ul>

          <h5 className="terms-of-use-page__subtitle f-20 f-500">
            10. Fulfillment of Financial Obligations via Virtual Account
          </h5>
          <ul className="terms-of-use-page__list">
            <li className="terms-of-use-page__item f-16 f-400" data-index="10.1.">
              Fulfillment of any and all financial obligations resulting from transactions performed
              via the Platform and from the Terms of Use shall be carried out via Virtual Account
              unless provided differently in the Terms of Use. No other financial obligations can be
              fulfilled or payments made through the Virtual Account. Among other, it is forbidden
              to make payments from one Virtual Account to another Virtual Account, except for the
              payments made automatically and according to the Terms of Use for the purposes of
              performing financial obligations arising from the transactions concluded through the
              Platform or owned to Scramble under the Terms of Use.
            </li>
            <li className="terms-of-use-page__item f-16 f-400" data-index="10.2.">
              Scramble shall have the right to establish limitations or requirements regarding the
              minimum or maximum balances on Virtual Accounts at its own discretion.
            </li>
            <li className="terms-of-use-page__item f-16 f-400" data-index="10.3.">
              The User hereby authorizes and instructs Scramble to deduct (debit) any amount the
              User is obligated to pay to another User or Scramble from the first User’s (i.e. the
              obligor’s) Virtual Account on the due date of such payment and transfer (credit) the
              amount to the Virtual Account of the other User (i.e. the obligee) or Scramble. The
              authorization and instruction given by the User herein is irrevocable until the User
              uses the Platform and it shall apply to each payment the User is obliged to make under
              the Terms of Use or the transactions they have concluded via the Platform without any
              further confirmation from the User.
            </li>
            <li className="terms-of-use-page__item f-16 f-400" data-index="10.4.">
              The User shall be obliged to ensure sufficient available funds on their Virtual
              Account in order to perform obligations arising from the Terms of Use and the
              transactions they have concluded via the Platform in a due and timely manner. The User
              shall be liable for the violation of the aforementioned obligation according to the
              terms and conditions of the relevant transaction. In case there are insufficient funds
              in the User’s Virtual Account for making a due payment, Scramble shall make the
              payment to the extent there are available funds on the User’s Virtual Account as soon
              as there are such funds, unless the transaction under which the payment is due
              prescribes otherwise. In addition, in case there are insufficient funds in the User’s
              Virtual Account for making a due payment under the Terms of Use, the User shall pay
              default interest at the rate specified in the Price List.
            </li>
            <li className="terms-of-use-page__item f-16 f-400" data-index="10.5.">
              Scramble shall have the right to use the funds transferred to them and reflected in
              the User’s Virtual Account for the purposes of performing the respective User’s
              obligations arising from the Terms of Use and the transactions the User has concluded
              via the Platform. Among other, Scramble shall have the right to use the respective
              funds for covering the debts of the User to other Users, third parties (if so provided
              by the legal acts or decisions of the courts or competent authorities) or Scramble by
              executing the respective transactions within reasonable time. Scramble shall not use,
              and the User shall not request that Scramble should use its own funds to fulfill the
              financial obligations of the User.
            </li>
            <li className="terms-of-use-page__item f-16 f-400" data-index="10.6.">
              If the Virtual Account lacks sufficient funds for fulfilling several obligations that
              have become simultaneously collectible, the payments made for fulfilling the
              obligations that have become simultaneously collectible shall be made proportionally
              to the amount of the obligation.
            </li>
          </ul>

          <h5 className="terms-of-use-page__subtitle f-20 f-500">
            11. General Rights and Obligations of the User
          </h5>
          <ul className="terms-of-use-page__list">
            <li className="terms-of-use-page__item f-16 f-400" data-index="11.1.">
              In addition to other obligations set forth in the Terms of Use, each User shall be
              obliged:
              <ul className="terms-of-use-page__sublist">
                <li className="terms-of-use-page__subitem f-16 f-400" data-index="11.1.1">
                  not to use the Platform for illegal transactions or activities, including, among
                  other, fraud, money laundering, terrorist financing or other similar activities,
                  and to be respectful towards other Users and Scramble;
                </li>
                <li className="terms-of-use-page__subitem f-16 f-400" data-index="11.1.2">
                  to use the Platform through web browsers intended for general purposes and not
                  through the programs that send automatic queries to the Platform for whichever
                  purposes. It is forbidden to use, including but not limited to, robots, spiders,
                  scrapers and other automatic data entry and processing devices. The User is
                  forbidden to perform any activities that disturb or hinder the Platform’s intended
                  usage by Scramble or other Users, including refraining from overloading the
                  Platform’s computer network and servers;
                </li>
                <li className="terms-of-use-page__subitem f-16 f-400" data-index="11.1.3">
                  to submit only truthful data and information upon registering the User Account and
                  at any time information is required by Scramble. If the User discloses unverified
                  information, the User shall be obliged to indicate it upon disclosing such
                  information. The User shall be liable for the damage caused by violation of the
                  obligation set forth in this Clause with respect to Scramble and other Users,
                  first and foremost in case where Scramble or another User has submitted a
                  declaration of intent or executed a transaction presuming correctness of false
                  information. Scramble does not verify the correctness or completeness of the
                  information submitted by the Users and shall not take any responsibility for the
                  correctness completeness of the information submitted by the Users;
                </li>
                <li className="terms-of-use-page__subitem f-16 f-400" data-index="11.1.4">
                  to keep the data and devices necessary for logging in to the User Account of the
                  Platform in a manner that prevents third parties from gaining access to them;
                </li>
                <li className="terms-of-use-page__subitem f-16 f-400" data-index="11.1.5">
                  to inform Scramble immediately about transferring or losing the data and devices
                  enabling logging in to the User’s User Account to third parties and the usage of
                  the Platform by the third parties, and about the risk of unauthorized use of the
                  User Account and Virtual Account that may occur respectively;
                </li>
                <li className="terms-of-use-page__subitem f-16 f-400" data-index="11.1.6">
                  to inform Scramble as soon as possible of any changes in the data submitted upon
                  opening the User Account. Until Scramble is informed of the changes in the data,
                  Scramble shall consider the data submitted by the User upon opening of the User
                  Account as the valid data;
                </li>
                <li className="terms-of-use-page__subitem f-16 f-400" data-index="11.1.7">
                  to pay Scramble the fees for using the Platform in accordance with the Price List,
                  effective at the time of providing the respective service and pursuant to other
                  agreements (if applicable) entered into with Scramble.
                </li>
              </ul>
            </li>
            <li className="terms-of-use-page__item f-16 f-400" data-index="11.2.">
              The declarations of intent and transactions made through the User Account shall be
              considered as the declarations of intent and transactions of the User of the
              respective User Account and they shall be binding to the User even if the User has not
              executed such transactions.
            </li>
            <li className="terms-of-use-page__item f-16 f-400" data-index="11.3.">
              The User shall be liable for the damages caused by the unauthorized use of the User
              Account and Virtual Account, except in specific cases when the User’s liability is
              limited by the respective imperative provisions of applicable law.
            </li>
          </ul>

          <h5 className="terms-of-use-page__subtitle f-20 f-500">12. Intellectual Property</h5>
          <ul className="terms-of-use-page__list">
            <li className="terms-of-use-page__item f-16 f-400" data-index="12.1.">
              Any and all intellectual property rights to the Platform, including the structure, web
              design elements, texts and other components of the Platform, and the intellectual
              property rights related to them (including the intellectual property rights to
              trademarks, logos, brands, software, databases, news, messages, texts, graphic,
              musical and other design) belong to Scramble, unless the rights of another person are
              referred to with regard to a component, or information has been entered by the Users
              of the Platform. Users shall not be allowed to change, reproduce, disseminate,
              process, translate, make extracts from, forward, include in other databases or make
              public the Platform or any of its contents or components or use it in any other manner
              that is beyond the intended purposes of the Platform without the prior written consent
              of Scramble. Furthermore, the User shall not be allowed to grant sub-licenses for
              using the Platform or any of its contents or components or create new objects of
              intellectual property based on them.
            </li>
            <li className="terms-of-use-page__item f-16 f-400" data-index="12.2.">
              The Users are allowed to publish only such material in the Platform for which they
              have intellectual property rights to the extent that is necessary for publishing the
              respective materials in the Platform and for granting the consent set forth in Clause
              13.3. A User shall be fully responsible for the materials published in the Platform by
              them and shall be obliged to compensate to Scramble any and all expenses and damages
              caused (including indirect damages) that are related to the use of material published
              by the User in the Platform in an unauthorized manner or in a manner that otherwise
              violates rights arising from intellectual property.
            </li>
            <li className="terms-of-use-page__item f-16 f-400" data-index="12.3.">
              With publishing of any material in the Platform by the User, the User shall give their
              unconditional and free consent to Scramble to store, disseminate and publish such
              material in accordance with the Terms of Use and to change or process in any other
              manner to the extent that is necessary for the intended use of the Platform.
            </li>
          </ul>

          <h5 className="terms-of-use-page__subtitle f-20 f-500">13. Liability</h5>
          <ul className="terms-of-use-page__list">
            <li className="terms-of-use-page__item f-16 f-400" data-index="13.1.">
              Scramble shall not be liable for publishing incorrect or misleading information
              provided by the User in the Platform, or for any other violation committed by any User
              via the Platform.
            </li>
            <li className="terms-of-use-page__item f-16 f-400" data-index="13.2.">
              Scramble shall not be liable for the correctness and completeness of information
              received from third parties if Scramble forwards or discloses it in the Platform. If a
              User discovers incorrectness of information contained in the Platform, they shall be
              obliged to inform Scramble as soon as possible.
            </li>
            <li className="terms-of-use-page__item f-16 f-400" data-index="13.3.">
              Scramble shall not be liable for information, opinions and any other content published
              on websites belonging to third parties to which the Platform directly or indirectly
              refers or to which links have been published in the Platform.
            </li>
            <li className="terms-of-use-page__item f-16 f-400" data-index="13.4.">
              Scramble shall not be liable to Users or third parties if a User violates an
              obligation set forth in the Terms of Use, any other agreement concluded with Scramble,
              legal act or an agreement concluded between the Users. Scramble shall be only be
              liable for its own direct obligations arising from a transaction concluded through the
              Platform, unless otherwise specified herein or in the relevant transaction and always
              subject to the limitations of liability prescribed herein and otherwise applicable.
            </li>
            <li className="terms-of-use-page__item f-16 f-400" data-index="13.5.">
              Scramble shall not be liable for any damages that were caused to a User or third
              parties in relation to the fact that Scramble exercised available remedies (e.g.
              removed information from the Platform or limited access to it, limited or restricted
              User’s access to their User Account, Virtual Account or the Platform), if it was
              necessary due to a complaint submitted regarding User’s violation, Scramble’s
              suspicion of a violation or suspicion of unauthorized use of a User Account or
              directly due to the fact that information or a User’s conduct is in violation of the
              Terms of Use or legal acts. In such case Scramble shall not be liable for the damages
              caused even if a complaint or suspicion was unfounded or it later appears that the
              case did not constitute a violation.
            </li>
            <li className="terms-of-use-page__item f-16 f-400" data-index="13.6.">
              Scramble shall not be liable for the temporary interruption in the access to the
              Platform or its functionality.
            </li>
            <li className="terms-of-use-page__item f-16 f-400" data-index="13.7.">
              Scramble shall only be liable for direct proprietary damages to the User caused by
              Scramble’s intentional material violation of its direct obligations. Other damages or
              loss of profit shall not be subject to compensation. Scramble shall not be liable if a
              service provider or any other third party used by Scramble causes the violation of
              obligations.
            </li>
            <li className="terms-of-use-page__item f-16 f-400" data-index="13.8.">
              If a User causes damages to Scramble by violating any of the obligations, the User
              shall be obliged to fully compensate the respective damages to Scramble at first
              request.
            </li>
            <li className="terms-of-use-page__item f-16 f-400" data-index="13.9.">
              The primary purpose of the service provided by Scramble is to introduce Investors and
              Founders to each other in order to enable the Founders to borrow money from the
              Investors via the Platform for the purpose of funding or developing their business
              activities. The services provided by Scramble relate to introductory functions only
              and, among other, the services provided by Scramble do not include the following:
              <ul className="terms-of-use-page__sublist">
                <li className="terms-of-use-page__subitem f-16 f-400" data-index="13.9.1">
                  guaranteeing or otherwise underwriting the availability or accessibility of funds
                  to the Users, or ensuring the performance of the financial obligations of the
                  User(s);
                </li>
                <li className="terms-of-use-page__subitem f-16 f-400" data-index="13.9.2">
                  accepting deposits or other refundable instruments or giving of loans or other
                  financings on its own account or behalf;
                </li>
                <li className="terms-of-use-page__subitem f-16 f-400" data-index="13.9.3">
                  providing investment, tax, legal or any other professional advice;
                </li>
                <li className="terms-of-use-page__subitem f-16 f-400" data-index="13.9.4">
                  provision of payment services.
                </li>
              </ul>
            </li>
            <li className="terms-of-use-page__item f-16 f-400" data-index="13.10.">
              Scramble performs an administrative function in the execution and performance by the
              Users of agreements with the view to facilitating loans between the Users and
              communication between the Users.
            </li>
            <li className="terms-of-use-page__item f-16 f-400" data-index="13.11.">
              Allowing a Founder to make an offer to issue a loan to them via the Platform does not
              constitute any of the following:
              <ul className="terms-of-use-page__sublist">
                <li className="terms-of-use-page__subitem f-16 f-400" data-index="13.11.1">
                  an investment recommendation or other endorsement by Scramble in respect of the
                  relevant Fundraising Request;
                </li>
                <li className="terms-of-use-page__subitem f-16 f-400" data-index="13.11.2">
                  Scramble&apos;s confirmation or any other indication that the Founder is
                  creditworthy;
                </li>
                <li className="terms-of-use-page__subitem f-16 f-400" data-index="13.11.3">
                  Scramble&apos;s confirmation that the Founder’s business is viable;
                </li>
                <li className="terms-of-use-page__subitem f-16 f-400" data-index="13.11.4">
                  Scramble&apos;s confirmation that the Investor will get the relevant investment
                  back or make any profit from it.
                </li>
              </ul>
            </li>
          </ul>

          <h5 className="terms-of-use-page__subtitle f-20 f-500">
            14. Duration of Use of the Platform and Termination of Use
          </h5>
          <ul className="terms-of-use-page__list">
            <li className="terms-of-use-page__item f-16 f-400" data-index="14.1.">
              User Accounts are opened without a term. If a User has no valid (outstanding)
              agreements concluded via the Platform, they can close the User Account and the related
              Virtual Account and thereby terminate the Terms of Use by contacting Scramble’s
              customer support via e-mail {CONTACT_EMAIL}.
            </li>
            <li className="terms-of-use-page__item f-16 f-400" data-index="14.2.">
              Scramble shall have the right to limit or cancel the User’s right to use the Platform
              and close the User Account and the related Virtual Account, as well as to terminate an
              agreement concluded with a User at any time without an advance notice if:
              <ul className="terms-of-use-page__sublist">
                <li className="terms-of-use-page__subitem f-16 f-400" data-index="14.2.1">
                  the User violates or has violated the Terms of Use or any other User obligations;
                </li>
                <li className="terms-of-use-page__subitem f-16 f-400" data-index="14.2.2">
                  the User does not accept new Terms of Use upon first logging into the Platform
                  after the new Terms of Use have been made available in the Platform;
                </li>
                <li className="terms-of-use-page__subitem f-16 f-400" data-index="14.2.3">
                  the User has submitted incorrect, misleading and/or inaccurate information to
                  Scramble or other Users;
                </li>
                <li className="terms-of-use-page__subitem f-16 f-400" data-index="14.2.4">
                  the User acts in bad faith, unrespectfully, unlawfully or in contradiction with
                  the moral standards recognized in the society;
                </li>
                <li className="terms-of-use-page__subitem f-16 f-400" data-index="14.2.5">
                  Scramble has discovered a circumstance based on which there may be a suspicion
                  that third parties have gained access to the data and devices allowing logging
                  into the User’s User Account and using of the Platform and the User Account could
                  be used by an unauthorized person.
                </li>
              </ul>
            </li>
          </ul>

          <h5 className="terms-of-use-page__subtitle f-20 f-500">
            15. Scramble shall have the right to close the Platform.
          </h5>
          <ul className="terms-of-use-page__list">
            <li className="terms-of-use-page__item f-16 f-400" data-index="15.1.">
              Upon closing the Platform or in cases set forth in Clauses 15.1 and 15.2:
              <ul className="terms-of-use-page__sublist">
                <li className="terms-of-use-page__subitem f-16 f-400" data-index="15.1.1">
                  the transactions made through the Platform shall remain in force under the terms
                  and conditions set forth therein;
                </li>
                <li className="terms-of-use-page__subitem f-16 f-400" data-index="15.1.2">
                  the User’s obligation under Clause 10.1 shall remain valid and the User shall make
                  the payments to Scramble’s bank account;
                </li>
                <li className="terms-of-use-page__subitem f-16 f-400" data-index="15.1.3">
                  the settlements arising from transactions concluded between the respective User
                  and other Users or Scramble shall thereafter take place through the bank or
                  payment accounts of the respective User and other Users (for the sake of clarity:
                  after the expiry of the right to use the Platform, Scramble shall not be
                  responsible for executing payments on behalf of the Users via the Virtual
                  Accounts). Scramble shall inform the Users who have outstanding transactions
                  concluded via the Platform with a User who no longer has the right to use the
                  Platform of the expiry of the respective User’s right to use the Platform; and
                </li>
                <li className="terms-of-use-page__subitem f-16 f-400" data-index="15.1.4">
                  Scramble shall pay the positive balance registered in the User’s Virtual Account
                  (as of the moment of expiry of the right to use the Platform), deducting the sums
                  payable to Scramble, to the bank account of the User within 30 (thirty) days from
                  the expiry of the right to use the Platform.
                </li>
              </ul>
            </li>
            <li className="terms-of-use-page__item f-16 f-400" data-index="15.2.">
              Clauses 15.1.3 and 15.1.4 shall not be applicable if Scramble decides to cease its
              operations, but another service provider appointed by Scramble takes over operating
              the Platform.
            </li>
          </ul>

          <h5 className="terms-of-use-page__subtitle f-20 f-500">16. Sending Notices</h5>
          <ul className="terms-of-use-page__list">
            <li className="terms-of-use-page__item f-16 f-400" data-index="16.1.">
              The notices sent to the User’s mailbox connected to the User Account shall be
              considered as received within 24 hours of sending.
            </li>
          </ul>

          <h5 className="terms-of-use-page__subtitle f-20 f-500">17. Other Conditions</h5>
          <ul className="terms-of-use-page__list">
            <li className="terms-of-use-page__item f-16 f-400" data-index="17.1.">
              Legislation of the Republic of Estonia shall be applicable to the use of the Platform
              and the Terms of Use. If a Clause of the Terms of Use proves to be void due to
              contradicting the law, it shall not influence the validity of other Clauses.
            </li>
            <li className="terms-of-use-page__item f-16 f-400" data-index="17.2.">
              Disagreements and disputes arising from the fulfillment of the Terms of Use shall be
              settled by the parties primarily through negotiations. Scramble shall have the
              unilateral right to establish a procedure for extrajudicial settling of disputes. If
              settling of disagreements through negotiations is impossible, the dispute shall be
              settled in general court. If the respective User is a legal entity or a private person
              operating in its economic or professional activities, or a person who after commencing
              the use of the Platform has settled in a foreign state or whose place of business,
              residence or location at the time of filing an action is unknown, the competent
              institution for settling the dispute shall be Harju County Court.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default TermsOfUsePage;
