import React from 'react';
import * as classnames from 'classnames';
import { prettyFloatMoney } from '../../helpers/utils';

export const FR_Result = ({ status, theme }) => (
  <div className={classnames('fr-result', theme)}>
    <div className="fr-result__top">
      <div className="fr-result__result">
        <div className="fr-result__title f-16 f-400">Fundraising result</div>
        <div className="fr-result__value f-26 tl f-500">{prettyFloatMoney(status.raised)}</div>
      </div>
      <div className="fr-result__fee">
        <div className="fr-result__title f-16 f-400">Origination fee</div>
        <div className="fr-result__value f-26 tl f-500">
          {prettyFloatMoney(status.disbursement_fee)}
        </div>
      </div>
      <div className="fr-result__cash">
        <div className="fr-result__title f-16 f-400">Cash received</div>
        <div className="fr-result__value f-26 tl f-500">
          {prettyFloatMoney(status.cash_received)}
        </div>
      </div>
    </div>
  </div>
);

export default FR_Result;
