import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import { MyLink } from '../../../components/MyLink';
import { BankStatementUploadForm } from '../../../components/Forms/BankStatementUploadForm';
import { getBankStatements } from '../../../store/actions/profileActions';
import { deleteBankStatement } from '../../../store/services/userServices';
import { RoundButton } from '../../../components/UI/RoundButton';
import { DATE_FORMAT_MMM_DO_YYYY } from '../../../helpers/common';
import { showDate } from '../../../helpers/date';
import s from './index.module.scss';

export const BankStatements = () => {
  const dispatch = useDispatch();
  const { data } = useSelector(state => state.profileStore.bankStatementList);
  const [params, setParams] = useState({ limit: 3, page: 1 });

  useEffect(() => {
    dispatch(getBankStatements(params));
  }, []);

  const onDelete = async file => {
    await deleteBankStatement(file.id);
    dispatch(getBankStatements({ ...params, page: 1 }));
    setParams({ page: 1, limit: 3 });
  };

  const getNext = totalPages => {
    if (params.page < totalPages) {
      const nextPage = params.page + 1;
      dispatch(
        getBankStatements(
          {
            ...params,
            page: nextPage,
          },
          true
        )
      );

      setParams(prevState => ({
        ...prevState,
        page: nextPage,
      }));
    }
  };

  const grouped = useMemo(() => {
    if (!data) {
      return null;
    }
    return data.list.reduce((acc, file) => {
      if (acc[file.date]) {
        acc[file.date].push(file);
      } else {
        acc[file.date] = [file];
      }
      return acc;
    }, {});
  }, [data]);

  return (
    <div className={s.statement_wrap} id="bank_statement">
      <div className="row">
        <h2 className="f-26 f-500">Bank Statement: monthly update</h2>
      </div>

      <div className={s.statement_container}>
        <BankStatementUploadForm className={s.statement_form} />

        <div className={s.statement_content}>
          {grouped &&
            Object.keys(grouped).map(
              (date, idx) =>
                grouped[date] && (
                  <div key={date + idx} className={s.statement_group}>
                    <div className={classnames(s.statement_group_date)}>
                      {showDate(date, DATE_FORMAT_MMM_DO_YYYY)}
                    </div>
                    <ul className={s.statement_list}>
                      {grouped[date].map(file => (
                        <li key={file.id} className={classnames(s.statement_file)}>
                          <MyLink
                            className={s.statement_file_link}
                            href={file.statement.file}
                            isExternal
                          >
                            Doc: {file.statement.name}
                          </MyLink>
                          <button
                            className={s.statement_file_delete}
                            type="button"
                            onClick={() => onDelete(file)}
                          >
                            <svg
                              width="12"
                              height="12"
                              viewBox="0 0 12 12"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M11.6891 10.2248C12.1036 10.6398 12.1036 11.2853 11.6891 11.7003C11.4818 11.9078 11.2284 12 10.952 12C10.6756 12 10.4223 11.9078 10.215 11.7003L6 7.48127L1.78503 11.7003C1.57774 11.9078 1.32438 12 1.04798 12C0.771593 12 0.518234 11.9078 0.310941 11.7003C-0.103647 11.2853 -0.103647 10.6398 0.310941 10.2248L4.52591 6.00576L0.310941 1.78674C-0.103647 1.37176 -0.103647 0.726225 0.310941 0.311239C0.725528 -0.103746 1.37044 -0.103746 1.78503 0.311239L6 4.53026L10.215 0.311239C10.6296 -0.103746 11.2745 -0.103746 11.6891 0.311239C12.1036 0.726225 12.1036 1.37176 11.6891 1.78674L7.47409 6.00576L11.6891 10.2248Z"
                                fill="white"
                              />
                            </svg>
                          </button>
                        </li>
                      ))}
                    </ul>
                  </div>
                )
            )}

          {data && params.page < data.total_pages && (
            <div className={s.statement_buttons}>
              <RoundButton
                type="button"
                label="Show more"
                className={s.statement_btn_more}
                onClick={() => getNext(data.total_pages)}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
