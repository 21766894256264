import React from 'react';
import { useFormik } from 'formik';
import classnames from 'classnames';
import { useDispatch } from 'react-redux';
import { setModals } from '../../store/actions/commonActions';
import { CheckboxButton } from '../../kit/form/Checkbox';
import { RoundButton } from '../../components/UI/RoundButton';
import { getUserProfile } from '../../store/actions/profileActions';
import { MyLink } from '../../components/MyLink';
import { confirmTermOfUse } from './action';
import { APP_LINKS } from '../../helpers/links';
import s from './index.module.scss';

const TermsOfUseModal = ({ onSuccess }) => {
  const dispatch = useDispatch();
  const onSubmit = async values => {
    if (values.accept) {
      const res = await confirmTermOfUse();
      if (res?.success) {
        onSuccess && onSuccess();
        dispatch(setModals([]));
        dispatch(getUserProfile());

        const warningEl = document.querySelector('#tou_warning');
        if (warningEl) {
          warningEl.style.display = 'none';
        }
      }
    }
  };

  const { values, handleSubmit, setFieldValue } = useFormik({
    initialValues: { accept: false },
    onSubmit,
  });

  return (
    <div className={s.wrapper}>
      <form className={s.form} onSubmit={handleSubmit}>
        <h1 className="f-24 f-500">Notice for the Users</h1>
        <div className={classnames(s.disclaimer, 'f-16')}>
          By clicking the box and agreeing to the{' '}
          <MyLink href={APP_LINKS.docTermsOfUse} isExternal newWindow underline>
            Terms of Use
          </MyLink>
          , the{' '}
          <MyLink
            href={APP_LINKS.docAnnexToUnSubordinatedLoansAgreement}
            isExternal
            newWindow
            underline
          >
            Annex to Financing Agreement
          </MyLink>
          , the{' '}
          <MyLink
            href={APP_LINKS.docAnnexToSubordinatedLoansAgreement}
            isExternal
            newWindow
            underline
          >
            Annex to Subordinated Financing Agreement
          </MyLink>{' '}
          and the{' '}
          <MyLink href={APP_LINKS.docAssignmentAgreement} isExternal newWindow underline>
            Assignment Agreement
          </MyLink>
          , you confirm understanding and acceptance of the loan extension terms. Proceed only if
          you&apos;ve reviewed the details. Contact your manager for any questions.
        </div>
        <div className={s.checkboxes}>
          <CheckboxButton
            value="accept"
            checked={values.accept}
            onChange={() => setFieldValue('accept', !values.accept)}
          >
            <span>
              I have read and agree to the{' '}
              <MyLink href={APP_LINKS.docTermsOfUse} isExternal newWindow underline>
                Terms of Use
              </MyLink>
              , the{' '}
              <MyLink
                href={APP_LINKS.docAnnexToUnSubordinatedLoansAgreement}
                isExternal
                newWindow
                underline
              >
                Annex to Financing Agreement
              </MyLink>
              , the{' '}
              <MyLink
                href={APP_LINKS.docAnnexToSubordinatedLoansAgreement}
                isExternal
                newWindow
                underline
              >
                Annex to Subordinated Financing Agreement
              </MyLink>{' '}
              and the{' '}
              <MyLink href={APP_LINKS.docAssignmentAgreement} isExternal newWindow underline>
                Assignment Agreement
              </MyLink>
              .
            </span>
          </CheckboxButton>
        </div>
        <div className={s.buttons}>
          <RoundButton
            type="submit"
            label="Accept and continue"
            onSubmit={handleSubmit}
            disabled={!values.accept}
            className={s.submit}
            fillBackground
          />
        </div>
      </form>
    </div>
  );
};

export default TermsOfUseModal;
