import { METADATA } from '../../helpers/metadata';
import { LOGOUT_USER } from '../actions/actionTypes';
import {
  GET_ACTIVE_FOUNDERS_LIST,
  GET_ALL_FOUNDERS_LIST,
  GET_FOUNDER_DETAIL,
  GET_FOUNDER_FUNDRAISING_STATUS,
  GET_FOUNDER_SWAP_PARTNERS,
} from '../types/founderTypes';

const initialState = {
  activeList: { ...METADATA.default, data: null },
  allList: { ...METADATA.default, data: null },
  detail: { ...METADATA.default, data: null },
  fundraisingStatus: { ...METADATA.default, data: null },
  swapPartners: { ...METADATA.default, data: null },
};

const founderReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ACTIVE_FOUNDERS_LIST.REQUEST:
      return {
        ...state,
        activeList: { ...state.activeList, ...METADATA.request },
      };
    case GET_ACTIVE_FOUNDERS_LIST.SUCCESS:
      return {
        ...state,
        activeList: { ...METADATA.success, data: action.payload },
      };
    case GET_ACTIVE_FOUNDERS_LIST.FAILURE:
      return {
        ...state,
        activeList: {
          ...state.activeList,
          ...METADATA.error,
          error: action.error,
        },
      };
    case GET_ALL_FOUNDERS_LIST.REQUEST:
      return { ...state, allList: { ...state.allList, ...METADATA.request } };
    case GET_ALL_FOUNDERS_LIST.SUCCESS:
      return {
        ...state,
        allList: { ...METADATA.success, data: action.payload },
      };
    case GET_ALL_FOUNDERS_LIST.FAILURE:
      return {
        ...state,
        allList: { ...state.allList, ...METADATA.error, error: action.error },
      };
    case GET_FOUNDER_DETAIL.REQUEST:
      return { ...state, detail: { ...state.detail, ...METADATA.request } };
    case GET_FOUNDER_DETAIL.SUCCESS:
      return {
        ...state,
        detail: { ...METADATA.success, data: action.payload },
      };
    case GET_FOUNDER_DETAIL.FAILURE:
      return {
        ...state,
        detail: { ...state.detail, ...METADATA.error, error: action.error },
      };
    case GET_FOUNDER_FUNDRAISING_STATUS.REQUEST:
      return {
        ...state,
        fundraisingStatus: { ...state.fundraisingStatus, ...METADATA.request },
      };
    case GET_FOUNDER_FUNDRAISING_STATUS.SUCCESS:
      return {
        ...state,
        fundraisingStatus: { ...METADATA.success, data: action.payload },
      };
    case GET_FOUNDER_FUNDRAISING_STATUS.FAILURE:
      return {
        ...state,
        fundraisingStatus: {
          ...state.fundraisingStatus,
          ...METADATA.error,
          error: action.error,
          data: null,
        },
      };
    case GET_FOUNDER_SWAP_PARTNERS.REQUEST:
      return {
        ...state,
        swapPartners: {
          ...state.swapPartners,
          ...METADATA.request,
          data: null,
        },
      };
    case GET_FOUNDER_SWAP_PARTNERS.SUCCESS:
      return {
        ...state,
        swapPartners: { ...METADATA.success, data: action.payload },
      };
    case GET_FOUNDER_SWAP_PARTNERS.FAILURE:
      return {
        ...state,
        swapPartners: {
          ...state.swapPartners,
          ...METADATA.error,
          error: action.error,
        },
      };

    case LOGOUT_USER:
      return initialState;
    default:
      return state;
  }
};

export default founderReducer;
