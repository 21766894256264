import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { prettyFloatMoney } from '../../helpers/utils';
import './index.scss';

const DashDepositInfo = () => {
  const totals = useSelector(state => state.dashboardStore.dashDepositInfo);

  return (
    <Link to="/loans" className="dash-deposit-info">
      <div className="dash-deposit-info__block">
        <p className="f-16 f-400">Security deposit</p>
        <p className="dash-deposit-info__security f-42 f-500 tl">
          {prettyFloatMoney(totals?.deposited)}
        </p>
      </div>

      <div className="dash-deposit-info__block">
        <p className="f-16 f-400">Outstanding security deposit</p>
        <p className="dash-deposit-info__outstanding f-26 f-500 tl">
          {prettyFloatMoney(totals?.outstanding)}
        </p>
      </div>
    </Link>
  );
};

export default DashDepositInfo;
