import React from 'react';
import * as classnames from 'classnames';
import AvatarEdit from '../AvatarEdit';
import './index.scss';

const PhotoUpload = ({ value, name, label, onChange, setFieldValue, error, className }) => {
  return (
    <div className={classnames('photo-upload', className)}>
      {value ? (
        <>
          <p className="photo-upload__title f-12">{label}</p>
          <AvatarEdit
            src={value.id ? value.file : value}
            name={name}
            setFieldValue={setFieldValue}
            className="photo-upload__editor"
          />
        </>
      ) : (
        <label
          htmlFor={name}
          className={classnames('photo-upload__label', error && 'photo-upload__label-error')}
        >
          <div className="photo-upload__centered">
            <p className="photo-upload__centered-title f-16">{label}</p>
            {error ? (
              <p className="cv-upload__centered-error f-12">{error}</p>
            ) : (
              <p className="cv-upload__centered-desc f-12">Upload file up to 12Mb</p>
            )}
          </div>
          <input
            type="file"
            name={name}
            id={name}
            onChange={onChange}
            className="photo-upload__input"
          />
        </label>
      )}
    </div>
  );
};

export default PhotoUpload;
