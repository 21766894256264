import React from 'react';
import { renderRoutes } from 'react-router-config';
import { NavLink } from 'react-router-dom';
import { APP_LINKS } from '../../helpers/links';
import './index.scss';

const TABS = [
  {
    label: 'Next repayment',
    path: APP_LINKS.nextPayment,
  },
  {
    label: 'Bank Statement',
    path: APP_LINKS.bankStatements,
  },
  {
    label: 'Add funds',
    path: APP_LINKS.addFunds,
  },
  {
    label: 'Withdraw',
    path: APP_LINKS.withdraw,
  },
  {
    label: 'Bank accounts',
    path: APP_LINKS.bankAccounts,
  },
];

const PaymentsPage = ({ route, user }) => (
  <div className="payments-page">
    <div className="container-inner">
      <h1 className="payments-page__title f-500 f-42">Funds</h1>
      <div className="payments-page__links">
        {TABS.map(tab => (
          <NavLink
            key={tab.path}
            to={tab.path}
            className="payments-page__link"
            activeClassName="payments-page__link-selected"
          >
            {tab.label}
          </NavLink>
        ))}
      </div>
      {renderRoutes(route.routes, { user })}
    </div>
  </div>
);

export default PaymentsPage;
