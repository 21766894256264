import React from 'react';
import classnames from 'classnames';
import { MyLink } from '../MyLink';
import config from '../../config';
import s from './index.module.scss';

import { ReactComponent as IconFacebook } from '../../assets/icons/icon_facebook_grey.svg';
import { ReactComponent as IconLinkedin } from '../../assets/icons/icon_linked_grey.svg';
import { ReactComponent as IconInstagram } from '../../assets/icons/icon_instagram_grey.svg';

export const LINKS = [
  {
    icon: IconFacebook,
    link: config.facebookLink,
  },
  {
    icon: IconLinkedin,
    link: config.linkedinLink,
  },
  {
    icon: IconInstagram,
    link: config.instagramLink,
  },
];

export const SocialButtonsGrey = ({ className }) => (
  <div className={classnames(s.container, className)}>
    {LINKS.map((item, index) => {
      const Icon = item.icon;
      return (
        <MyLink key={index} href={item.link} isExternal>
          <Icon />
        </MyLink>
      );
    })}
  </div>
);
