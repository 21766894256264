import * as React from 'react';
import * as Yup from 'yup';
import * as classnames from 'classnames';
import { Formik } from 'formik';
import {GoogleCaptchaV2} from "../../GoogleCaptchaV2";
import { InputTextField } from '../../UI/InputTextField';
import { PasswordField } from '../../UI/PasswordField';
import { RoundButton } from '../../UI/RoundButton';
import { strongPasswordRegex } from '../../../helpers/common';
import { RoundLink } from '../../UI/RoundLink';
import Checkbox from '../../UI/Checkbox';
import { APP_LINKS } from '../../../helpers/links';
import './index.scss';

const INITIAL_VALUES = {
  email: '',
  password: '',
  termsAccept: false,
  privacyAccept: false,
  captchaPassed: false,
};

const VALIDATION_SCHEMA = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Email is required'),
  password: Yup.string()
    .matches(strongPasswordRegex, "Password doesn't meet requirements")
    .required('Password is required'),
  termsAccept: Yup.boolean().oneOf([true], 'Must Accept Terms of Use'),
  privacyAccept: Yup.boolean().oneOf([true], 'Must Accept Privacy policy'),
  captchaPassed: Yup.boolean().oneOf([true], 'Check for robot'),
});

const RegisterForm = ({ onSubmit, role }) => (
  <Formik validationSchema={VALIDATION_SCHEMA} initialValues={INITIAL_VALUES} onSubmit={onSubmit}>
    {({ values, errors, touched, handleChange, handleSubmit, setFieldValue, isSubmitting }) => (
      <form className="register-form" onSubmit={handleSubmit}>
        <h1 className="register-form__title f-34 f-500">
          Create a {role.replace('_', '-')} account
        </h1>
        <InputTextField
          label="Email *"
          name="email"
          value={values.email}
          onChange={e => setFieldValue('email', e.target.value.trim())}
          onClear={() => setFieldValue('email', '')}
          className="register-form__email"
          error={errors.email && touched.email && errors.email}
        />
        <PasswordField
          value={values.password}
          onChange={handleChange}
          onClear={() => setFieldValue('password', '')}
          error={errors.password && touched.password && errors.password}
          className="register-form__password"
          withValidation
        />

        <div className="register-form__terms">
          <Checkbox
            name="termsAccept"
            isChecked={values.termsAccept}
            onChange={handleChange}
            error={errors.termsAccept && touched.termsAccept}
            render={() => (
              <span
                className={classnames('f-12', errors.termsAccept && touched.termsAccept && 'error')}
              >
                I confirm that I have read, understood and accept <br /> the{' '}
                <a href={APP_LINKS.docTermsOfUse} target="_blank" rel="noopener noreferrer">
                  Terms of Use
                </a>
              </span>
            )}
          />
          <Checkbox
            name="privacyAccept"
            isChecked={values.privacyAccept}
            onChange={handleChange}
            error={errors.privacyAccept && touched.privacyAccept}
            render={() => (
              <span
                className={classnames(
                  'f-12',
                  errors.privacyAccept && touched.privacyAccept && 'error'
                )}
              >
                I confirm that I have read, understood and accept <br /> the{' '}
                <a href={APP_LINKS.docPrivacyAndPolicy} target="_blank" rel="noopener noreferrer">
                  Privacy Policy
                </a>
              </span>
            )}
          />
        </div>

        <GoogleCaptchaV2
          className="register-form__captcha"
          onChange={token => setFieldValue('captchaPassed', !!token)}
        />

        <RoundButton
          label="Register"
          className="register-form__submit"
          type="submit"
          disabled={isSubmitting || !!Object.keys(errors).length}
          onSubmit={handleSubmit}
          fillBackground
          fullWidth
          withArrow
        />
        <div className="register-form__bottom">
          <span>Already registered?</span>
          <RoundLink label="Sign in" path="/sign-in" />
        </div>
      </form>
    )}
  </Formik>
);

export default RegisterForm;
