import * as React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { RoundButton } from '../../UI/RoundButton';
import { PasswordField } from '../../UI/PasswordField';
import { strongPasswordRegex } from '../../../helpers/common';
import './index.scss';

const VALIDATION_SCHEMA = Yup.object().shape({
  password1: Yup.string()
    .matches(strongPasswordRegex, "Password doesn't meet requirements")
    .required('Password is required'),
  password2: Yup.string().required('Confirm password'),
});

const INITIAL_VALUES = {
  password1: '',
  password2: '',
};

const RecoverPasswordForm = ({ onSubmit }) => (
  <Formik
    initialValues={INITIAL_VALUES}
    validationSchema={VALIDATION_SCHEMA}
    onSubmit={(values, formikBag) => onSubmit(values, formikBag)}
  >
    {({ values, errors, touched, handleChange, handleSubmit, setFieldValue }) => (
      <form onSubmit={handleSubmit}>
        <h1 className="recover-password_form__title f-500">Enter a new password</h1>
        <PasswordField
          label="New Password"
          name="password1"
          onChange={handleChange}
          value={values.password1}
          onClear={() => setFieldValue('password1', '')}
          error={errors.password1 && touched.password1 && errors.password1}
          withValidation
          className="recover-password_form__password"
        />
        <PasswordField
          label="Confirm Password"
          name="password2"
          onChange={handleChange}
          value={values.password2}
          onClear={() => setFieldValue('password2', '')}
          error={
            (errors.password2 && touched.password2 && errors.password2) ||
            (values.password1 !== values.password2 &&
              touched.password2 &&
              'Password does not match') ||
            ''
          }
          className="recover-password_form__password"
        />
        <RoundButton
          label="Set password"
          name="password2"
          onSubmit={handleSubmit}
          className="recover-password_form__submit"
          fillBackground
          fullWidth
          type="submit"
        />
      </form>
    )}
  </Formik>
);

export default RecoverPasswordForm;
