import React from 'react';
import * as classnames from 'classnames';
import { Link } from 'react-router-dom';
import { ArrowLongNext } from '../Icons';
import './index.scss';

export const BackButton = ({ to, onClick, className, ...props }) => {
  const classNames = classnames('back-button', className);
  const content = (
    <>
      <ArrowLongNext />
      <span className="f-16 f-500">Back</span>
    </>
  );
  return to ? (
    <Link to={to} {...props} className={classNames}>
      {content}
    </Link>
  ) : (
    <button onClick={onClick} {...props} className={classNames}>
      {content}
    </button>
  );
};
