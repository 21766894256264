import React from 'react';
import * as classnames from 'classnames';

const TruncatedTextByLength = ({ text, className }) => {
  if (!text) {
    return null;
  }

  return <p className={classnames('truncated-text-by-length', className)}>{text}</p>;
};

export default TruncatedTextByLength;
