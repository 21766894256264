import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Avatar from '../../components/UI/Avatar';
import { getFullname, getNameInitials } from '../../helpers/utils';
import { RoundLink } from '../../components/UI/RoundLink';
import {
  getDashBalanceInfo,
  getDashDepositInfo,
  getDashPaymentInfo,
} from '../../store/actions/dashboardActions';
import { ROLES, USER_STATUSES } from '../../helpers/constants';
import NotificationBlock from '../NotificationBlock';
import { getFundraisingStatus, getPartnerCounts } from '../../store/actions/fundraisingActions';
import DashBalanceInfo from '../../components/DashBalanceInfo';
import DashPaymentInfo from '../../components/DashPaymentInfo';
import DashDepositInfo from '../../components/DashDepositInfo';
import { DATE_FORMAT_DD_MMM } from '../../helpers/common';
import FundraisingInfo from '../FundraisingInfo';
import { getMissingProfileDetails, getUserProfile } from '../../store/actions/profileActions';
import Preloader from '../../components/UI/Preloader';
import { NOTIFICATIONS } from '../../components/Notification/list';
import { OLD_FOUNDERS_ID } from '../../helpers/founders';
import { canChangeUntil } from '../../helpers/date';
import { isVerifiedPhoneNumber } from '../../helpers/user';
import './index.scss';

class DashboardModule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  componentDidMount() {
    this.props.getPartnerCounts();
    this.props.getDashBalanceInfo();
    this.props.getDashPaymentInfo();
    this.props.getDashDepositInfo();
    this.props.getFundraisingStatus();
    this.props.getMissingProfileDetails();
    this.props.getUserProfile();
  }

  render() {
    const { loading } = this.state;
    const {
      user,
      dashBalanceInfo,
      dashPaymentInfo,
      status,
      fundraisingDates,
      missingInfo,
      isParticipating,
      history,
      profile,
    } = this.props;
    const fullname = getFullname(user.first_name, user.last_name);
    const notifications = [];
    const endDate =
      status && `${moment(status.end_date).format(DATE_FORMAT_DD_MMM).replace('.', '')}, 23:59 GMT`;
    const isReachedGoal = !!(status && status.goal && status.raised >= status.goal);
    const isActiveFundraising = status && status.is_active;
    const missingInfoExist = !!Object.keys(missingInfo).length;

    missingInfoExist &&
      !missingInfo.has_contact_details &&
      notifications.push(idx => (
        <li key={idx}>
          Provide your contact details to start fundraising{' '}
          <Link to="/profile?r=contacts" style={{ color: '#9E98A3' }}>
            Provide details →
          </Link>
        </li>
      ));

    missingInfoExist &&
      !missingInfo.has_company_info &&
      notifications.push(idx => (
        <li key={idx}>
          Provide your company details to start fundraising{' '}
          <Link to="/profile?r=company" style={{ color: '#9E98A3' }}>
            Provide details →
          </Link>
        </li>
      ));

    missingInfoExist &&
      !missingInfo.is_verified &&
      notifications.push(idx => (
        <li key={idx}>
          Verify your identity to start fundraising.{' '}
          <Link to="/profile?r=veriff" style={{ color: '#9E98A3' }}>
            Start verification process →
          </Link>
        </li>
      ));

    missingInfoExist &&
      missingInfo.has_contact_details &&
      missingInfo.has_company_info &&
      missingInfo.is_verified &&
      missingInfo.status !== USER_STATUSES.verified &&
      notifications.push(idx => (
        <li key={idx}>
          We are currently verifying your profile information, it may take up to 1 business day
        </li>
      ));

    isActiveFundraising &&
      isReachedGoal &&
      notifications.push(idx => (
        <li key={idx}>
          Nice! You’ve secured all swaps. <span style={{ color: '#F93C10' }}>{endDate}</span>{' '}
          <Link to="/fundraising" style={{ color: '#9E98A3' }}>
            See details →
          </Link>
        </li>
      ));

    missingInfoExist &&
      missingInfo.status === USER_STATUSES.verified &&
      fundraisingDates &&
      !fundraisingDates.is_active &&
      user.role === ROLES.founder &&
      !status?.goal &&
      notifications.push(idx => (
        <li key={idx}>
          Set your new fundraising goal until {canChangeUntil(fundraisingDates.start_date)}
        </li>
      ));

    profile &&
      !isVerifiedPhoneNumber(profile.user) &&
      notifications.push(NOTIFICATIONS.verify_phone());

    return (
      <div className="dashboard-module">
        <div className="container-inner">
          <div className="dashboard-module__header row">
            <Avatar
              src={user.photo && user.photo.file}
              size={64}
              alt={fullname}
              nameInitials={getNameInitials(user.first_name, user.last_name)}
              active={isParticipating}
              className="dashboard-module__avatar"
            />

            <div className="dashboard-module__header-actions">
              {/*<RoundLink*/}
              {/*  label="Prepay loans"*/}
              {/*  path="/payments/prepay-loans"*/}
              {/*  className="dashboard-module__header-prepay"*/}
              {/*/>*/}
              <RoundLink
                label="Add funds"
                path="/payments/add-funds"
                className="dashboard-module__header-funds"
              />
            </div>
          </div>

          <h1 className="dashboard-module__title f-42 f-500">
            {user.first_name ? `Hey ${user.first_name}, welcome back` : 'Welcome back!'}
          </h1>

          <div className="dashboard-module__notifications-block">
            {loading ? (
              <Preloader className="preloader" />
            ) : (
              <NotificationBlock
                className="dashboard-module__notifications"
                notifications={notifications}
              />
            )}
          </div>

          <section className="dashboard-module__summary">
            <DashBalanceInfo balance={dashBalanceInfo} />
            <DashPaymentInfo payment={dashPaymentInfo} />
            {OLD_FOUNDERS_ID.includes(user.id) && <DashDepositInfo />}
          </section>

          <section className="dashboard-module__fundraising">
            <h2 className="dashboard-module__fundraising-title f-26 f-500">Fundrasing settings</h2>
            {status?.step === 2 ? (
              <>
                <FundraisingInfo
                  onGoalEdit={() => history.push('/fundraising?to=1')}
                  className="dashboard-module__fundraising-info"
                />
                {(isReachedGoal || status?.step === 4) && (
                  <RoundLink
                    label="See fundraising result"
                    path="/fundraising"
                    className="dashboard-module__fundraising-result"
                    fillBackground
                    fullWidth
                  />
                )}
              </>
            ) : (
              <RoundLink
                label={status?.goal ? 'Go to fundraising' : 'Start new fundraising'}
                path="/fundraising"
                className="dashboard-module__fundraising-start"
              />
            )}
          </section>
          {/*<DashboardFAQ />*/}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.userStore.user,
  dashBalanceInfo: state.dashboardStore.dashBalanceInfo,
  dashPaymentInfo: state.dashboardStore.dashPaymentInfo,
  status: state.fundraisingStore.status.data,
  isParticipating: state.fundraisingStore.isParticipating,
  fundraisingDates: state.dashboardStore.fundraisingDates,
  missingInfo: state.profileStore.profileMissingDetails,
  profile: state.profileStore.profile.data,
});

const mapDispatchToProps = dispatch => ({
  getDashBalanceInfo: () => dispatch(getDashBalanceInfo()),
  getDashPaymentInfo: () => dispatch(getDashPaymentInfo()),
  getDashDepositInfo: () => dispatch(getDashDepositInfo()),
  getFundraisingStatus: () => dispatch(getFundraisingStatus()),
  getPartnerCounts: () => dispatch(getPartnerCounts()),
  getMissingProfileDetails: () => dispatch(getMissingProfileDetails()),
  getUserProfile: () => dispatch(getUserProfile()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardModule);
