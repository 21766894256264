import axios from '../../axios-api';
import Pathes from '../../helpers/pathes';
import { getMessage } from '../../helpers/utils';
import Notify from '../../components/Notification';

export const confirmTermOfUse = () => {
  return axios
    .post(Pathes.Fundraising.termsOfUseStatus)
    .then(response => {
      const { status, data } = response;
      const message = getMessage(data);
      if (status === 200) {
        message && Notify.success({ text: message });
        return { data, success: true, message };
      }
      throw new Error(message);
    })
    .catch(e => ({ success: false, message: e.message }));
};
