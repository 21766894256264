import React, { useState } from 'react';
import moment from 'moment';
import classnames from 'classnames';
import { DATE_FORMAT_DD_MMM_YYYY } from '../../../../helpers/common';
import { prettyFloatMoney } from '../../../../helpers/utils';
import s from './index.module.scss';

export const Table = ({ data, rowDetails, onToggleRow }) => {
  const [activeRows, setActiveRows] = useState([]);

  return (
    <div className={s.table__wrap}>
      <div className={s.table__inner}>
        <div className={s.table}>
          <div className={s.table__thead}>
            <div className={s.table__thead_col}>Num</div>
            <div className={s.table__thead_col}>Due date</div>
            <div className={s.table__thead_col}>Repayment</div>
            <div className={s.table__thead_col}>Outstanding principal</div>
          </div>

          <div className={s.table__tbody}>
            {data.list.map((item, idx) => (
              <div className={s.table__tbody_row} key={idx}>
                <div className={s.table__tbody_col}>{idx + 1}</div>

                <div className={s.table__tbody_col}>
                  {moment(item.deadline).format(DATE_FORMAT_DD_MMM_YYYY)}
                </div>

                <div
                  className={classnames(s.table__tbody_col)}
                  onClick={() => {
                    onToggleRow(item.deadline);
                    setActiveRows(
                      activeRows.includes(item.deadline)
                        ? activeRows.filter(deadline => deadline !== item.deadline)
                        : [...activeRows, item.deadline]
                    );
                  }}
                >
                  <div className={s.row}>
                    {prettyFloatMoney(item.total_amount)}
                    <IconVector active={activeRows.includes(item.deadline)} />
                  </div>

                  <div
                    className={classnames(
                      s.dropdown,
                      activeRows.includes(item.deadline) && rowDetails[item.deadline] && 'active'
                    )}
                  >
                    <div className={s.dropdown_content}>
                      {rowDetails[item.deadline]?.fundraisings.map(fundraising => (
                        <div key={fundraising.fundraising_name} className={s.fundraising}>
                          <div className="f-16 f-500">{fundraising.fundraising_name}</div>
                          <div className={s.sources}>
                            {fundraising.sources.map(source => (
                              <div key={source.source} className={s.source}>
                                <div className="f-14">{source.source_text}</div>
                                <div className="f-16">{prettyFloatMoney(source.amount)}</div>
                              </div>
                            ))}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                <div className={classnames(s.table__tbody_col, 'tl')}>
                  {prettyFloatMoney(item.outstanding)}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const IconVector = ({ active }) => (
  <svg
    width="7"
    height="4"
    viewBox="0 0 7 4"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      marginBottom: '3px',
      transform: `rotate(${active ? '180deg' : '0'})`,
    }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.89072 3.84L6.83832 0.946667C7.05389 0.72 7.05389 0.373333 6.83832 0.16C6.62275 -0.0533333 6.25898 -0.0533333 6.04341 0.16L3.5 2.66667L0.956587 0.16C0.727545 -0.0533333 0.377246 -0.0533333 0.161677 0.16C-0.0538922 0.373333 -0.0538922 0.72 0.161677 0.946667L3.09581 3.84C3.32485 4.05333 3.67515 4.05333 3.89072 3.84Z"
      fill="black"
    />
  </svg>
);
