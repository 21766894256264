import React from 'react';
import moment from 'moment';
import classnames from 'classnames';
import { connect } from 'react-redux';
import * as qs from 'qs';
import { getActivityList } from '../../store/actions/activityActions';
import { getMissingProfileDetails } from '../../store/actions/profileActions';
import { prettyFloatMoney } from '../../helpers/utils';
import Preloader from '../../components/UI/Preloader';
import { RoundButton } from '../../components/UI/RoundButton';
import ActivityFilters from './filters';
import { MyLink } from '../../components/MyLink';
import config from '../../config';
import './index.scss';

const FILE_FORMATS = ['pdf', 'xlsx', 'csv'];

class ActivityModule extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      limit: 20,
      filters: {
        group_by_type: 1,
        group_by_fundraising: 0,
      },
    };
  }

  componentDidMount() {
    const { page, limit, filters } = this.state;
    this.props.getActivityList({ page, limit, ...filters });
    this.props.getMissingProfileDetails();
  }

  getNext = totalPages => {
    if (this.state.page < totalPages) {
      const nextPage = this.state.page + 1;
      this.props.getActivityList(
        {
          ...this.state.filters,
          limit: this.state.limit,
          page: nextPage,
        },
        true
      );

      return this.setState(prevState => ({
        ...prevState.filters,
        limit: prevState.limit,
        page: nextPage,
      }));
    }
  };

  onFilterSubmit = ({
    types,
    startDate,
    endDate,
    groupResults,
    groupByFundraising,
    loanNumber,
  }) => {
    const filters = {
      operation_types: types.length ? types.map(i => i.value) : null,
      start_date: startDate ? `${startDate.year}-${startDate.month}-${startDate.day}` : null,
      end_date: endDate ? `${endDate.year}-${endDate.month}-${endDate.day}` : null,
      loan: loanNumber || null,
      group_by_type: groupResults ? 1 : 0,
      group_by_fundraising: groupByFundraising ? 1 : 0,
    };

    this.setState(prevState => ({
      ...prevState,
      page: 1,
      filters,
    }));

    this.props.getActivityList({
      page: 1,
      limit: this.state.limit,
      ...filters,
    });
  };

  render() {
    const { page, filters } = this.state;
    const { activityTypes, activityList } = this.props;
    const { data, loading } = activityList;
    const isEmpty = !(data && data.list.length);

    const isFundraisingGroup = !!this.state.filters?.group_by_fundraising;

    return (
      <div className="activity-module">
        <div className="container-inner">
          <h1 className="activity-module__title f-42 f-500">Activity</h1>

          <ActivityFilters onSubmit={this.onFilterSubmit} />
          {!isEmpty && (
            <div className="activity-module__download">
              <div>Download results</div>
              <ul className="scr-circle-list">
                {FILE_FORMATS.map(format => (
                  <li key={format}>
                    <MyLink
                      href={`${config.apiURL}activities/download/${format}/${
                        filters
                          ? `?${qs.stringify(filters, {
                              strictNullHandling: true,
                              arrayFormat: 'comma',
                              skipNulls: true,
                            })}`
                          : ''
                      }`}
                      isExternal
                      newWindow
                    >
                      {format}
                    </MyLink>
                  </li>
                ))}
              </ul>
            </div>
          )}

          {loading && !data ? (
            <Preloader />
          ) : isEmpty ? (
            <div className="activity-module__empty f-16 f-400">
              {this.state.filters ? ' No results for selected filters' : ' No activity yet.'}
            </div>
          ) : (
            <div className="activity-module__table">
              <div
                className={classnames(
                  'activity-module__thead',
                  isFundraisingGroup ? 'cols_4' : 'cols_3'
                )}
              >
                <div className="activity-module__thead-col">Date</div>
                <div className="activity-module__thead-col">Details</div>
                <div className="activity-module__thead-col">Amount</div>
                {isFundraisingGroup && (
                  <div className="activity-module__thead-col">Fundraising</div>
                )}
              </div>
              <div className="activity-module__tbody">
                {data?.list.map((item, index) => (
                  <div
                    className={classnames(
                      'activity-module__tbody-row',
                      isFundraisingGroup ? 'cols_4' : 'cols_3'
                    )}
                    key={item.id || index}
                  >
                    <div className="activity-module__tbody-col">
                      {!(!filters?.group_by_type && !filters?.group_by_fundraising)
                        ? moment(item.created_at).format('DD MMMM YYYY')
                        : moment(item.created_at).format('DD MMMM YYYY, HH:mm')}
                    </div>
                    <div className="activity-module__tbody-col">
                      {item.details
                        ? item.details
                        : activityTypes
                        ? activityTypes[item.operation_type]
                        : ''}
                    </div>
                    <div className="activity-module__tbody-col">
                      <p className="tl">{prettyFloatMoney(item.total_amount || item.amount)}</p>
                    </div>
                    {isFundraisingGroup && (
                      <div className="activity-module__tbody-col">
                        {item.fundraising_name || '-'}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          )}

          {data && page < data.total_pages && (
            <RoundButton
              type="button"
              label="Show more"
              className="activity-module__more-btn"
              fullWidth
              onClick={() => this.getNext(data.total_pages)}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  activityList: state.activityStore.activityList,
  activityTypes: state.activityStore.activityTypes,
});

const mapDispatchToProps = dispatch => ({
  getActivityList: (params, isNext) => dispatch(getActivityList(params, isNext)),
  getMissingProfileDetails: () => dispatch(getMissingProfileDetails()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ActivityModule);
