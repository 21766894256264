import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { ROLES } from '../../helpers/constants';
import { createWithdrawRequest, getWithdrawInfo } from '../../store/actions/paymentActions';
import { getBalance } from '../../store/actions/dashboardActions';
import { getMissingProfileDetails } from '../../store/actions/profileActions';
import Preloader from '../../components/UI/Preloader';
import WithdrawForm from '../../components/Forms/WithdrawForm';
import WithdrawHistory from './history';
import s from './index.module.scss';

export const WithdrawModule = ({ user }) => {
  const dispatch = useDispatch();
  const [historyUpdater, setHistoryUpdater] = useState(new Date().toISOString()); // Used to force update Withdrawals history
  const { data, loading } = useSelector(state => state.paymentStore.withdrawInfo);
  const hasExternalAcc = !!data?.external_accounts?.length;

  useEffect(() => {
    dispatch(getWithdrawInfo());
    dispatch(getMissingProfileDetails());
  }, []);

  const onSubmit = async (values, formikBag) => {
    const payload = {
      account: values.account.id,
      amount: values.amount,
    };

    const res = await dispatch(createWithdrawRequest(payload));
    if (res && res.success) {
      dispatch(getBalance());
      setHistoryUpdater(new Date().toISOString()); // Force update Withdrawals History
      formikBag.resetForm();
      formikBag.setValues({ account: values.account, amount: '' });
    }
  };

  return (
    <div className={s.wrapper}>
      {loading ? (
        <Preloader className={s.preloader} />
      ) : hasExternalAcc ? (
        <WithdrawForm
          balance={data && data.cash}
          accounts={data && data.external_accounts}
          onSubmit={onSubmit}
        >
          {user.role === ROLES.founder && (
            <WithdrawHistory resetTime={historyUpdater} className={s.history} />
          )}
        </WithdrawForm>
      ) : (
        <div>
          <h1 className={classnames('f-26 f-500')}>Bank account info</h1>
          <p className={classnames(s.description, 'f-20 f-500')}>
            Withdrawals are only available to companies with valid bank accounts (IBAN for EU-based
            companies).
          </p>
        </div>
      )}
    </div>
  );
};
