import React from 'react';
import * as classnames from 'classnames';
import './index.scss';

const BankStatementUpload = ({
  name,
  label,
  description,
  onChange,
  error,
  multiple = true,
  accept,
  className,
}) => (
  <div className={classnames('statement_upload', className)}>
    <label
      htmlFor={name}
      className={classnames('statement_upload__label', error && 'statement_upload__label_error')}
    >
      <div className="statement_upload__centered">
        <div className="statement_upload__centered_title f-16">{label}</div>
        {error ? (
          <div className="statement_upload__centered_error f-12">{error}</div>
        ) : (
          <div className="statement_upload__centered_desc f-12">
            {description || 'Upload file up to 12Mb'}
          </div>
        )}
      </div>
      <input
        type="file"
        multiple={multiple}
        name={name}
        id={name}
        onChange={onChange}
        className="statement_upload__input"
        accept={accept && accept.join(', ')}
      />
    </label>
  </div>
);

export default BankStatementUpload;
