import React, { useEffect } from 'react';
import classnames from 'classnames';
import { NavLink, useHistory, useParams } from 'react-router-dom';
import withScroll from '../../../../hoc/withScroll';
import { MyLink } from '../../../../components/MyLink';
import { APP_LINKS } from '../../../../helpers/links';
import { ExampleTable } from './components/ExampleTable';
import Dropdown from '../../../../components/Dropdown';
import { TARIFF_PLANS } from '../../../../helpers/constants';
import { EXAMPLE_DATA_ADVANCED_6, EXAMPLE_DATA_BASIC_6 } from './components/ExampleTable/data';
import { StandardPricing } from './components/Pricing/standard';
import { AdvancedPricing } from './components/Pricing/advanced';
import s from './index.module.scss';

const TABS = [
  {
    label: 'Standard Plan',
    path: APP_LINKS.helpLoanTerms('v4-standard'),
  },
  {
    label: 'Advanced Plan',
    path: APP_LINKS.helpLoanTerms('v4-advanced'),
  },
];

const LoanTermsVersion04 = ({ plan = TARIFF_PLANS.standard }) => {
  const history = useHistory();
  const { version } = useParams();
  const { standard } = TARIFF_PLANS;

  useEffect(() => {
    !version && history.replace(APP_LINKS.helpLoanTerms('v4-standard'));
  }, []);

  return (
    <div className={s.page}>
      <div className="container-inner">
        <h1 className="f-42 f-500">Loan terms</h1>
        <div className={s.links}>
          {TABS.map(tab => (
            <NavLink
              key={tab.path}
              to={tab.path}
              className={s.link}
              activeClassName={s.link_selected}
            >
              {tab.label}
            </NavLink>
          ))}
        </div>
        <section className={s.page__pricing}>
          <h2 className="f-26 f-500">{plan === standard ? 'Standard Plan' : 'Advanced Plan'}</h2>
          <p className={classnames(s.page__pricing_desc, 'f-16 f-400')}>
            Simple flat fee, no hidden costs
          </p>
          <div className={s.page__pricing_groups}>
            <div className={s.page__pricing_group}>
              <div className="f-28 f-600 nowrap">{plan === standard ? '9%' : '6%'} flat fee</div>
              <div className="f-16 f-400">for each 6 months</div>
            </div>
            <div className={s.page__pricing_group}>
              <div className="f-28 f-600 nowrap">Up to 2 years</div>
              <div className="f-16 f-400">
                Use money for 6 months or <br /> up to 2 years
              </div>
            </div>
            <div className={s.page__pricing_group}>
              <div className="f-28 f-600 nowrap">{plan === standard ? '2%' : '8%'}</div>
              <div className="f-16 f-400">of principal per months</div>
            </div>
          </div>
          {plan === standard ? (
            <ul className={s.page__pricing_list}>
              <li>
                Required risk factor scoring, which will determine the final repayment schedule;
              </li>
              <li>6 months loan terms;</li>
              <li>Personal Guarantees up to 40%;</li>
              <li>Effective annual cost of capital 20,8%;</li>
              <li>Available to everyone;</li>
            </ul>
          ) : (
            <ul className={s.page__pricing_list}>
              <li>Possible to reduce monthly repayment from 8% to only 4% per month;</li>
              <li>6 months loan terms;</li>
              <li>Personal Guarantees up to 40%;</li>
              <li>Effective annual cost of capital 16%;</li>
              <li>Available to brands with annual sales &gt;€1M;</li>
              <li>Required risk factor scoring, which will determine the final pricing.</li>
            </ul>
          )}
        </section>

        <section className={s.page__risk}>
          <h2 className="f-26 f-500">How does risk factor scoring work?</h2>
          <div className="f-16 f-400" style={{ marginTop: '15px' }}>
            Scramble risk evaluation is built around 7 parameters:
          </div>
          <ul className={s.page__risk_list}>
            <li>
              <i>1</i> Sales volume
            </li>
            <li>
              <i>2</i> Profitability
            </li>
            <li>
              <i>3</i>Sales growth
            </li>
            <li>
              <i>4</i>Existing debt level
            </li>
            <li>
              <i>5</i>Shareholders equity
            </li>
            <li>
              <i>6</i> External investors
            </li>
            <li>
              <i>7</i> Payments discipline
            </li>
          </ul>

          <div className="f-16 f-400">
            Based on the results of the assessment, the brand will be assigned a certain risk level:
            High-, Medium, or Low-Risk.
          </div>
          <div className="f-16 f-400" style={{ marginTop: '4px' }}>
            Based on the brand’s risk level, final pricing is adjusted as follows:
          </div>
          {plan === standard ? <StandardPricing /> : <AdvancedPricing />}
        </section>

        <section className={s.page__repayments}>
          <h2 className="f-26 f-500">Loan repayments</h2>
          <p className={classnames(s.page__repayments_desc, 'f-16 f-400')}>
            Monthly loan repayments are deducted from your Scramble cash account at 10:00 AM (GMT+1)
            on the 5th day, the month after the loan is issued.
          </p>
          <h3 className="f-20 f-500">Regular repayment schedule</h3>
          {plan === standard ? (
            <p className={classnames(s.page__repayments_desc, 'f-16 f-400')}>
              Right after the end of a round, we charge a 4.05% flat fee. Then, you pay off 2% or 4%
              or 6% of the loan principal for the first 5 months, and 90% or 80% or 70% of the loan
              principal and an outstanding 4.95% flat fee in the 6th month.
            </p>
          ) : (
            <p className={classnames(s.page__repayments_desc, 'f-16 f-400')}>
              Right after the end of a round, we charge a 1.725% flat fee. Then, you pay off 8% or
              4% of the loan principal for the first 5 months, and 60% or 80% of the loan principal
              and an outstanding 4.275% flat fee in the 6th month.
            </p>
          )}
          <p className="f-16 f-400" style={{ marginTop: '10px' }}>
            You can prepay up to 100% of the loan without any extra fees or penalties.
          </p>

          <h3 className="f-20 f-500">Loan Terms Repayments</h3>
          <Dropdown
            label={<span className="f-16 f-400">Regular repayment schedule example</span>}
            className={s.page__repayments_dropdown}
            style={{ marginTop: '20px' }}
            defaultShow
          >
            <div className={s.page__summary}>
              <div className={s.page__summary_block}>
                <div className="f-16 f-400">Loan amount</div>
                <div className="f-20 f-600">€10’000</div>
              </div>
              <div className={s.page__summary_block}>
                <div className="f-16 f-400">Origination date</div>
                <div className="f-20 f-600">15.01.2024</div>
              </div>
              <div className={s.page__summary_block}>
                <div className="f-16 f-400">Flat fee</div>
                <div className="f-20 f-600">{plan === standard ? '9% ' : '6%'}</div>
              </div>
              <div className={s.page__summary_block}>
                <div className="f-16 f-400">Monthly repayment</div>
                <div className="f-20 f-600">{plan === standard ? '2%' : '8%'}</div>
              </div>
            </div>
            <ExampleTable
              data={plan === standard ? EXAMPLE_DATA_BASIC_6 : EXAMPLE_DATA_ADVANCED_6}
            />
          </Dropdown>

          <h3 className="f-20 f-500">Flexible repayment schedule</h3>
          <p className={classnames(s.page__repayments_desc, 'f-16 f-400')}>
            Based on your actual cash flow, each repayment can be turned into “bullet” (to be paid
            in the last month).
          </p>
          <ul className={s.page__grouped_list}>
            <li>
              <div className="f-18 f-500">1% flat fee for each additional bullet conversion</div>
            </li>
          </ul>

          <h3 className="f-20 f-500">What if I want to extend my loan repayment period?</h3>
          <p className={classnames(s.page__repayments_desc, 'f-16 f-400')}>
            Yes, you can do it. It will cost you {plan === standard ? '9%' : '6%'} flat fee for the
            next 6 months and additional 1-3% refinance fee.
          </p>
          <div className={classnames(s.page__repayments_desc, 'f-16 f-400')}>
            Refinance fee depends on the time you requested this option:
            <ul className={s.page__grouped_list} style={{ marginTop: '10px' }}>
              <li>
                <div className="f-18 f-500">Early Refinancing</div>
                <div className="f-16 f-400">
                  If you request extension of the loan in advance within 4 months of receiving the
                  loan, then you have to pay only 1% refinance fee
                </div>
              </li>
              <li>
                <div className="f-18 f-500">Late Refinancing</div>
                <div className="f-16 f-400">
                  If you request extension of the loan until the start of the round in the last 6th
                  month after receiving the loan, then you have to pay 2% refinance fee
                </div>
              </li>
              <li>
                <div className="f-18 f-500">Auto-Extension</div>
                <div className="f-16 f-400">
                  If you didn’t request extension in advance and can’t pay the outstanding loan
                  amount, it will extend automatically for 3% refinance fee
                </div>
              </li>
            </ul>
          </div>
        </section>

        <section className={s.page__wrong}>
          <h2 className="f-26 f-500">What if something goes wrong?</h2>
          <ul className={s.page__grouped_list}>
            <li>
              <div className="f-18 f-500">Need more capital?</div>
              <div className="f-16 f-400">
                As long as a business is operational, funding is available every month.
              </div>
            </li>
            <li>
              <div className="f-18 f-500">Can’t pay a loan on time?</div>
              <div className="f-16 f-400">
                Turn your missed payments into «bullet» with an extra 1% flat fee
              </div>
            </li>
            <li>
              <div className="f-18 f-500">Business fails completely?</div>
              <div className="f-16 f-400">
                The co-founder team provides a joint personal guarantee for a maximum of 20% of the
                borrowed principal.
                <br />
                Co-founders have up to 5 years to pay back loans from their personal income.
              </div>
            </li>
          </ul>
        </section>

        <section className={s.page__agreements}>
          <h2 className="f-26 f-500">Loan agreements</h2>
          <p className={classnames(s.page__agreements_desc, 'f-16 f-400')}>
            When you set up your fundraising settings, you accept the terms of the Finiancing
            Agreement and the Subordinated Financing Agreement. These documents for each created
            loan are available inside a loan card on the{' '}
            <MyLink href={APP_LINKS.loans}>Loans page</MyLink>.
          </p>
        </section>
      </div>
    </div>
  );
};

export default withScroll(LoanTermsVersion04);
