import React from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import config from '../../config';
import s from './index.module.scss';

export const MyLink = ({
  isExternal,
  href,
  newWindow = true,
  children,
  className,
  underline,
  ...other
}) => {
  const linkOptions = {};
  if (isExternal && newWindow) {
    linkOptions.target = '_blank';
    linkOptions.rel = 'noopener noreferrer';
  }
  return isExternal ? (
    <a
      href={href || '#'}
      className={classnames(underline && s.underline, className)}
      {...linkOptions}
      {...other}
    >
      {children}
    </a>
  ) : (
    <Link
      to={href ? href.replace(config.baseURL, '') : '#'}
      className={classnames(underline && s.underline, className)}
      {...other}
    >
      {children}
    </Link>
  );
};
