import React from 'react';
import classnames from 'classnames';
import { StartupsActive } from './active';
import { StartupsAll } from './all';
import s from './index.module.scss';

const StartupsPage = () => {
  return (
    <div className={s.startups_page}>
      <div className="container-inner">
        <h1 className={classnames(s.startups_page__title, 'f-42 f-500')}>Startups</h1>
        <StartupsActive />
        <StartupsAll />
      </div>
    </div>
  );
};

export default StartupsPage;
