import { GET_MINI_FAQ } from '../types/helpTypes';

const initialState = {
  miniFaqs: null,
};

const helpReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_MINI_FAQ.SUCCESS:
      return { ...state, miniFaqs: action.payload };

    default:
      return state;
  }
};

export default helpReducer;
