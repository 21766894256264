import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { APP_LINKS } from './helpers/links';
import SignUpPage from './pages/SignUpPage';
import SignInPage from './pages/SignInPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import RecoverPasswordPage from './pages/RecoverPasswordPage';
import DevPage from './pages/DevPage';
import RootPage from './pages/RootPage';
import DashboardPage from './pages/DashboardPage';
import PageNotFound from './pages/PageNotFound';
import FundraisingPage from './pages/FundraisingPage';
import PaymentsPage from './pages/PaymentsPage';
import PaymentsNextPage from './pages/PaymentsNextPage';
// import PaymentsPrepayPage from './pages/PaymentsPrepayPage';
import PaymentsBankDetailsPage from './pages/PaymentsBankDetailsPage';
import PaymentsAddFundsPage from './pages/PaymentsAddFundsPage';
import PaymentsAddFundsResultPage from './pages/PaymentsAddFundsResultPage';
import PaymentsWithdrawPage from './pages/PaymentsWithdrawPage';
import PaymentsBankStatementPage from './pages/PaymentsBankStatementPage';
import FoundersPage from './pages/FoundersPage';
import FounderDetailPage from './pages/FounderDetailPage';
import LoansPage from './pages/LoansPage';
import ActivityPage from './pages/ActivityPage';
import ProfilePage from './pages/ProfilePage';
import HelpPage from './pages/HelpPage';
import LoanTermsPage from './pages/LoanTermsPage';
import SwapGuaranteePage from './pages/SwapGuaranteePage';
import TermsOfUsePage from './pages/TermsOfUsePage';
import StartupsPage from './pages/StartupsPage';
import StartupDetailPage from './pages/StartupDetailPage';

const ROUTES = [
  {
    path: '/sign-up/co-founder/:inviteCode',
    component: SignUpPage,
    pageTitle: 'Scramble - Sign Up Co-Founder',
    exact: true,
  },
  {
    path: '/sign-up/:invite',
    component: SignUpPage,
    pageTitle: 'Scramble - Sign Up',
    exact: true,
  },
  {
    path: '/sign-in',
    component: SignInPage,
    pageTitle: 'Scramble - Sign In',
    exact: true,
  },
  {
    path: '/forgot-password',
    component: ForgotPasswordPage,
    pageTitle: 'Scramble - Forgot Password',
    exact: true,
  },
  {
    path: '/password/set/:key',
    component: RecoverPasswordPage,
    pageTitle: 'Scramble - Password Recovery',
    exact: true,
  },
  {
    path: '/legal/terms-of-use/',
    component: TermsOfUsePage,
    pageTitle: 'Scramble - Terms of Use',
    exact: true,
  },
  {
    path: '/dev',
    component: DevPage,
    pageTitle: 'Scramble - Development Page',
    exact: true,
  },
  {
    path: '/',
    component: RootPage,
    routes: [
      {
        path: '/',
        render: () => <Redirect to={APP_LINKS.dashboard} />,
        pageTitle: 'Scramble - Deposit Page',
        exact: true,
      },
      {
        path: APP_LINKS.dashboard,
        component: DashboardPage,
        pageTitle: 'Scramble - Dashboard',
        auth: user => !!user,
        exact: true,
      },
      {
        path: APP_LINKS.fundraising,
        component: FundraisingPage,
        pageTitle: 'Scramble - Fundraising',
        auth: user => !!user,
        exact: true,
      },
      {
        path: APP_LINKS.founders,
        component: FoundersPage,
        pageTitle: 'Scramble - Founders',
        auth: user => !!user,
        exact: true,
      },
      {
        path: APP_LINKS.startups,
        component: StartupsPage,
        pageTitle: 'Scramble - Startups page',
        auth: user => !!user,
        exact: true,
      },
      {
        path: APP_LINKS.startupDetail(':slug'),
        component: StartupDetailPage,
        pageTitle: 'Scramble - Startup Detail page',
        auth: user => !!user,
        exact: true,
      },
      {
        path: APP_LINKS.founderDetail(':id'),
        component: FounderDetailPage,
        pageTitle: 'Scramble - Founder Detail page',
        auth: user => !!user,
        exact: true,
      },
      {
        path: APP_LINKS.activity,
        component: ActivityPage,
        pageTitle: 'Scramble - Activity',
        auth: user => !!user,
        exact: true,
      },
      {
        path: APP_LINKS.profile,
        component: ProfilePage,
        pageTitle: 'Scramble - Profile',
        auth: user => !!user,
        exact: true,
      },
      {
        path: '/payments',
        component: PaymentsPage,
        auth: user => !!user,
        pageTitle: 'Payments',
        routes: [
          {
            path: '/payments',
            render: () => <Redirect to={APP_LINKS.nextPayment} />,
            pageTitle: 'Scramble - Next Payments Page',
            exact: true,
          },
          {
            path: APP_LINKS.nextPayment,
            component: PaymentsNextPage,
            pageTitle: 'Scramble - Next Payments Page',
            exact: true,
          },
          {
            path: APP_LINKS.bankAccounts,
            component: PaymentsBankDetailsPage,
            pageTitle: 'Scramble - Bank accounts',
            exact: false,
          },
          // {
          //   path: '/payments/prepay-loans',
          //   component: PaymentsPrepayPage,
          //   pageTitle: 'Scramble - Prepay loans',
          //   exact: true
          // },
          {
            path: APP_LINKS.addFunds,
            component: PaymentsAddFundsPage,
            pageTitle: 'Scramble - Add Cash',
            exact: true,
          },
          {
            path: APP_LINKS.addFundsResult,
            component: PaymentsAddFundsResultPage,
            pageTitle: 'Scramble - Add Cash Result',
            exact: true,
          },
          {
            path: APP_LINKS.withdraw,
            component: PaymentsWithdrawPage,
            pageTitle: 'Scramble - Withdraw',
            exact: true,
          },
          {
            path: APP_LINKS.bankStatements,
            component: PaymentsBankStatementPage,
            pageTitle: 'Scramble - Bank Statement',
            exact: true,
          },
        ],
      },
      {
        path: APP_LINKS.loans,
        component: LoansPage,
        auth: user => !!user,
        pageTitle: 'Scramble - Loans',
      },
      {
        path: APP_LINKS.helpLoanTerms(':version?'),
        component: LoanTermsPage,
        pageTitle: 'Scramble - Loan Terms',
        exact: true,
      },
      {
        path: '/help/swap-guarantees',
        component: SwapGuaranteePage,
        pageTitle: 'Scramble - Mutual Swap Guarantee',
        exact: true,
      },
      {
        path: APP_LINKS.help,
        component: HelpPage,
        auth: user => !!user,
        pageTitle: 'Scramble - FAQ',
        exact: true,
      },
      {
        path: '**',
        component: PageNotFound,
        pageTitle: 'Not found',
      },
    ],
  },
];

export default ROUTES;
