import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getStartupDetail } from '../../store/actions/startupActions';
import StartupDetail from '../../containers/CompanyDetail';
import { BackButton } from '../../components/UI/Buttons';
import { canGoBack } from '../../helpers/utils';
import { APP_LINKS } from '../../helpers/links';
import Preloader from '../../components/UI/Preloader';
import s from './index.module.scss';

const StartupDetailPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { slug } = useParams();

  useEffect(() => {
    dispatch(getStartupDetail(slug));
  }, [slug]);

  const onBack = () => {
    canGoBack(history) ? history.goBack() : history.push(APP_LINKS.startups);
  };

  const { startupDetail } = useSelector(state => state.startupStore);
  const { data: startup, loading } = startupDetail;

  return (
    <div className={s.wrapper}>
      <div className="container-inner">
        <div className={s.back}>
          <BackButton onClick={onBack} />
        </div>
        {loading ? (
          <Preloader className={s.preloader} />
        ) : startup ? (
          <StartupDetail />
        ) : (
          <div className={s.empty}>Please check network connection...</div>
        )}
      </div>
    </div>
  );
};

export default StartupDetailPage;
