export const EXAMPLE_DATA_BASIC_6 = {
  total: 10900,
  total_fee: 900,
  total_principal: 10000,
  list: [
    {
      date: '2024-01-15',
      fee: 405,
      principal: 0,
      total: 405,
    },
    {
      date: '2024-02-01',
      fee: 0,
      principal: 200,
      total: 200,
    },
    {
      date: '2024-03-01',
      fee: 0,
      principal: 200,
      total: 200,
    },
    {
      date: '2024-04-01',
      fee: 0,
      principal: 200,
      total: 200,
    },
    {
      date: '2024-05-01',
      fee: 0,
      principal: 200,
      total: 200,
    },
    {
      date: '2024-06-01',
      fee: 0,
      principal: 200,
      total: 200,
    },
    {
      date: '2024-07-01',
      fee: 495,
      principal: 9000,
      total: 9495,
    },
  ],
};

export const EXAMPLE_DATA_ADVANCED_6 = {
  total: 10600,
  total_fee: 600,
  total_principal: 10000,
  list: [
    {
      date: '2024-01-15',
      fee: 172.5,
      principal: 0,
      total: 172.5,
    },
    {
      date: '2024-02-01',
      fee: 0,
      principal: 800,
      total: 800,
    },
    {
      date: '2024-03-01',
      fee: 0,
      principal: 800,
      total: 800,
    },
    {
      date: '2024-04-01',
      fee: 0,
      principal: 800,
      total: 800,
    },
    {
      date: '2024-05-01',
      fee: 0,
      principal: 800,
      total: 800,
    },
    {
      date: '2024-06-01',
      fee: 0,
      principal: 800,
      total: 800,
    },
    {
      date: '2024-07-01',
      fee: 427.5,
      principal: 6000,
      total: 6427.5,
    },
  ],
};
