import React from 'react';
import classnames from 'classnames';
import s from './index.module.scss';

export const CheckboxButton = ({ className, children, ...checkboxProps }) => (
  <label className={classnames(s.label, className)} htmlFor={checkboxProps.name}>
    <Checkbox {...checkboxProps} />
    {children}
  </label>
);

export const Checkbox = ({ ...checkboxProps }) => {
  return (
    <span className={s.checkbox}>
      <input
        type="checkbox"
        className={s.checkbox__input}
        id={checkboxProps.value}
        {...checkboxProps}
      />
      <span className={s.checkbox__checkmark}>
        <svg
          width="16"
          height="13"
          viewBox="0 0 16 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.86989 5.55273H1.86962H0.640909C0.335462 5.55273 0.164902 5.90463 0.353481 6.1438L0.353557 6.1439L5.16821 12.2435L5.16827 12.2436C5.48371 12.6428 6.08852 12.6417 6.40501 12.2443L6.40562 12.2435L14.9895 1.36598C14.9898 1.36566 14.99 1.36534 14.9903 1.36502C15.1825 1.12438 15.0048 0.775 14.7034 0.775H13.4747C13.2341 0.775 13.005 0.885236 12.8564 1.07562C12.8563 1.0758 12.8561 1.07598 12.856 1.07616L5.78605 10.0323L2.48759 5.85297C2.41399 5.75952 2.32018 5.68397 2.2132 5.63197C2.10621 5.57997 1.98884 5.55288 1.86989 5.55273Z"
            fill="white"
            stroke="white"
            strokeWidth="0.45"
          />
        </svg>
      </span>
    </span>
  );
};
