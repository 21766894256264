import React from 'react';
import classnames from 'classnames';
import ReCAPTCHA from 'react-google-recaptcha';
import config from '../../config';
import s from './index.module.scss';

export const GoogleCaptchaV2 = ({ className, onChange }) => (
  <div className={classnames(s.wrapper, className)}>
    <ReCAPTCHA
      sitekey={config.googleReCaptchaSiteKeyV2}
      onChange={value => {
        if (value && typeof value === 'string') {
          onChange(value);
        }
      }}
    />
  </div>
);
